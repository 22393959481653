import React, { useState } from 'react';
import ReactDOM from "react-dom";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {API_BASE_URL,META_BASE_URL} from '../constants';
import {useAuthState, AuthProvider} from "@bamboobox/b2logincheck";
import { css } from "glamor";
import { toast } from "react-toastify";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import Alert from '@mui/material/Alert';
import "./../toast.css"

const localhost = API_BASE_URL;

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});
const AccountSearchBar = (props) => {
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;
  var typeAheadUrl1 = API_BASE_URL+"/b2pm/account/autocomplete/name?q=";
  var typeAheadUrl2 = "&tenantId="+tenantId;

    const [myCompany, setMyCompany] = useState([])
    var listAllData = [];

    const accountSelected = (e,option) => {
      console.log(e,option);
     // alert(option.accountId);
      window.name = "SearchBarInput";
      localStorage.setItem("windowTenantID", window.TenantID);
      localStorage.setItem("windowAccountID", option.accountId);
    //  window.location.reload(false);
    props.setDisabled(true)
    // }
   
    if(option.accountId === undefined && option.length<=2)
    {
        toast.error(<Alert severity="error">Enter atleast 3 characters</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });


    }
    else if (option.accountId === undefined && option.length>=3)
    {
        toast.error(<Alert severity="error">Account is not present in the list</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
    }
    else{ 
      // if(props.isRouted ===  true)
      // {
      //   props.setAccountID(option.accountId)
      //  //  props.setIsRouted(false)
      // }
    //  else{
      props.setAccountIDClicked(option.accountId); 
      // props.setShowNewAccount(false);

    //  }
    
    }
      // props.setShowNewAccount(true) 

    }

    const getDataFromAPI = (e) => {
      console.log(e.target.value);
      fetch(typeAheadUrl1 + e.target.value + typeAheadUrl2,{credentials:'include'})
      .then((response) => response.json())
      .then(data => {
        listAllData = data;
        var tmpData = [];
        for (var i = 0; i < data.length; i++) {

          tmpData.push(data[i])
        }
        setMyCompany(tmpData)
      })
    }
    return (

        <Autocomplete id = "searchbox"
         style={{ width: "262px" }}
          freeSolo
          autoComplete
          autoHighlight
          onChange = {accountSelected}
          options={myCompany}
          getOptionLabel = {(option) =>option.companyName}
          renderOption={(option) => (
            <React.Fragment>
              {option.companyName}
            </React.Fragment>
          )}
          renderInput={(params) => (
            <TextField {...params}
              onChange={getDataFromAPI}
              variant="outlined"
              placeholder="Search for an account"
              InputProps={{
                  
                ...params.InputProps,
                style:{height:'32px', borderRadius:"4px"},

                endAdornment: (
                  <InputAdornment>
                    
                    <IconButton>
                    <svg width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clip-path="url(#clip0_1227_4675)">
                    <path d="M10.8924 15.4212C6.54476 15.4212 3.02026 11.9698 3.02026 7.71228C3.02026 3.45476 6.54472 0.00341797 10.8924 0.00341797C15.2401 0.00341797 18.7646 3.45479 18.7646 7.71232C18.7646 11.9699 15.2401 15.4212 10.8924 15.4212ZM10.8924 2.20597C7.78693 2.20597 5.26946 4.67124 5.26946 7.71232C5.26946 10.7534 7.78693 13.2187 10.8924 13.2187C13.9979 13.2187 16.5154 10.7534 16.5154 7.71232C16.5154 4.67124 13.9979 2.20597 10.8924 2.20597Z" fill="#595959"/>
                    <path d="M1.89576 17.6236C1.59683 17.6254 1.30953 17.5104 1.09731 17.3043C0.656314 16.8759 0.653406 16.1787 1.0908 15.7468C1.09297 15.7447 1.09514 15.7426 1.09731 15.7405L5.32577 11.5997C5.78227 11.1678 6.50983 11.1802 6.95082 11.6272C7.39181 12.0742 7.37922 12.7867 6.92272 13.2186L2.69422 17.3043C2.482 17.5104 2.1947 17.6254 1.89576 17.6236Z" fill="#595959"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1227_4675">
                    <rect width="18" height="17.6267" fill="white" transform="translate(0.764648)"/>
                    </clipPath>
                    </defs>
                    </svg>

                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
          )}
        />

    );
}

export default AccountSearchBar;