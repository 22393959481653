import React, { useState, useRef, useEffect } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import Paper from '@mui/material/Paper';
//Local Components
import RApp from "./route";
// import GridTable from "@nadavshaar/react-grid-table";
import GridTable from "./grid_src"
import GetColumns from "./getAccountColumns";
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// Toast
import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ACCOUNT_CONTACT_SVG from "./icons/accountContactcreationIcon";
import ENRICH_SVG from "./icons/enrichIcon";
import Filters from "./filters";
import { useColumnVisibilityPersist } from "@bamboobox/b2gridutils";
import {
  saveFilters,
  getFilterNames,
  getFilterDataByName,
  deleteFilters,
} from "./api";
//Material Components
import Chip from "@material-ui/core/Chip";
import MuiAlert from "@material-ui/lab/Alert";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Dialog from "@material-ui/core/Dialog";
import Snackbar from "@material-ui/core/Snackbar";
import { Button, createMuiTheme, Typography } from "@material-ui/core/";
import { withStyles, ThemeProvider } from "@material-ui/core/styles";
import { ViewColumn } from "@material-ui/icons";
import { AccountDetail } from "./components_detailview/AccountDetail";
import UItheme from "@bamboobox/b2-theme";
import "@bamboobox/b2-theme/grid/style.css";
import {
  API_BASE_URL,
  META_BASE_URL,
  ACC_CON_CREATE_LINK,
  LOGIN_URL,
  AUTH_URL,
  ACCOUNT_SUMMARY_REPORT
} from "./constants";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import {ResourceCheck} from '@bamboobox/b2resource-check/dist'
import AccountSearchBar from "./components_detailview/accountSearchBar";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useParams } from 'react-router-dom';
import { DATE_FIELDS } from './filters/attributeitem/constants'
import { Divider } from "@material-ui/core";
import Alert from '@mui/material/Alert';
import "./toast.css"
// import { version } from '../package.json';
import CacheBuster from 'react-cache-buster';

// console.log("verison of build", version);

const localhost = API_BASE_URL;
const ui = "account_view";
const mapper = {
  "Country (Bombora)" : ["bomboraSurgingCountry" , "Account"],
  "Industry": ["companyPrimaryIndustryCategory", "ACCOUNT"],
  "Sub Industry": ["companySubIndustryCategory", "ACCOUNT"],
  "Company Revenue": ["companyBrandRevenueNumerical", "ACCOUNT"],
  "Account Classification": ["companyClassification", "ACCOUNT"],
  "Capillary Geography": ["crmAccountGeography", "ACCOUNT"],
  "Company Type": ["companyType", "ACCOUNT"],
  // "Company Hierarchy": ["companyHierarchy", "ACCOUNT"],
  // "Account Region": ["companyAccountRegion", "ACCOUNT"],
  "Number of Contacts": ["count_contacts", "ACCOUNT"],
  "Number of Stores": ["crmNumberOfStores", "ACCOUNT"],
  "Account Source": ["accountSource", "ACCOUNT"],
  "Company Name": ["companyName", "ACCOUNT"],
  "Company Country": ["companyCountry", "ACCOUNT"],
  "Company State": ["companyState", "ACCOUNT"],
  "Company City": ["companyCity", "ACCOUNT"],
  "Company Phone": ["companyPhone", "ACCOUNT"],
  "Brand Revenue Number": ["companyBrandRevenueNumerical", "ACCOUNT"],
  "Business Format": ["crmBusinessFormat", "ACCOUNT"],
  "Group Company Name": ["companyGroupName", "ACCOUNT"],
  "Parent Company Name": ["companyParent", "ACCOUNT"],
  "Group Company Website": ["companyGroupWebsite", "ACCOUNT"],
  "Parent Company Website": ["companyParentWebsite", "ACCOUNT"],

  "Contact Mobile": ["contactMobile", "CONTACT"],
  "Contact Phone": ["contactPhone", "CONTACT"],

  "Field Sales": ["crmFSExecutive", "ACCOUNT"],
  "Inside Sales": ["crmISExecutive", "ACCOUNT"],

  "Account Creation Date": ["createddateTime", "ACCOUNT"],
  "Account Modification Date": ["modifieddateTime", "ACCOUNT"],
  "Contact Creation Date": ["createddateTime", "CONTACT"],
  "Contact Modification Date": ["modifieddateTime", "CONTACT"],
};

const resources=["account_list_view"]

const MyAwesomeTable2 = () => {
  const [columVisibilityTransformer, onColumnChangeSave] =
    useColumnVisibilityPersist("B2AMAccountListView");
  const [tableManager, setTableManager] = useState(null);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [reload, setReload] = useState(false);
  const [editRowId, setEditRowId] = useState(null);
  let [searchText, setSearchText] = useState("");
  let [selectedRowsIds, setSelectedRowsIds] = useState([]);
  let [sort, setSort] = useState({ colId: null, isAsc: true });
  let [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(15);
  let [pageSizes, setPageSizes] = useState([15, 50, 75, 100]);
  const [sortby, setSortby] = useState("");
  const [sortType, setSortType] = useState("");
  let [enableColumnsReorder, setEnableColumnsReorder] = useState(true);
  let [highlightSearch, setHighlightSearch] = useState(true);
  let [showSearch, setShowSearch] = useState(false);
  let [showRowsInformation, setShowRowsInformation] = useState(true);
  let [showColumnVisibilityManager, setShowColumnVisibilityManager] = useState(true);
  let [isHeaderSticky, setIsHeaderSticky] = useState(true);
  let [isVirtualScroll, setIsVirtualScroll] = useState(true);
  let [isPaginated, setIsPaginated] = useState(true);
  let [minSearchChars, setMinSearchChars] = useState(2);
  let [minColumnResizeWidth, setMinColumnWidth] = useState(70);
  let [showDetailedView, setShowDetailedView] = useState(false);
  let [showCallBack, setShowCallBack] = useState(false);
  let [accountIDClicked, setAccountIDClicked] = useState("");
  let [prevAccountID, setPrevAccount] = useState("");
  let [nextAccountID, setNextAccount] = useState("");
  const [disable, setDisabled] = useState(false);

  const getAccountId = (accountId) => {
    // console.log("Button click!")
    setAccountIDClicked(accountId);
    // console.log("Gotchha --" + accountIDClicked)

    setShowDetailedView(true);
    console.log("getAccountID");
  };

  const getNextAccountId = (rows, rowClicked) => {
    let rowStart = Math.floor(rowClicked / pageSize);

    console.log(rowStart);
    let start = rowStart * pageSize;
    console.log(start);

    let countrows = 0;

    for (let i = start; i < (rowStart + 1) * pageSize; i++) {
      if (rows[i] !== undefined) {
        console.log(rows[i]);
        countrows++;
      } else {
        break;
      }
    }
    let end = start + countrows; //(rowStart+1)*pageSize ;
    console.log(end);

    //console.log(rows[rowClicked-1])
    if (rows[rowClicked + 1] === undefined || rowClicked + 1 > end) {
      setNextAccount(rows[start].accountId);
      console.log(rows[start].accountId);
    } else {
      console.log("Next");
      console.log(rows[rowClicked + 1].accountId);
      setNextAccount(rows[rowClicked + 1].accountId);
    }
  };

  const getPreviousAccountId = (rows, rowClicked) => {
    let rowStart = Math.floor(rowClicked / pageSize);

    let start = rowStart * pageSize;
    let countrows = 0;

    for (let i = start; i < (rowStart + 1) * pageSize; i++) {
      if (rows[i] !== undefined) {
        console.log(rows[i]);
        countrows++;
      } else {
        break;
      }
    }

    console.log("rows-------- " + countrows);
    let end = start + countrows; //(rowStart+1)*pageSize ;
    console.log(end);
    console.log(rows);
    console.log(rowClicked);
    console.log(pageSize);
    //console.log(rows[rowClicked-1])
    if (rows[rowClicked - 1] === undefined || rowClicked - 1 < start) {
      setPrevAccount(rows[end - 1].accountId);
      console.log(rows[end - 1].accountId);
    } else {
      console.log(prevAccountID);

      setPrevAccount(rows[rowClicked - 1].accountId);
      console.log(rows[rowClicked - 1].accountId);
    }
  };

  const getAccountDetailviaCallback = (previousNextAccountId) => {
    // getAccountDetailviaCallback(previousNextAccountId)
    console.log(previousNextAccountId);

    let start = (page - 1) * pageSize;
    let countrows = 0;

    for (let i = start; i < page * pageSize; i++) {
      if (rowsData[i] !== undefined) {
        countrows++;
      } else {
        break;
      }
    }

    let end = start + countrows; //(page)*pageSize ;
    console.log("--------------------------------");
    console.log(start);
    console.log(end);
    console.log(rowsData[start]);
    console.log(rowsData[end - 1]);

    // setShowDetailedView(false);
    for (let i = start; i < end; i++) {
      if (rowsData[i].accountId === previousNextAccountId) {
        // setShowDetailedView(false);
        // let prev = rowsData[i-1].accountId

        let prev = "";
        let next = "";
        let clicked = "";
        if (rowsData[i - 1] === undefined || i - 1 < start) {
          prev = rowsData[end - 1].accountId;
          next = rowsData[i + 1].accountId;
          clicked = rowsData[i].accountId;

          setAccountIDClicked(clicked);
          setPrevAccount(prev);
          setNextAccount(next);
          console.log("Previous");
          console.log(prevAccountID);
          console.log("Next");
          console.log(nextAccountID);
          console.log("Previous-Now Current");
          console.log(accountIDClicked);
          getAccountId(previousNextAccountId);
        } else if (rowsData[i + 1] === undefined || i + 1 > end) {
          prev = rowsData[i - 1].accountId;
          next = rowsData[start].accountId;
          clicked = rowsData[i].accountId;

          setAccountIDClicked(clicked);
          setPrevAccount(prev);
          setNextAccount(next);
          console.log("Previous");
          console.log(prevAccountID);
          console.log("Next");
          console.log(nextAccountID);
          console.log("Previous-Now Current");
          console.log(accountIDClicked);
          getAccountId(previousNextAccountId);
        } else {
          prev = rowsData[i - 1].accountId;
          next = rowsData[i + 1].accountId;
          clicked = rowsData[i].accountId;

          setAccountIDClicked(clicked);
          setPrevAccount(prev);
          setNextAccount(next);
          console.log("Previous");
          console.log(prevAccountID);
          console.log("Next");
          console.log(nextAccountID);
          console.log("Previous-Now Current");
          console.log(accountIDClicked);
          getAccountId(previousNextAccountId);
        }
      } else {
        continue;
      }
    }
  };

  let [columns, setColumns] = useState(
    columVisibilityTransformer(
      GetColumns(
        { setRowsData },
        { getAccountId },
        { getPreviousAccountId },
        { getNextAccountId }
      )
    )
  );

  let { id, previousPage, filterAppliedOnPreviousPage } = useParams();

  const [filterAppliedThroughOtherPage, setFilterAppliedThroughOtherPage] = useState(filterAppliedOnPreviousPage ? JSON.parse(unescape(filterAppliedOnPreviousPage)) : undefined)
  const [previousPageName, setPreviousPageName] = useState(previousPage)
  let [selectAllMode, setSelectAllMode] = useState("page");
  const [chipData, setChipData] = useState([]);
  const [body, setBody] = useState(id ? JSON.parse(unescape(id)) : []);
  const [filterName, setFilterName] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [attributeData, setAttributeData] = useState([]);
  // const [openSnackbar, setOpenSnackbar] = useState(false);
  // const [message, setMessage] = useState("");
  // const [severity, setSeverity] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [action, setAction] = useState("");
  const [filterNamesArr, setFilterNamesArr] = useState([]);
  const [question, setQuestion] = useState("");
  const [state, setState] = React.useState({
    checkedC: true,
  });
  const [count, setCount] = useState(0);
  let gridData = useRef();
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

  var adapt = {
    tenantId: tenantId,
    userId: userId,
    // error: "",
    rows: [],
  };

  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });

  useEffect(() => {
    getFilterNames(tenantId, ui).then((json) => {
      setFilterOptions(json.data);
    });
  }, []);

  const callbackToSetGridVisibilty = (state) => {
    console.log("Acccount-gridVisibility");
    setReload(true);
    setShowDetailedView(state);

    // if(reload === true)
    // {  setShowDetailedView(state);
    //   setReload(false)
    // }
    // else{
    //    setShowDetailedView(state);
    //   setReload(true)
    // }
  };

  useEffect(() => {
    setLoading(true);
    document.title = "Account List View";
    setSelectedRowsIds([]);
    displayGridData();
  }, [reload, page, pageSize, body, sortType, sortby]);

  useEffect(() => {
    applyFilters(body);
  }, [])

  useEffect(() => {
    const filtersArr = filterOptions.map((option) => {
      return option?.name;
    });
    setFilterNamesArr(filtersArr);
  }, [filterOptions]);

  function displayGridData() {
    // ?sortby=${sortby}&sortType=${sortType}
    // setTimeout(() => {
    fetch(
      `${localhost}/b2pm/${tenantId}/${page}/${pageSize}/filterAccount?sortby=${sortby}&sortType=${sortType}`,
      {
        credentials: "include",
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.rowSelected === null) {
          setLoading(true)
        }
        else {
          console.log("testing", isLoading, body, pageSize, data);
          let arr = new Array(parseInt(data.totalCount));
          const initial = 1 + (page - 1) * pageSize;
          const final = initial + data.rowSelected?.length;

          const dataArr = getAllRows(data.rowSelected);

          let i = 0;
          for (let x = initial - 1; x < final - 1; x++) {
            arr[x] = dataArr[i];
            i++;
          }
          // setRowsData(getAllrows(arr));
          setRowsData(arr);
          setLoading(false);
        }

      })
      .catch((error) => {
          toast.error(<Alert severity="error">Unable to fetch list due to Server error</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
        setLoading(false);
        return Promise.reject(error);
      });
  }

  function getAllRows(inp_rows) {
    if (!inp_rows || !inp_rows.length) {
      return [];
    }
    var rows = inp_rows;
    var rows_out = [];
    for (let i = 0; i < rows.length; i++) {
      rows_out.push(getAllRowsData(rows[i], i + 1));
    }
    return rows_out;
  }

  function getAllRowsData(inp, i) {
    var row = { id: i, row_id: i, ...inp.account, ...inp.funding, ...inp.crm };
    return row;
  }

  //ENRICHMENT GRID
  const addToEnrichQueue = () => {
    console.log("Accountdata enrich grid -> ", selectedRowsIds);

    for (let i = 0; i < selectedRowsIds.length; i++) {
      if (selectedRowsIds[i] === undefined) {
        continue;
      }
      for (let j = 0; j < rowsData.length; j++) {
        if (selectedRowsIds[i] === rowsData[j].id) {
          var json = { id: rowsData[j].accountId };
          adapt.rows.push(json);
          //   mcarlson@vmware.com
          //console.log(adapt);
          break;
        }
      }
    }
    console.log("inside to addtoenrich")
    axios
      .post(localhost + "/b2pm/account/add", adapt, { withCredentials: true })
      .then((tabledata) => {
        if (tabledata.status === 200) {
          toast.success(<Alert severity="success">Added to Enrichment Queue successfully</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
      }
      });
  };

  // let counter = rowsData.length
  const IconButton = withStyles((theme) => ({
    root: {
      ...theme.typography.button,
      boxSizing: "border-box",
      minWidth: 24,
      padding: "6px 6px",
    },
  }))(Button);

  // function Alert(props) {
  //   return <MuiAlert elevation={6} variant="filled" {...props} />;
  // }
  // const closeSnackbar = (event, reason) => {
  //   if (reason === "clickaway") {
  //     return;
  //   }
  //   setOpenSnackbar(false);
  // };
  const handleDelete = (chipToDelete) => () => {
    setChipData((chips) =>
      chips.filter((chip) => chip.key !== chipToDelete.key)
    );
    setBody((body) => body.filter((e) => e.attrId !== chipToDelete.key));
  };

  function applyFilters(attrList) {
    const attributeArray = attrList.map((object) => {
      const obj = { ...object };
      obj.attribute = mapper[object.attribute][0];
      obj.type = mapper[object.attribute][1];
      delete obj.isValid;
      delete obj.index;
      return obj;
    });
    setBody(attributeArray);
    setShowModal(false);
    const chipArray = attrList.map((element, index) => {

      if (DATE_FIELDS.includes(element.attribute)) {
        let fromDate = new Date(element.values[0])
        let toDate = new Date(element.values[1])

        if (element.relation.toLowerCase() === "Is".toLowerCase()) {

          return {
            key: element.attrId,
            label: `${element.attribute} (${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()})`,
          };

        } else {


          return {
            key: element.attrId,
            label: `${element.attribute}(${fromDate.getDate()}-${(fromDate.getMonth() + 1)}-${fromDate.getFullYear()} to ${toDate.getDate()}-${(toDate.getMonth() + 1)}-${toDate.getFullYear()})`,
          };
        }
      } else if (element.relation.toLowerCase() === "Is Empty".toLowerCase()) {
        const valuesArr = "Is Empty";
        return {
          key: element.attrId,
          label: `${element.attribute}(${valuesArr})`,
        };
      }
      const valuesArr = element.values.join(",");
      return {
        key: element.attrId,
        label: `${element.attribute}(${valuesArr})`,
      };
    });
    setChipData(chipArray);
    setFilterName("");
  }

  function saveData(mode = "save", attrList, filterName) {
    saveFilters(mode, attrList, filterName, tenantId, ui).then((json) => {
      getFilterNames(tenantId, ui).then((res) => {
        setFilterOptions(res.data);
      });
      if (json?.success) {
        // setSeverity("success");
        toast.success(<Alert severity="success">{json?.message}</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
        if (mode === "save") {
          applyFilters(attrList);
        } else {
          setShowModal(false);
          setFilterName("");
        }
      } else {
        // setSeverity("error");
        toast.error(<Alert severity="error">{json?.message}</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
      }
      // setMessage(json?.message);
      // setOpenSnackbar(true);
    });
  }

  const onBackClick = () => {
    if (previousPageName === "account_management") {
      let goBack = `${ACCOUNT_SUMMARY_REPORT}/#/filter/${escape(JSON.stringify(filterAppliedThroughOtherPage))}`
      window.location.href = goBack
    }
    else if (previousPage === "trend_chart") {
      alert("trend chart routing applied")
      // props.history.push(`http://test.bamboobox.in:3000/#/filter/${escape(JSON.stringify(filterAppliedOnPreviousPage))}`)
    }
  }

  return showDetailedView === false ? (
    <ThemeProvider theme={UItheme}>
      {/* changes by likhit */}
      <React.Fragment>
        <div className="demo">
          <div className="tableWrapper">
            {/* UI changes */}
            <div style={{ display: 'flex', alignItems: 'center', marginBottom: "8px", }}>
              {Array.isArray(filterAppliedThroughOtherPage) ?
                <IconButton aria-label="back" style={{ color: "rgba(0, 0, 0, 0.54)", padding: '5px' }} onClick={onBackClick}>
                  <ArrowBackIosIcon style={{ fontSize: "20px" }} />
                </IconButton> :
                <React.Fragment></React.Fragment>
              }
              <Typography
                className="hub"
                style={{ color: "#595959", fontSize: "20px", fontStyle: "Bold", fontWeight: "700" }}
              >
                {/* <ArrowBackIcon style={{ fontSize: "1rem" }} /> */}
                Account Management
              </Typography>
            </div>
            <div className="button-section">
              <div style={{ display: "flex" }}>
                <div style={{marginTop: "5px"}}>
                <AccountSearchBar
                  setAccountIDClicked={getAccountId}
                  setDisabled={setDisabled}
                />
                </div>
                <div style={{ marginLeft: "24px", marginRight: "8px", marginTop: "20px" }}>
                  <Divider orientation="vertical" style={{ height: "18px", }} />
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "fit-content",
                    justifyContent: "flex-start",
                    height: "32px",
                    margin: "12px"
                  }}
                >
                  {/* UI changes */}

                  <Button
                    title="Add to Account/Contact"
                    rel="noopener"
                    target="_blank"
                    href={ACC_CON_CREATE_LINK}
                    // onClick={() =>addToEnrichQueue()}
                    variant="contained"
                    style={{ fontSize: "12px", backgroundColor: "#21DA8C", borderColor: "#21DA8C", color: "#FFFFFF", borderRadius: "18px" }}
                  >
                    {/* <Typography variant="body1"> New Account/Contact </Typography> */}
                    ADD ACCOUNT/CONTACT
                  </Button>

                  <Button
                    title="Add to Enrichment Queue"
                    onClick={() => selectedRowsIds.length > 0 ? addToEnrichQueue() : ""}
                    variant="contained"
                    style={{
                      fontSize: "12px",
                      backgroundColor: (selectedRowsIds.length > 0) ? "#21DA8C" : "#C4C4C4",
                      borderColor: (selectedRowsIds.length > 0) ? "#21DA8C" : "#C4C4C4",
                      cursor: selectedRowsIds.length === 0 ? 'default' : "Pointer",
                      color: "#FFFFFF",
                      borderRadius: "18px"
                    }}

                  >
                    Queue to Enrich
                  </Button>
                </div>

              </div>

              <Filters
                style={{ width: "350px", height: "36px" }}
                getFilterData={(data) => {
                  setAction(data.actionType);
                  if (data.isValid) {
                    setAttributeData(data.attrList);
                    setFilterName(data.filterName);

                    if (data.actionType === "APPLY") {
                      setQuestion("Do you want to Apply?");
                      setOpenConfirmation(true);
                    } else if (data.actionType === "SAVE") {
                      setQuestion("Do you want to Save?");
                      setOpenConfirmation(true);
                    } else {
                      if (data.actionType === "SAVE_APPLY") {
                        if (filterNamesArr.includes(data.filterName)) {
                          // setMessage("FilterName already exists!");
                          // setSeverity("error");
                          toast.error(<Alert severity="error">FilterName already exists!</Alert>,{
                            closeOnClick: true,
                            autoClose: true,
                            closeButton: true,
                            newestOnTop: true
                          });
                          // setOpenSnackbar(true);
                        } else {
                          setQuestion("Do you want to Save and Apply?");
                          setOpenConfirmation(true);
                        }
                      } else {
                        setQuestion("Do you want to Apply?");
                        setOpenConfirmation(true);
                      }
                    }
                  } else {
                    if (!data?.filterName) {
                      // setMessage("Enter FilterName!");
                      // setSeverity("error");
                      toast.error(<Alert severity="error">Enter FilterName!</Alert>,{
                        closeOnClick: true,
                        autoClose: true,
                        closeButton: true,
                        newestOnTop: true
                      });
                      // setOpenSnackbar(true);
                    }
                  }
                }}
                onClickFilter={(filter, type) => {
                  if (filter !== "none") {
                    if (type === "edit") {
                      console.log(type);
                      getFilterDataByName(filter, tenantId, ui).then((json) => {
                        setAttributeData(json);
                        setShowModal(true);
                      });
                    } else if (type === "delete") {
                      console.log("delete");
                      deleteFilters(filter, tenantId, ui)
                        .then((res) => {
                          getFilterNames(tenantId, ui)
                            .then((json) => {
                              setFilterOptions(json.data);
                            })
                            .catch((err) => {
                              console.error(err);
                            });
                        })
                        .catch((err) => {
                          console.error(err);
                        });
                    } else if (type === "add") {
                      setShowModal(true);
                    } else {
                      getFilterDataByName(filter, tenantId, ui).then((json) => {
                        applyFilters(json);
                      });
                    }
                  } else {
                    setBody([]);
                    setChipData([]);
                  }
                }}
                onModalClose={(e) => {
                  setShowModal(false);
                  setAttributeData([]);
                  setFilterName("");
                }}
                showModal={showModal}
                attributeData={attributeData}
                customStyle={{ width: 150, background: "#fff" }}
                options={filterOptions}
                attributesValueURL={`${META_BASE_URL}/attribute-setup/getattribute?tenantId=${tenantId}`}
              />
            </div>

            <div style={{ display: "flex", flexWrap: "wrap" }}>
              {chipData.map((data) => {
                return (
                  <div key={data.key} style={{ margin: "1px" }}>
                    <Chip
                      style={{ margin: "10px", backgroundColor: "#8A8A8A", color: "#FFFFFF", fontSize: "small" }}
                      variant="outlined"
                      size="medium"
                      label={data.label}
                      deleteIcon={<CloseRoundedIcon style={{ color: "#FFFFFF", fontSize: "medium", fontWeight: "700" }} />}
                      onDelete={handleDelete(data)}
                    />
                  </div>
                );
              })}
            </div>

            <Paper style={{ borderRadius: '18px', padding: `28px 28px 0px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`, }}>
              <GridTable
                columns={columns}
                onColumnsChange={onColumnChangeSave(setColumns)}
                rows={rowsData}
                isLoading={isLoading}
                editRowId={editRowId}
                onEditRowIdChange={setEditRowId}
                selectedRowsIds={selectedRowsIds}
                onSelectedRowsChange={setSelectedRowsIds}
                onRowClick={(
                  { rowIndex, data, column, isEdit, event },
                  tableManager
                ) =>
                  !isEdit &&
                  tableManager.rowSelectionApi.getIsRowSelectable(data.id) &&
                  tableManager.rowSelectionApi.toggleRowSelection(data.id)
                }
                onLoad={setTableManager}
                searchText={searchText}
                onSearchTextChange={setSearchText}
                //sort={sort}
                // onSortChange={setSort}
                page={page}
                onPageChange={setPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
                pageSizes={pageSizes}
                enableColumnsReorder={enableColumnsReorder}
                highlightSearch={highlightSearch}
                showSearch={showSearch}
                showRowsInformation={showRowsInformation}
                showColumnVisibilityManager={showColumnVisibilityManager}
                isHeaderSticky={isHeaderSticky}
                isVirtualScroll={isVirtualScroll}
                isPaginated={isPaginated}
                minSearchChars={minSearchChars}
                minColumnResizeWidth={minColumnResizeWidth}
                selectAllMode={selectAllMode}
                onSortChange={({ colId, isAsc }) => {
                  setRowsData([]);
                  setLoading(true);
                  const sortArr = columns.filter((e) => e?.id == colId);
                  if (colId) {
                    setSortby(sortArr[0]?.field);
                  } else {
                    setSortby("");
                  }
                  if (isAsc) {
                    setSortType("asc");
                  } else {
                    setSortType("desc");
                  }
                }}
                icons={{ columnVisibility: <MoreHorizIcon color="action" /> }}
              />
            </Paper>
          </div>
        </div>
        <Dialog
          open={openConfirmation}
          onClose={openConfirmation}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle style={{ display: "flex", justifyContent: "center" }} id="alert-dialog-title">
            {question}
          </DialogTitle>
          <DialogContent>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="outlined"
                style={{ margin: "10%", borderColor: "#A0A0A0", color: "#A0A0A0" }}
                onClick={(e) => {
                  setOpenConfirmation(false);
                  setShowModal(false);
                  setFilterName("");
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                color="primary"
                style={{ margin: "10%", backgroundColor: "#21DA8C" }}
                onClick={(e) => {
                  setOpenConfirmation(false);
                  if (action === "SAVE") {
                    saveData("edit", attributeData, filterName);
                  } else if (action === "SAVE_APPLY") {
                    saveData("save", attributeData, filterName);
                  } else {
                    applyFilters(attributeData);
                  }
                  setShowModal(false);
                }}
              >
                Yes
              </Button>
            </div>
          </DialogContent>
        </Dialog>
        {/* <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={closeSnackbar}
        >
          <Alert onClose={closeSnackbar} severity={severity}>
            {message}
          </Alert>
        </Snackbar> */}
      </React.Fragment>
    </ThemeProvider>
  ) : (
    <ThemeProvider theme={UItheme}>
      <AccountDetail
        accountIDClicked={accountIDClicked}
        setAccountIDClicked={setAccountIDClicked}
        prevAccountID={prevAccountID}
        nextAccountID={nextAccountID}
        callbackToSetGridVisibilty={callbackToSetGridVisibilty}
        getAccountDetailviaCallback={getAccountDetailviaCallback}
        reload={reload}
        setReload={setReload}
        rowsLength={rowsData.length}
        setDisabled={setDisabled}
        disable={disable}
      />
    </ThemeProvider>
  );
  // <div>accountDetailedView</div>
};

export default MyAwesomeTable2;

ReactDOM.render(
  <AuthProvider loginURL={LOGIN_URL} authcheckURL={AUTH_URL}>
    {/* <CacheBuster
        currentVersion={version}
        isEnabled={true} //If false, the library is disabled.
        isVerboseMode={true}> */}
        <ResourceCheck component={<RApp />} resources={resources} />
    {/* <RApp /> */}
    {/* </CacheBuster> */}
  </AuthProvider>,
  document.getElementById("root")
);
