import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import IconButton from "@material-ui/core/IconButton";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { AccountProviderContext } from "./AccountDetail";

const useRowStyles = makeStyles({
    root: {
        "& > *": {
            //borderBottom: "unset",
            borderBottom: "none"
        }
    }
    ,
    label: {
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "15px",
        /* or 125% */
        color: "#A0A0A0"
    },
    value: {
        // font-family: Proxima Nova;
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "15px",
        /* identical to box height, or 107% */
        color: "#595959"
    }
});

function createData(name, accountDetail) {
    if (name === "Organisation Information") {
        return {
            name,
           information: [
                { label: "Name", value: accountDetail.rowSelected[0].account.companyName },
                { label: "URL", value:  accountDetail.rowSelected[0].account.companyWebsite },
                { label: "Industry", value:  accountDetail.rowSelected[0].account.companyPrimaryIndustryCategory },
                { label: "Sub Industry", value:  accountDetail.rowSelected[0].account.companySubIndustryCategory},
                { label: "Company Type", value:  accountDetail.rowSelected[0].account.companyType },
                // { label: "Company Hierarchy", value:  accountDetail.rowSelected[0].account.companyHierarchy },
                { label: "Parent Name", value:  accountDetail.rowSelected[0].account.companyParent },
                { label: "Parent URL", value: accountDetail.rowSelected[0].account.companyParentWebsite },
                { label: "Group Name", value: accountDetail.rowSelected[0].account.companyGroupName },
                { label: "Group URL", value: accountDetail.rowSelected[0].account.companyGroupWebsite },
           ]
        };
    }
    else if (name === "Contact Information") {
        return {
            name,
            information: [
                { label: "Phone", value: accountDetail.rowSelected[0].account.companyPhone },
                // { label: "Fax", value: accountDetail.rowSelected[0].account.companyFax },
                { label: "Mailing City", value:accountDetail.rowSelected[0].account.companyCity },
                // { label: "Mailing Street", value:accountDetail.rowSelected[0].account.companyStreet },
                { label: "Mailing State", value:accountDetail.rowSelected[0].account.companyState },
                { label: "Mailing Country", value:accountDetail.rowSelected[0].account.companyCountry },
                // { label: "Mailing Zip Code", value:accountDetail.rowSelected[0].account.companyZipCode },

             ]
        };
    }
    else {
      
        return {
            name,
           information: [
                { label: "Company Description",value: accountDetail.rowSelected[0].account.companyDescription  },
                { label: "Twitter URL", value: accountDetail.rowSelected[0].account.companyTwitter },
                { label: "LinkedIn URL", value: accountDetail.rowSelected[0].account.companyLinkedin },
                { label: "Facebook URL", value: accountDetail.rowSelected[0].account.companyFacebook },
                { label: "Account Classification", value: accountDetail.rowSelected[0].account.companyClassification },
                // { label: "Account Region", value: accountDetail.rowSelected[0].account.companyAccountRegion },
                { label: "Business format", value: accountDetail.rowSelected[0].crm.crmBusinessFormat },
                { label: "Field Sales", value: accountDetail.rowSelected[0].crm.crmFSExecutive },
                { label: "Inside Sales", value: accountDetail.rowSelected[0].crm.crmISExecutive },
                // { label: "Field Marketing", value: accountDetail.rowSelected[0].crm.crmFmExecutive },
                // { label: "Location Count", value: accountDetail.rowSelected[0].account.companyLocationCount},
                { label: "No. of stores",value: accountDetail.rowSelected[0].crm.crmNumberOfStores },
                { label: "Capillary Geography",value: accountDetail.rowSelected[0].crm.crmAccountGeography },
                { label: "Account Region",value: accountDetail.rowSelected[0].account.companyAccountRegion },
                // { label: "Company Employee Range", value: accountDetail.rowSelected[0].account.companyEmployeeCount},
                // { label: "Recent Funding Date", value: accountDetail.rowSelected[0].funding.companyRecentFundingDate},
                { label: "Company Revenue", value: accountDetail.rowSelected[0].account.companyBrandRevenueNumerical},
                // { label: "Company Funding", value: accountDetail.rowSelected[0].funding.companyRecentFundingAmount },
                // { label: "Total Funding Amount", value: accountDetail.rowSelected[0].funding.companyTotalFundingAmount},
                // { label: "Continent", value: accountDetail.rowSelected[0].account.companyContinent},
                { label: "Tech Attribute", value: accountDetail.rowSelected[0].account.companyTechAttributes},
                // { label: "Product", value: accountDetail.rowSelected[0].account.companyProducts },
                // { label: "Hashtag", value: accountDetail.rowSelected[0].account.companyHashTag }
           ]
        };
    }

}



function Row(props) {
    const { row } = props;
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();

    return (
        <React.Fragment>
            <TableRow className={classes.root}>
                
                <TableCell component="th" scope="row" style={{ fontStyle: 'normal', fontWeight: 'bold', fontSize: '16px', whiteSpace: 'nowrap', borderBottom: 'none' }}>
                    {row.name}
                </TableCell>
                <TableCell style={{  width: '40px', borderBottom: 'none' }}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon style={{"color":"#21DA8C"}} /> : <KeyboardArrowDownIcon style={{"color":"#21DA8C"}} />}
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0, borderBottom: 'none' }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            {/* <Typography variant="h6" gutterBottom component="div">
                History
              </Typography> */}
                            <Table size="small" aria-label="purchases">
                                <TableHead>
                                    <TableRow></TableRow>
                                </TableHead>
                                <TableBody>
                                    {row.information.map((informationRow) => (
                                        <TableRow key={informationRow.label}>
                                            <TableCell component="th" scope="row" style={{ fontfamily: 'Roboto', fontStyle: 'normal', fontWeight: 'normal', fontSize: '14px', lineHeight: '16px', whiteSpace: 'nowrap', color: '#039E89' }}>
                                                {/* {informationRow.label} */}

                                                <div style={{ display: 'flex', flexDirection: 'column'}}> 
                                               <div className={classes.label}>{informationRow.label}</div>
                                                <div className={classes.value}>{informationRow.value == null || informationRow.value == "" ? '-' : informationRow.value}</div>
                                                </div>
                                            </TableCell>
                                            {/* <TableCell style={{ fontfamily: 'Roboto', fontStyle: 'normal', fontWeight: '500', fontSize: '14px', lineHeight: '22px', whiteSpace: 'nowrap', borderBottom: 'none' }}>{informationRow.value==null || informationRow.value==""?'-':informationRow.value}</TableCell> */}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}

Row.propTypes = {
    row: PropTypes.shape({
        calories: PropTypes.number.isRequired,
        carbs: PropTypes.number.isRequired,
        fat: PropTypes.number.isRequired,
        history: PropTypes.arrayOf(
            PropTypes.shape({
                amount: PropTypes.number.isRequired,
                customerId: PropTypes.string.isRequired,
                date: PropTypes.string.isRequired
            })
        ).isRequired,
        name: PropTypes.string.isRequired,
        price: PropTypes.number.isRequired,
        protein: PropTypes.number.isRequired
    }).isRequired
};


//const rows = [createData("Organisation Information"), createData("Contact Information"), createData("Additional Information")];
const OrganisationalInformation = () => {
    const accountDetail = React.useContext(AccountProviderContext)
    const rows = [createData("Organisation Information", accountDetail), createData("Contact Information", accountDetail), createData("Additional Information", accountDetail)];
    return (
        <TableContainer style={{paddingTop:"30px"}}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        {/* <TableCell /> */}
                        {/* <TableCell>Account Details</TableCell> */}
                        {/* <TableCell align="right"></TableCell> */}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <Row key={row.name} row={row} />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export default OrganisationalInformation