let formData = {
    "userId": "",
    "tenantId": "",
    "msg": "",
    "rowSelected": [
      {
        "account": {
          "accountId": "",
          "companyName": "",
          "companyParent": null,
          "companyGroupName": null,
          "companyWebsite": "",
          "companyParentWebsite": null,
          "companyGroupWebsite": null,
          "companyPrimaryIndustryCategory": null,
          "companySubIndustryCategory": null,
          "companyBrandRevenueNumerical": null,
          "companyEmployeeCount": null,
          "companyPhone": null,
          "companyFax": null,
          "companyStreet": null,
          "companyCity": null,
          "companyState": null,
          "companyCountry": null,
          "companyZipCode": null,
          "companyContinent": null,
          "companyDescription": null,
          "companyLinkedin": null,
          "companyFacebook": null,
          "companyTwitter": null,
          "companyLocationCount": null,
          "companyProducts": null,
          "companyHashTag": null,
          "companyType": null,
          // "companyHierarchy": null,
          "companyTechAttributes": null,
          "companyClassification": null,
          // "companyAccountRegion": null
        },
        "funding": {
          "companyRecentFundingDate": null,
          "companyTotalFundingAmount": null,
          "companyFundingList": null,
          "companyRecentFundingAmount": null
        },
        "crm": {
          "crmBusinessFormat": null,
          "crmISExecutive": null,
          "crmFSExecutive": null,
          "crmFmExecutive": null,
          // "crmAccountRegion": null,
          "crmNumberOfStores": null,
          "crmAccountGeography": null
        },
        "row_id": null,
        "msg": "",
        "contactSource": null
      }
    ]
  };

  export default formData