import React from "react";
import clsx from "clsx";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import KeyboardArrowRightSharpIcon from "@material-ui/icons/KeyboardArrowRightSharp";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import ExtensionIcon from "@material-ui/icons/Extension";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import OrganisationalInformation from "./OrganisationalInformation";
import { AccountProviderContext } from "./AccountDetail";
import { AppsOutlined } from "@material-ui/icons";
import axios from "axios";
import FormPropsTextFields from "./accountDetailsForm";
import SaveButton from "./saveButton";
import accountSearchBar from "./accountSearchBar";
//import SearchTextBox from "./searchTextBox";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import formData from "./data";
import AlertDialog from "./DialogSidebar";
import { API_BASE_URL } from "../constants";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import UITheme from "@bamboobox/b2-theme"
import EnrichIcon from "../icons/enrichIcon"
import { styled } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import "./../toast.css"

const localhost = API_BASE_URL;

const drawerWidth = 400;
const drawerHeight = 48;

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    background: "#FFFFFF",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
    width:"30%",
    borderRadius: "16px",
    justifyContent: "space-between"
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  hide: {
    display: "none",
  },
  drawer: {
    //width: drawerWidth,
    flexShrink: 0,
    height: `calc(100% - ${drawerHeight}px)`,
  },
  drawerPaper: {
    width: drawerWidth,
    top: "auto",
    height: "90%",
    marginTop: "2px",
    //height: `calc(100% - ${drawerHeight}px)`,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-end",
    // paddingTop: '62px'
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  },
}));



const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#595959",
    color: '#FFFFFF',
    boxShadow: theme.shadows[1],
    fontSize: 13,
    borderRadius:"18px",
    fontWeight:"600",
    fontSize: "13px",
    lineHeight: "13px"
  },
}));


export default function AccountListDetailSidebar(props) {
  const classes = useStyles();
  const theme = useTheme();
  const accountDetail = React.useContext(AccountProviderContext);
  const dataPassed = React.useContext(AccountProviderContext);
  console.log(dataPassed);
  const [open, setOpen] = React.useState(true);
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

  var adapt = {
    tenantId: tenantId,
    userId: userId,
    rows: [],
  };
  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClickOpen = () => {
    console.log("fdh");
    setOpenDialog(true);
  };

  const handleClose = () => {
    setOpenDialog(false);
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const onEdit = (e) => {
    console.log("onEdit has been pressed");
    //Route to Form page so that we can route to that page with the id of the account
    //props.history .push('/')
    e.preventDefault();
    window.updatedFormData = formData;
    props.setEditDisabled(true);
    // window.name = "newaccount";
    // window.updatedFormData.rowSelected[0].account.accountId = null
    props.setShowNewAccount(true);
  };

  const onDelete = () => {
    // axios.delete('/alok-nehasapi/' + accountDetail[0].accountId).then((response) => {
    //     });
    //         axios.delete(localhost+"/b2pm/deletedata/TTT-ID-00000/USR-ID-0000/account/"+ props.accountID)
    //          .then((account) => {
    //  if(account.status === 200)
    //  {
    //   props.callbackToSetGridVisibilty(false);
    //   props.setReload(true)
    //     alert("Account Deleted!")
    //  }
    //});
  };

  const onEnrichment = () => {
    console.log("Add to Enrichment queue button has been added");

    var json = { id: props.accountID };
    adapt.rows.push(json);
    axios.post(localhost + "/b2pm/account/add", adapt,{withCredentials:true}).then((account) => {

      if (account.status === 200) {
          toast.success(<Alert severity="success">Added to Enrichment Queue successfully</Alert>,{
            closeOnClick: true,
            autoClose: true,
            closeButton: true,
            newestOnTop: true
          });
      }
    });
  };

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <div style={{ display: 'flex', flexDirection: 'column' }}> */}
      {/* <div className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}> */}
      {/* <AccountDetailHeader /> */}
      {/* </div> */}
      <div></div>
      <div style={{ display: "flex" }}>
        <div
          className={classes.drawer}
          variant="persistent"
          anchor="left"
          open={props.open}
          classes={{
            paper: classes.drawerPaper,
          }}
        >
          <div
            className={classes.drawerHeader}
            style={{ display: "flex", justifyContent: "space-between",flexDirection:"column",padding:"40px"}}
          >
            <div style={{paddingBottom: "10px"}}>
            <Typography style={{fontWeight:"700", fontSize:"13px",color:"#595959"}}>ACCOUNT DETAILS</Typography>
            </div>
            <div style={{display: "flex", justifyContent:"space-between",width: "70%"}} >
              <LightTooltip title="Edit account information" aria-label="edit" arrow>
                <IconButton
                  aria-label="edit"
                  className={classes.margin}
                  style={{ padding: "2px", color: "rgba(3, 3, 3, 0.6)"}}
                  onClick={(e) => onEdit(e)}
                >
                  <EditIcon />
                  {/* onClick={() => onEdit(props.rowData.rowid, props.item.recordId)} */}
                </IconButton>
              </LightTooltip>
               
              <LightTooltip
                title="Add to enrichment queue"
                aria-label="enrich"
                arrow
              >
                <IconButton
                  aria-label="enrich"
                  className={classes.margin}
                  style={{ padding: "2px",color:"rgba(3, 3, 3, 0.6)" }}
                  onClick={() => onEnrichment()}
                >
                  <ExtensionIcon />
                </IconButton>
              </LightTooltip>

              <LightTooltip
                title="Delete account information"
                aria-label="delete"
                arrow
              >
                <IconButton
                  aria-label="delete"
                  className={classes.margin}
                  style={{ padding: "2px", color:"rgba(3, 3, 3, 0.6)" }}
                  onClick={() => onDelete()}
                >
                  {/* <DeleteIcon style={{ color: "#F7685B" }} />*/}
                  <AlertDialog
                    accountID={props.accountID}
                    callbackToSetGridVisibilty={
                      props.callbackToSetGridVisibilty
                    }
                    setReload={props.setReload}
                  />
                </IconButton>
              </LightTooltip>

              

              {/* <IconButton
                onClick={handleDrawerClose}
                style={{ padding: "2px" }}
              >
                {theme.direction === "ltr" ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton> */}
            </div>
          </div>
          <Dialog
            openDialog={openDialog}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Let Google help apps determine location. This means sending
                anonymous location data to Google, even when no apps are
                running.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} color="primary">
                Disagree
              </Button>
              <Button onClick={handleClose} color="primary" autoFocus>
                Agree
              </Button>
            </DialogActions>
          </Dialog>
          <Divider variant="middle" />
          {/* <List>
                        {['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
                            <ListItem button key={text}>
                                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List> */}
          {/* <UpperSideBarData />
                    <Divider variant="middle" /> */}
          <OrganisationalInformation />
          {/* <List>
                        {['All mail', 'Trash', 'Spam'].map((text, index) => (
                            <ListItem button key={text}>
                                <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                                <ListItemText primary={text} />
                            </ListItem>
                        ))}
                    </List> */}
        </div>
        {/* <main
                        className={clsx(classes.content, {
                            [classes.contentShift]: open,
                        })}
                    >
                        <div className={classes.drawerHeader} />
                        <AccountDetailTab />
                    </main> */}
        {/* </div> */}
      </div>

      {/* <AppBar
                position="fixed"
                className={clsx(classes.appBar, {
                    [classes.appBarShift]: open,
                })}
            >
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleDrawerOpen}
                        edge="start"
                        className={clsx(classes.menuButton, open && classes.hide)}
                    >
                        <MenuIcon />
                    </IconButton>
                    <Typography variant="h6" noWrap>
                        Persistent drawer
          </Typography>
                </Toolbar>
            </AppBar> */}
    </div>
  );
}
