import React from "react";
import axios from 'axios';
import { css } from "glamor";
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
//import ServerAutoSuggest from './Typeahead.js'
import "./gridindex.css";
import {AccountDetail} from './components_detailview/AccountDetail'
import {API_BASE_URL} from './constants' 
import {useAuthState, AuthProvider} from "@bamboobox/b2logincheck";
import Alert from '@mui/material/Alert';
import "./toast.css"

const localhost = API_BASE_URL;

//Snackbar
toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});


const EDIT_SVG = (
  <svg height="16" viewBox="0 0 20 20" width="16" xmlns="http://www.w3.org/2000/svg">
    <g fill="#fff" stroke="#1856bf" transform="translate(2 2)">
      <path
        d="m8.24920737-.79402796c1.17157287 0 2.12132033.94974747 2.12132033 2.12132034v13.43502882l-2.12132033 3.5355339-2.08147546-3.495689-.03442539-13.47488064c-.00298547-1.16857977.94191541-2.11832105 2.11049518-2.12130651.00180188-.00000461.00360378-.00000691.00540567-.00000691z"
        transform="matrix(.70710678 .70710678 -.70710678 .70710678 8.605553 -3.271644)"
      />
      <path d="m13.5 4.5 1 1" />
    </g>
  </svg>
);
const CANCEL_SVG = (
  <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <g fill="none" stroke="#dc1e1e" transform="translate(5 5)">
      <path d="m.5 10.5 10-10" />
      <path d="m10.5 10.5-10-10z" />
    </g>
  </svg>
);
const SAVE_SVG = (
  <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
    <path d="m.5 5.5 3 3 8.028-8" fill="none" stroke="#4caf50" transform="translate(5 6)" />
  </svg>
);

const TRASH_ICON = (
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash" viewBox="0 0 16 16">
  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"/>
  <path fillRule="evenodd" d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1
  -1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"/>
  </svg>
);

const styles = {
  select: { marginBottom: '10px', width: '100%', height: '80%', display: 'flex', justifyContent: 'flex-center', alignItems: 'center', borderColor: '#0000', borderBottom: '1px solid #eee', borderRadius: '0%', background: '#fefefe', },  buttonsCellContainer: { padding: '0 20px', width: '100%', height: '100%', display: 'flex', justifyContent: 'flex-end', alignItems: 'center' },
  editButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', padding: 4, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
  buttonsCellEditorContainer: { height: '100%', width: '100%', display: 'inline-flex', padding: '0 20px', justifyContent: 'flex-end', alignItems: 'center' },
  cancelButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', marginRight: 10, padding: 2, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
  saveButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', padding: 2, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
  deleteButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer',marginLeft: 9, padding: 4, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' }

}





const GetColumns = ({ setRowsData }, {getAccountId}, {getPreviousAccountId},{getNextAccountId}) => {
  
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

    var saveadapt = {
       
        "tenantId": tenantId,
        "userId": userId ,
        "msg" :"",
        "rowSelected": []
    }


    const deleteSingleRow = (e,rowIndex,rowsClone)=>{
      console.log(e);
    
      console.log(rowIndex);
      var cId = rowsClone[rowIndex-1]["accountId"];
      console.log(rowsClone[rowIndex-1]["accountId"]);
      axios.delete(localhost+"/b2pm/deletedata/"+tenantId+"/"+userId+"/account/"+cId,{withCredentials:true})
      rowsClone.splice(rowIndex-1,1);  
      var data = rowsClone;
      setRowsData([...data]);
      
  }
  
 function addNewRow(rowsClone,z){   

    var obj = {
      "id": z, 
      "row_id": z,
      "msg": "",
      "accountSource": "Manual",
      "accountId": "",
      "companyName": "",
      "companyGroupName": "",
      "companyWebsite": "",
      "companyParentWebsite": "",
      "companyGroupWebsite": "",
      "companyPrimaryIndustryCategory": "",
      "companySubIndustryCategory": "",
      "companyBrandRevenueNumerical": "",
      "companyEmployeeCount": "",
      "companyFax": "",
      "companyPhone": "",
      "companyStreet": "",
      "companyCity": "",
      "companyState": "",
      "companyCountry": "",
      "companyContinent": "",
      "companyZipCode": "",
      "companyDescription": "",
      "companyLinkedin": "",
      "companyFacebook": "",
      "companyTwitter": "",
      "companyParent": "",
      // "companyHierarchy": "",
      "companyLocationCount": "",
      "companyProducts": "",
      "companyType": "",
      "companyClassification": "",
      "companyTechAttributes": "",
      "companyHashTag": "",
      "companyRecentFundingAmount": "",
      "companyRecentFundingDate": "",
      "companyTotalFundingAmount": "",
      "companyFundingList": "",
    
      // "companyAccountRegion": "",
      // "crmAccountRegion": "",
      "crmFmExecutive": "",
      "crmNumberofStores": "",
      "crmAccountGeography":"",
      "crmBusinessFormat": "",
      "crmISExecutive": "",
      "crmFSExecutive": ""
    };

    var data = rowsClone;
    data.unshift(obj);

    setRowsData([...data]);


  
    
  }
    
function saveAccountfromRow(row) {
         // var account = [..row];
         var account =
         {
             
                "accountId": row.accountId,
                "companyName": row.companyName,
                "companyGroupName": row.companyGroupName,
                "companyWebsite": row.companyWebsite,
                "companyParentWebsite": row.companyParentWebsite,
                "companyGroupWebsite": row.companyGroupWebsite,
                "companyPrimaryIndustryCategory": row.companyPrimaryIndustryCategory,
                "companySubIndustryCategory": row.companySubIndustryCategory,
                "companyBrandRevenueNumerical": row.companyBrandRevenueNumerical,
                "companyEmployeeCount": row.companyEmployeeCount,
                "companyFax": row.companyFax,
                "companyPhone": row.companyPhone,
                "companyStreet": row.companyStreet,
                "companyCity": row.companyCity,
                "companyState": row.companyState,
                "companyCountry": row.companyCountry,
                "companyContinent": row.companyContinent,
                "companyZipCode": row.companyZipCode,
                "companyDescription": row.companyDescription,
                "companyLinkedin": row.companyLinkedin,
                "companyFacebook": row.companyFacebook,
                "companyTwitter": row.companyTwitter,
                "companyParent": row.companyParent,
                // "companyHierarchy": row.companyHierarchy,
                "companyLocationCount": row.companyLocationCount,
                "companyProducts": row.companyProducts,
                "companyType": row.companyType,
                "companyClassification": row.companyClassification,
                "companyTechAttributes": row.companyTechAttributes,
                "companyHashTag": row.companyHashTag,
                "surging_country":row.surging_country
         }
         return account;
     }
    
    
     
    
    function saveFundingfromRow(row) {
         //var funding = [..row];
         var funding = {
             "companyRecentFundingAmount": row.companyRecentFundingAmount,
             "companyRecentFundingDate": row.companyRecentFundingDate,
             "companyTotalFundingAmount": row.companyTotalFundingAmount,
             "companyFundingList": row.companyFundingList,
            //  "companyAccountRegion": row.companyAccountRegion
         }
    
         return funding;
    
     }
    
    function saveCRMfromRow(row)
    {   

        //var crm = [...row];
        var crm = {

                // "crmAccountRegion": row.crmAccountRegion,
                "crmFmExecutive": row.crmFmExecutive,
                "crmNumberofStores": row.crmNumberofStores,
                "crmAccountGeography":row.crmAccountGeography,
                "crmBusinessFormat": row.crmBusinessFormat,
                "crmISExecutive": row.crmISExecutive,
                "crmFSExecutive": row.crmFSExecutive  
    
    }
    
         return crm;
    
    }

    function check_mandatory_accountFields(rowsClone, updatedRowIndex){
      if (!(rowsClone[updatedRowIndex].companyWebsite) 
      || !(rowsClone[updatedRowIndex].companyWebsite).trim()
      || !(rowsClone[updatedRowIndex].companyName)
      || !(rowsClone[updatedRowIndex].companyName).trim())
      {
      alert("No Mandatory fields passed");
      return false;
      } else { 
      return true;
      }
      }
     


    const save_data  = async(rowsClone, updatedRowIndex) => {
 
    // console.log("SAVEDATA");
    // console.log(rowsClone.length);
   
    // console.log(rowsClone);
    // console.log(updatedRowIndex);
    // console.log("-------------")
    

    var json = {"row_id" : updatedRowIndex , 
                "accountSource" : rowsClone[updatedRowIndex].accountSource,
                "msg" : rowsClone[updatedRowIndex].msg 
              };
    var account = saveAccountfromRow(rowsClone[updatedRowIndex])
    json["account"] = account;
    var funding = saveFundingfromRow(rowsClone[updatedRowIndex])
    json["funding"] = funding;
    var crm= saveCRMfromRow(rowsClone[updatedRowIndex])
    json["crm"] = crm;
   
   saveadapt.rowSelected.push(json);
   // console.log("Push SAVEDATA");
   // console.log(saveadapt)


      var resp = "";  
      resp = await axios.post(localhost+"/b2pm/savedata/account", saveadapt,{withCredentials:true});
      let tabledata = resp;
    
      let length = tabledata.data.rowSelected.length-1;
      let currentrow = tabledata.data.rowSelected[length];
      let row =  {id: rowsClone[updatedRowIndex].id,
                  row_id: rowsClone[updatedRowIndex].id,
                  msg:currentrow.msg,
                  accountSource: currentrow.accountSource,
                  ...currentrow.account,...currentrow.crm,...currentrow.funding};
        
      (function removeNull(o) {
          for(var key in o) {
              if( null === o[key] ) o[key] = '';
              if ( typeof o[key] === 'object' ) removeNull(o[key]);
          }
       })(row);
      
      rowsClone[updatedRowIndex] = row;

  
   
      setRowsData(rowsClone);
     

      if (resp.status == 200) {
          //  SNACKBAR TOAST
               
            if(rowsClone[1].accountId == "")
                   {  
                      
                       rowsClone.splice(0,1);
                        //console.log(rowsClone)
                        setRowsData(rowsClone);
                       
                     }
              toast.success(<Alert severity="success">Account has been added successfully!</Alert>,{
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
            
            
            
         }
         
  }
  return [
    {
    id: "checkbox",
    visible: true,
    pinned: true,
    width: "54px",
    },
    {
    id: "1",
    field: "companyName",
    label: "Company Name" ,
    visible: true,
    width:"200px",
    pinned: true,
    // sort: ({a, b, isAscending}) => { },
    cellRenderer: ({ tableManager, value, data, column, colIndex, rowIndex }) => (

      // <a href="#" onClick={() => {this.handleClick}}>click me</a>
      <a href='#' className="rgt-cell-inner rgt-text-truncate"
                onClick={e => {getAccountId(data.accountId);
                   
                 
                   // console.log(rowIndex-1)
                   //  console.log([...tableManager.rowsApi.rows][rowIndex-1])
                   //  console.log([...tableManager.rowsApi.rows])
                   getPreviousAccountId([...tableManager.rowsApi.rows],rowIndex-1);
                  getNextAccountId([...tableManager.rowsApi.rows],rowIndex-1);
                  //  //Present
                  //   console.log([...tableManager.rowsApi.rows][rowIndex-1]);
                  //   console.log("Prev")
                  // console.log([...tableManager.rowsApi.rows][rowIndex-2].accountId);
                  // console.log("New")
                  // console.log([...tableManager.rowsApi.rows][rowIndex].accountId);
                } }
            >{value}</a>
        )
      
          
    },


    {
    id: "2",
    field: "companyWebsite",
    label: "Company Website",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,


    },
    {
    id: "3",
    field: "companyParent",
    label: "Parent Company",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    {
    id: "4",
    field: "companyGroupName",
    label: "Group Name",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
   
    
   
    {
    id: "5",
    field: "companyParentWebsite",
    label: "Parent Website",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    {
    id: "6",
    field: "companyGroupWebsite",
    label: "Group Website",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    {
    id: "7",
    field: "companyPrimaryIndustryCategory",
    label: "Industry",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    {
    id: "8",
    field: "companySubIndustryCategory",
    label: "Sub-Industry",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
   
    {
    id: "9",
    field: "companyBrandRevenueNumerical",
    label: "Company Revenue ($)",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    // {
    // id: "10",
    // field: "companyEmployeeCount",
    // label: "Company Employee Range",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    {
    id: "11",
    field: "companyPhone",
    label: "Phone",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    // {
    // id: "12",
    // field: "companyFax",
    // label: "Fax",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    // {
    // id: "13",
    // field: "companyStreet",
    // label: "Mailing Street",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
    {
      id: "16",
      field: "companyCountry",
      label: "Mailing Country",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      },
      {
        id: "15",
        field: "companyState",
        label: "Mailing State",
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
        },
    {
    id: "14",
    field: "companyCity",
    label: "Mailing City",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },

   
  
   
    // {
    // id: "17",
    // field: "companyZipCode",
    // label: "Mailing ZipCode",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    // {
    // id: "18",
    // field: "companyContinent",
    // label: "Continent",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    {
    id: "19",
    field: "companyDescription",
    label: "Company Description",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
   
    {
    id: "20",
    field: "companyLinkedin",
    label: "Linkedin URL",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
   
    {
    id: "21",
    field: "companyFacebook",
    label: "FaceBook URL",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
   
    {
    id: "22",
    field: "companyTwitter",
    label: "Twitter URL",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    
    // {
    // id: "23",
    // field: "companyLocationCount",
    // label: "Location Count",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    // {
    // id: "24",
    // field: "companyProducts",
    // label: "Products",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    // {
    // id: "25",
    // field: "companyHashTag",
    // label: "Hashtags",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    {
    id: "26",
    field: "companyType",
    label: "Company Type",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
   
    // {
    // id: "27",
    // field: "companyHierarchy",
    // label: "Company Hierarchy",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    {
    id: "28",
    field: "companyTechAttributes",
    label: "Tech Attributes",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
   
    // {
    // id: "29",
    // field: "companyRecentFundingDate",
    // label: "Recent Funding Date",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    // {
    // id: "30",
    // field: "companyTotalFundingAmount",
    // label: "Total Funding Amount",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
   
    // {
    // id: "31",
    // field: "companyFundingList",
    // label: "Company Funding",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
    // {
    // id: "32",
    // field: "companyRecentFundingAmount",
    // label: "Recent Funding Amount",
    // visible: true,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
    {
    id: "33",
    field: "companyClassification",
    label: "Account Classification",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
   
   
    {
    id: "35",
    field: "crmFSExecutive",
    label: "Field Sales",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    {
    id: "36",
    field: "crmISExecutive",
    label: "Inside Sales",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    {
    id: "37",
    field: "crmFmExecutive",
    label: "Field Marketing",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    // {
    //   id: "37",
    //   field: "crmAccountRegion",
    //   label: "Account Region",
    //   visible: true,
    //   searchable: true,
    //   editable: true,
    //   sortable: true,
    //   resizable: true,
    //   },
    {
    id: "38",
    field: "crmBusinessFormat",
    label: "Business Format",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },

    {	
      id: "crmNumberOfStores",	
      field: "crmNumberOfStores",	
      label: "Number of Stores",	
      visible: true,	
      searchable: true,	
      editable: true,	
      sortable: true,	
      resizable: true,	
      },

    {	
      id: "crmAccountGeography",	
      field: "crmAccountGeography",	
      label: "Capillary Geography",	
      visible: true,	
      searchable: true,	
      editable: true,	
      sortable: true,	
      resizable: true,	
      },
	
      // {
      //   id: "34",
      //   field: "companyAccountRegion",
      //   label: "Account Region",
      //   visible: true,
      //   searchable: true,
      //   editable: true,
      //   sortable: true,
      //   resizable: true,
      //   },
    {
    id: "39",
    field: "accountSource",
    label: "Account Source",
    visible: true,
    searchable: true,
    editable: true,
    sortable: true,
    resizable: true,
    },
    {
      id: "40",
      field: "numberOfContacts",
      label: "Number of Contacts",
      visible: true,
      searchable: true,
      editable: true,
      sortable: true,
      resizable: true,
      },
      {
        id: "41",
        field: "bomboraSurgingCountry",
        label: "Country (Bombora)",
        visible: true,
        searchable: true,
        editable: true,
        sortable: true,
        resizable: true,
    
    
        },
      
        
    // {
    // id: "msg",
    // field: "msg",
    // label: "msg",
    // visible: false,
    // searchable: true,
    // editable: true,
    // sortable: true,
    // resizable: true,
    // },
    
    ];
   };
   
   
   export default GetColumns;











//    //npm.pkg.github.com/:_authToken=${NPM_TOKEN}
// email=ashwin@bamboobox.ai
// always-auth=true