import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./gridindex.css";
import getColumns from "./getContactColumns";
import axios from 'axios';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles, useTheme ,withStyles} from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { red,green } from '@material-ui/core/colors'
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import {useAuthState, AuthProvider} from "@bamboobox/b2logincheck";

import {API_BASE_URL} from '../constants.js'
import Alert from '@mui/material/Alert';
import "./../toast.css"

const localhost = API_BASE_URL;
const drawerWidth = 400;
const drawerHeight = 48;

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});

const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    appBar: {
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        width: `calc(100% - ${drawerWidth}px)`,
        marginLeft: drawerWidth,
        transition: theme.transitions.create(['margin', 'width'], {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    menuButton: {
        marginRight: theme.spacing(2),
    },
    hide: {
        display: 'none',
    },
    drawer: {
        //width: drawerWidth,
        flexShrink: 0,
        height: `calc(100% - ${drawerHeight}px)`,

    },
    drawerPaper: {
        width: drawerWidth,
        top: 'auto',
        height: '90%',
        marginTop: '2px'
        //height: `calc(100% - ${drawerHeight}px)`,

    },
    drawerHeader: {
        display: 'flex',
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        // necessary for content to be below app bar
        ...theme.mixins.toolbar,
        justifyContent: 'flex-end',
        // paddingTop: '62px'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: -drawerWidth,
    },
    contentShift: {
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.easeOut,
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginLeft: 0,
    },
}));


export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const TRASH_ICON = <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0.777778 12.4444C0.777778 13.3 1.47778 14 2.33333 14H8.55556C9.41111 14 10.1111 13.3 10.1111 12.4444V3.11111H0.777778V12.4444ZM10.8889 0.777778H8.16667L7.38889 0H3.5L2.72222 0.777778H0V2.33333H10.8889V0.777778Z" fill="#F7685B"/>
</svg>

// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }

const [state, setState] = React.useState({

  vertical: 'top',
  horizontal: 'center',
});
const userData = useAuthState();
let tenantId = userData.user.tenantId;
let userId = userData.user.userUuid;
const { vertical, horizontal } = state;
const [openSnackbar,setOpenSnackbar] = useState(false);
const [message,setMessage] = useState("");
const [severity,setSeverity] = useState("");
const closeSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
      return;
  }
  setOpenSnackbar(false);
};

const classes = useStyles();
const theme = useTheme();

  const styles = {
 select: { marginBottom: '20px', marginTop: '20px', width: '95%', height: '50%', outline:'none',display: 'flex', justifyContent: 'flex-center', alignItems: 'center', borderColor: '#0000', borderBottom: '1px solid #eee', borderRadius: '0%', background: '#fefefe',padding: '0%', },
 editButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', padding: 4, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
 buttonsCellEditorContainer: { height: '100%', width: '100%', display: 'inline-flex', padding: '0 20px', justifyContent: 'flex-end', alignItems: 'center' },
 cancelButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', marginRight: 10, padding: 2, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
 saveButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', padding: 2, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
 deleteButton: { background: '#f3f3f3', cursor: 'pointer',marginLeft: 0, padding: 0, display: 'inline-flex', border: 'none', borderRadius: '50%'}

}

const onDelete = () =>{

    // if (window.confirm('Are you sure you want to delete this contact ?')) {
    //   // Save it!
    axios.delete(localhost+"/b2pm/deletedata/"+tenantId+"/"+userId+"/account/"+ props.accountID,{withCredentials:true})
    .then((account) => {

console.log(account);

if(account.status === 200)
{  

props.callbackToSetGridVisibilty(false);
props.setReload(true)




}
    })
    
      toast.error(<Alert severity="error">Account Deleted successfully</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
    
  



}

const NoButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    marginLeft: '2px',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },

  },
}))(Button);
const YesButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);



  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
// style={styles.deleteButton} 
  return (
    <div>
    
      <IconButton aria-label="delete" className={classes.margin} style={{ padding: '2px' }} onClick={handleClickOpen}>

      <DeleteIcon />
      </IconButton>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete the account, the contacts of this account will also get deleted?"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
            
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
        <Button variant="contained" color="primary" onClick={()=> onDelete()} >
           Yes
          </Button>
          <Button variant="outline"  color="primary" onClick={handleClose} autoFocus >
            No
          </Button>
         
        </DialogActions>
      </Dialog>
      <Snackbar  anchorOrigin={{ vertical, horizontal }} key={vertical + horizontal}  open={openSnackbar} autoHideDuration={6000} onClose={closeSnackbar}>
        <Alert onClose={closeSnackbar} severity={severity}>
            {message}
        </Alert>
    </Snackbar>
    </div>
  );
}
