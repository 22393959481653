import React, { useEffect, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';//backArrow
//import SearchBar from "./SearchBar";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import DynamicGreenTab from "./DynamicGreenTab";
import { AccountProviderContext } from "./AccountDetail";
import axios from 'axios';
import Button from '@material-ui/core/Button';
import { Typography } from '@material-ui/core';
import formData from './data';
import NewAccount from "../icons/newAccountIcon"
import AccountSearchBar from './accountSearchBar';
import UITheme from '@bamboobox/b2-theme';
import Divider from '@mui/material/Divider';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import {API_BASE_URL, MARKETO_WEB_FORM_FILL} from '../constants';


const localhost = API_BASE_URL;

const useStyles = makeStyles((theme) => ({
    mainBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
}));

const mainDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 16px',
    width: '100%',
    height: '62px',
    position: 'sticky',
    top:0,
    zIndex:200,
    background:'#FFFFFF'
}

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#595959",
      color: '#FFFFFF',
      boxShadow: theme.shadows[1],
      fontSize: 13,
      borderRadius:"18px",
      fontWeight:"600",
      fontSize: "13px",
      lineHeight: "13px"
    },
  }));

const AccountListDetailHeader = (props) => {
    const accountDetail = React.useContext(AccountProviderContext)
    const classes = useStyles();

//console.log(accountDetail.rowSelected[0].account.companyName);

  const onNewAccountClick = (e) =>{
    e.preventDefault();
    window.updatedFormData = formData;
    window.name = "newaccount";
    window.updatedFormData.rowSelected[0].account.accountId = null
    //window.location.reload(false);
   props.setAcType('NewAccount')
 //  props.setAccountIDClicked(null)
   props.setNewDisabled(true)
 //  props.setAccountId(window.updatedFormData.rowSelected[0].account.accountId)
   props.setShowNewAccount(true);
  }

    const onBack = () => {
        console.log("Go back to Account List page ")
        if(props.isRouted === false)
            {
                props.setDisabled(false);
                //props.history.push('/AccountHub/')
                //if it is a parent child relation then we have to send a callback function so that it can render the grid with some status true or false
                props.callbackToSetGridVisibilty(false);
            }

            else{
               // props.history.goBack();
                // props.history.push('/');
                window.location.href=MARKETO_WEB_FORM_FILL;
                // <a href ='http://login.bamboobox.in/#/web-form-fill'></a>
            }
        
    }

    const getPreviousNextAccount = (previousNextAccountId) => {

     
        props.setShowNewAccount(false)
        props.getAccountDetailviaCallback(previousNextAccountId)
    }

    const handleSideBar=()=>{
        props.setOpen(!props.open)
    }

    return (
           <div style={mainDiv} classes={{ mainBar: classes.mainBar }}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                    <React.Fragment>
                    {props.newdisable||props.editdisable? <React.Fragment></React.Fragment> :
                        <IconButton aria-label="back">
                            <ArrowBackIosIcon style={{ marginRight: "-10px", height: "20px",color: '#37CAD3' }} onClick={onBack} />
                        </IconButton>}
                        {props.newdisable ?  

                     <Typography variant="h6" style={{ color: UITheme.palette.primary.dark, marginRight: '20px',color: '#595959' }}>Create New Account</Typography     >
                       : <div style={{display: 'flex',alignItems: 'center'}}>
                         <Typography  style={{ marginRight: '10px', fontSize:"20px", fontWeight:"700", color: '#595959' }}>
                           {accountDetail.rowSelected[0].account.companyName}
                           </Typography>
                       <LightTooltip title={props.open===true?"Click to close Account details panel":"Click to see Account details panel"}>
                       <InfoOutlinedIcon onClick={handleSideBar} style={props.open?{color:"#37CAD3"}:{color:"#A0A0A0"}} />
                       </LightTooltip></div>  
                    }
                        </React.Fragment>
                        
                </div>
                {/* //||props.editdisable */}
                {props.newdisable|| props.isRouted? <React.Fragment></React.Fragment> :
                <div style={{ display: 'flex', justifyContent: 'center',position: 'relative',right:"1%",alignItems: 'center'}}>
                     {props.rowsLength == 1 || props.isRouted || props.disable || props.newdisable? <React.Fragment></React.Fragment> :

                    <React.Fragment>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <IconButton aria-label="back" style={{ padding: '0px', fontSize: '2rem' }} onClick={() => getPreviousNextAccount(props.previousAccountId)} >
                        <svg width="30" height="17" viewBox="0 0 30 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.17229 7.29303L10.2886 0.293041C10.5342 0.110883 10.8632 0.0100885 11.2046 0.0123669C11.5461 0.0146453 11.8727 0.119814 12.1142 0.305222C12.3557 0.49063 12.4926 0.741442 12.4956 1.00364C12.4986 1.26583 12.3673 1.51844 12.1301 1.70704L5.23685 7.00003H28.1396C28.485 7.00003 28.8162 7.10539 29.0605 7.29292C29.3047 7.48046 29.4419 7.73481 29.4419 8.00003C29.4419 8.26525 29.3047 8.5196 29.0605 8.70713C28.8162 8.89467 28.485 9.00003 28.1396 9.00003H5.23685L12.1301 14.293C12.2544 14.3853 12.3537 14.4956 12.4219 14.6176C12.4902 14.7396 12.5261 14.8708 12.5276 15.0036C12.5291 15.1364 12.4962 15.2681 12.4307 15.391C12.3652 15.5139 12.2685 15.6255 12.1462 15.7194C12.0239 15.8133 11.8785 15.8876 11.7185 15.9378C11.5584 15.9881 11.3869 16.0134 11.214 16.0123C11.0411 16.0111 10.8702 15.9835 10.7113 15.9311C10.5524 15.8787 10.4087 15.8025 10.2886 15.707L1.17229 8.70703C0.928137 8.5195 0.790983 8.26519 0.790983 8.00003C0.790983 7.73487 0.928137 7.48056 1.17229 7.29303V7.29303Z" fill="#37CAD3"/>
                                    </svg>
                                    <div style={{ fontStyle: "normal",fontWeight: "normal",fontSize: "14px",lineHeight: "17px",display: "flex",alignItems: "center",color: "#37CAD3",padding: "0px 12px 0px 12px"}}>Prev</div>
                        </IconButton>
                    </div>
                    <Divider orientation="vertical" style={{height: '16px'}} />
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <IconButton aria-label="back" style={{  padding: '0px', fontSize: '2rem' }} onClick={() => getPreviousNextAccount(props.nextAccountId)}>
                        <div style={{ fontStyle: "normal",fontWeight: "normal",fontSize: "14px",lineHeight: "17px",display: "flex",alignItems: "center",color: "#37CAD3",padding: "0px 12px 0px 12px"}}>Next</div>
                           <svg width="29" height="17" viewBox="0 0 29 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M28.316 7.29303L19.1997 0.293041C18.9541 0.110883 18.6251 0.0100885 18.2836 0.0123669C17.9422 0.0146453 17.6155 0.119814 17.3741 0.305222C17.1326 0.49063 16.9957 0.741442 16.9927 1.00364C16.9897 1.26583 17.121 1.51844 17.3582 1.70704L24.2514 7.00003H1.34871C1.00331 7.00003 0.672063 7.10539 0.427829 7.29292C0.183596 7.48046 0.0463867 7.73481 0.0463867 8.00003C0.0463867 8.26525 0.183596 8.5196 0.427829 8.70713C0.672063 8.89467 1.00331 9.00003 1.34871 9.00003H24.2514L17.3582 14.293C17.2338 14.3853 17.1346 14.4956 17.0664 14.6176C16.9981 14.7396 16.9622 14.8708 16.9607 15.0036C16.9592 15.1364 16.9921 15.2681 17.0576 15.391C17.1231 15.5139 17.2198 15.6255 17.3421 15.7194C17.4644 15.8133 17.6098 15.8876 17.7698 15.9378C17.9299 15.9881 18.1014 16.0134 18.2743 16.0123C18.4472 16.0111 18.6181 15.9835 18.777 15.9311C18.9359 15.8787 19.0796 15.8025 19.1997 15.707L28.316 8.70703C28.5601 8.5195 28.6973 8.26519 28.6973 8.00003C28.6973 7.73487 28.5601 7.48056 28.316 7.29303V7.29303Z" fill="#37CAD3"/>
                                        </svg>
                        </IconButton>
                    </div>
                  </React.Fragment>}
                   <div style={{paddingLeft:"30px"}}>
                    <AccountSearchBar    AccountId= {props.AccountId} setDisabled={props.setDisabled}  setAccountIDClicked={props.setAccountIDClicked} setShowNewAccount={props.setShowNewAccount}  accountID={props.accountID}  />
                    </div>
                    <div style={{ display: 'flex' }}>
                    {props.rowsLength == 1 || props.isRouted || props.disable || props.newdisable || props.editdisable? <React.Fragment></React.Fragment> :
                    <React.Fragment>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: '30px',paddingTop:'16px', paddingRight:"16px" }}>
                           <LightTooltip title="New Account">
                            <IconButton aria-label="back" style={{ padding: '0px', fontSize: '3rem' }} onClick={(e) => {onNewAccountClick(e)}} >
                            <svg width="12" height="13" paddingLeft="3px" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M11.2499 7.29183H6.74988V12.0418H5.24988V7.29183H0.749878V5.7085H5.24988V0.958496H6.74988V5.7085H11.2499V7.29183Z" fill="#21DA8C"/>
                            </svg>
                            <div style={{width:'4px'}}></div>
                            <NewAccount color="primary"/>
                            </IconButton >
                            </LightTooltip>
                            {/* <h2 style={{ fontSize: '12px', fontWeight: 'initial',  }}>New Account</h2> */}
                        </div>
                        </React.Fragment>}
                </div>
                </div>}
            </div>
    )
}

export default AccountListDetailHeader 

