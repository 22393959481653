import React, { useEffect, useState } from "react";
import { useParams ,useHistory} from 'react-router-dom';
import MyAwesomeTable from "./ContactCreation";
import GreenTab from "./GreenTab";
import InfoText from "./InfoText";
import ReactDOM from "react-dom";
import UpperSideBarData from "./UpperSideBarData";
// import ContactDetailGrid from "./ContactDetailGrid";
import axios from "axios";
import AccountListDetailSidebar from "./AccountListDetailSidebar";
import AccountListDetailHeader from "./AccountListDetailHeader";
import DynamicGreenTab from "./DynamicGreenTab";
//import FormPropsTextFields from "./Test";
import SimpleBackdrop from "./SimpleBackdrop";
import SimpleSnackbar from "./SimpleSnackbar";

import FormPropsTextFields from "./accountDetailsForm";
import SaveButton from "./saveButton";

import { API_BASE_URL } from "../constants";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";

const localhost = API_BASE_URL;
const AccountProviderContext = React.createContext(null);


// const accountID = 'f1292d6d-690c-433d-b98f-ebe00edead36'
//const accountID = 'f1292d6d-690c-433d-b98f-ebe00edead36'
//5db18ffe-3d57-4bba-bf56-fa51f50ef65d

const AccountDetail = (props) => {
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;
  let { routeAccountId } = useParams();
  let history = useHistory();


  window.TenantID = tenantId;
  window.name = "";
  const [passData, setPassData] = React.useState();
  const [backdropState, setBackdropState] = useState(true);
  const [snackbarState, setSnackbarState] = useState({
    open: false,
    severity: "",
    message: "",
  });
  const [newdisable, setNewDisabled] = useState(false);
  const [editdisable, setEditDisabled] = useState(false);


  const [prevID, setPrevID] = useState(props.accountIDClicked);
  let [showNewAccount, setShowNewAccount] = useState(false);
  let [AcType, setAcType] = useState("Existing");
  let [accountID,setAccountID] = useState(props.accountIDClicked)
  let [isRouted,setIsRouted] = useState(false)

  let [accountid, setAccountId] = useState("");
  const [backdropOnChange, setBackdropOnChange] = useState(false);
  //const accountID = props.accountIDClicked;
  window.accountid = props.accountIDClicked;
  console.log(props.accountIDClicked);
  console.log(props);
  // const prevAccountID = props.prevAccountID
  //   console.log(prevAccountID )
  //    const nextAccountID = props.nextAccountID
  //   console.log(nextAccountID)

  const getAccountDetail = async () => {
    if(props.accountIDClicked !==undefined) {
      setAccountID(props.accountIDClicked)
      await axios
        .get(
          localhost +
            "/b2pm/accountdetailed/account?tenantId=" +
            tenantId +
            "&accountId=" +
            props.accountIDClicked,
            {withCredentials:true}
        )
        .then((account) => {
          setPassData(account.data);
        });
      }
      else if(routeAccountId !==undefined) { 
 
       setIsRouted(true)
        setAccountID(routeAccountId)
        
        await axios
        .get(
          localhost +
            "/b2pm/accountdetailed/account?tenantId=" +
            tenantId +
            "&accountId=" +
            routeAccountId 
            ,{withCredentials:true}

        )
        .then((account) => {
          setPassData(account.data);
        });
      }
  
  
      
  
        else{
          // if(isRouted === true)
          // {
          //   routeAccountId = accountID
          // }
          props.callbackToSetGridVisibilty(false);
        }
    };

  useEffect(() => {
    console.log("getAccountDetail is being called");
    getAccountDetail();
  }, [props.accountIDClicked, showNewAccount]);
  const [open,setOpen]=useState(false);

  const getAccountDetailviaCallback = (previousNextAccountId) => {
    props.getAccountDetailviaCallback(previousNextAccountId);
    console.log(previousNextAccountId);
    // accountID = previousNextAccountId
    // getAccountDetail(previousNextAccountId)
  };

  const callbackToSetGridVisibilty = (state) => {
    console.log("gridVisibility");
    props.callbackToSetGridVisibilty(state);
  };
  {
    /* <SimpleBackdrop backdropState={backdropState} /> */
  }
  return (
    // <FormPropsTextFields />
    showNewAccount === false ? (
      passData === undefined || backdropOnChange === true ? (
        <div>
          <SimpleBackdrop backdropState={backdropState} />
        </div>
      ) : (
        <AccountProviderContext.Provider value={passData}>
          <AccountListDetailHeader
            history={history}
            rowsLength={props.rowsLength}
            disable={props.disable}
            setDisabled={props.setDisabled}
            accountID={accountID}
            setAcType={setAcType}
            AccountId={accountID}
            setAccountIDClicked={props.setAccountIDClicked}
            setShowNewAccount={setShowNewAccount}
            getAccountDetailviaCallback={getAccountDetailviaCallback}
            callbackToSetGridVisibilty={callbackToSetGridVisibilty}
            previousAccountId={props.prevAccountID}
            nextAccountId={props.nextAccountID}
            setNewDisabled={setNewDisabled}
            newdisable={newdisable}
            isRouted={isRouted}
            setIsRouted={setIsRouted}
            open={open}
            setOpen={setOpen}
          />
          {/* <AccountDetailHeader callbackToSetGridVisibilty={callback
                    
                    
                    ToSetGridVisibilty} previousAccountId={props.previousAccountId} nextAccountId={props.nextAccoutId}/> */}
          <div style={{ display: "flex",gap:"30px" }} className="account-details">
            {/* <Test /> */}
            {open===true?<AccountListDetailSidebar
              accountID={accountID}
              setShowNewAccount={setShowNewAccount}
              reload={props.reload}
              setReload={props.setReload}
              callbackToSetGridVisibilty={callbackToSetGridVisibilty}
              setEditDisabled={setEditDisabled}
              editdisable={editdisable}
              open={open}
            />:<React.Fragment></React.Fragment>}
            {/* <AccountDetailSideBar /> */}
            {/* <ContactDetailGrid /> */}
            <MyAwesomeTable
              accountID={accountID}
              passData={passData}
            />
            {/* <AccountDetailTab /> */}
            {/* <DynamicGreenTab /> */}
          </div>
          {/* <GreenTab /> */}
          {/* <InfoText /> */}
          {/* <UpperSideBarData /> */}
        </AccountProviderContext.Provider>
      )
    ) : 
    <AccountProviderContext.Provider value={passData}>
    <AccountListDetailHeader
      rowsLength={props.rowsLength}
      disable={props.disable}
      setDisabled={props.setDisabled}
      accountID={accountID}
      setAcType={setAcType}
      AccountId={accountID}
      setAccountIDClicked={props.setAccountIDClicked}
      setShowNewAccount={setShowNewAccount}
      getAccountDetailviaCallback={getAccountDetailviaCallback}
      callbackToSetGridVisibilty={callbackToSetGridVisibilty}
      previousAccountId={props.prevAccountID}
      nextAccountId={props.nextAccountID}
      setNewDisabled={setNewDisabled}
      newdisable={newdisable}
      setEditDisabled={setEditDisabled}
      editdisable={editdisable}
    />
     
     <FormPropsTextFields
       TenantID={window.TenantID}
       setAccountIDClicked={props.setAccountIDClicked}
       AccountId={accountID}
       AcType={AcType}
       setAcType={setAcType}
       setShowNewAccount={setShowNewAccount}
       setNewDisabled={setNewDisabled}
       setEditDisabled={setEditDisabled}

     />

     {/*  <FormPropsTextFields TenantID = "TTT-ID-00000"  AccountId= "03175df1-8cb2-4618-bf8e-79c4e279150b" AcType = "Existing"/> */}
  </AccountProviderContext.Provider>
  )

};

export { AccountDetail, AccountProviderContext };
