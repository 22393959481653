import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";

//export const API_BASE_URL = "http://test.bamboobox.in:8080";
//export const ACCOUNT_SUMMARY_REPORT = "http://test.bamboobox.in:3000"


// export const API_BASE_URL = "http://b2prospectapi.bamboobox.in";
// export const META_BASE_URL = "http://b2metadataapi.bamboobox.in";
// export const FILTER_URL = "http://b2filtersaapi.bamboobox.in/filter";

// export const ACC_CON_CREATE_LINK = "http://b2prospect.bamboobox.in";
// export const AUTH_URL = "http://loginapi.bamboobox.in/authorize/cookie";

// export const LOGIN_URL = "http://login.bamboobox.in";
// export const MARKETO_WEB_FORM_FILL = "http://b2prospectwebsignup.bamboobox.in"
// export const ACCOUNT_SUMMARY_REPORT = "http://b2analyticsaccountsummary.bamboobox.in"
// export const TENANT_CONFIG_API="http://b2tenantconfigapi.bamboobox.in/tenant/field/mapper/get/";


export const AUTH_URL=window._env_.AUTH_URL;
export const LOGIN_URL=window._env_.LOGIN_URL;
export const API_BASE_URL=window._env_.API_DOMAIN;
export const META_BASE_URL=window._env_.META_BASE_URL;
export const FILTER_URL=window._env_.FILTER_API_DOMAIN;
export const ACC_CON_CREATE_LINK=window._env_.ACC_CON_CREATE_LINK;
export const MARKETO_WEB_FORM_FILL=window._env_.MARKETO_WEB_FORM_FILL;
export const ACCOUNT_SUMMARY_REPORT=window._env_.ACCOUNT_SUMMARY_REPORT;
export const TENANT_CONFIG_API=window._env_.TENANT_CONFIG_API;

const EDIT_SVG = (
  <svg
    width="16"
    height="16"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.8735 6.98165L16.3209 5.53417L19.4668 8.68003L18.0193 10.1275L14.8735 6.98165ZM16.7571 5.26319C16.971 5.22212 17.2006 5.28463 17.3666 5.45071L19.5503 7.63434C19.7163 7.80042 19.7789 8.03001 19.7378 8.2439L16.7571 5.26319ZM6.25 15.6051L14.5199 7.3352L17.6658 10.4811L9.39586 18.751H6.25V15.6051Z"
      fill="#5D9CEC"
      stroke="#F2F2F2"
      stroke-width="0.5"
    />
  </svg>
);
const CANCEL_SVG = (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill="none" stroke="#dc1e1e" transform="translate(5 5)">
      <path d="m.5 10.5 10-10" />
      <path d="m10.5 10.5-10-10z" />
    </g>
  </svg>
);
const SAVE_SVG = (
  <svg
    height="20"
    viewBox="0 0 20 20"
    width="20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m.5 5.5 3 3 8.028-8"
      fill="none"
      stroke="#4caf50"
      transform="translate(5 6)"
    />
  </svg>
);
const TRASH_ICON = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-trash"
    viewBox="0 0 16 16"
  >
    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
    <path
      fillRule="evenodd"
      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4L4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
    />
  </svg>
);
const styles = {
  select: {
    marginBottom: "20px",
    marginTop: "20px",
    width: "95%",
    height: "50%",
    outline: "none",
    display: "flex",
    justifyContent: "flex-center",
    alignItems: "center",
    borderColor: "#0000",
    borderBottom: "1px solid #eee",
    borderRadius: "0%",
    background: "#fefefe",
    padding: "0%",
  },
  editButton: {
    background: "#f3f3f3",
    outline: "none",
    cursor: "pointer",
    padding: 4,
    display: "inline-flex",
    border: "none",
    borderRadius: "50%",
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
  buttonsCellEditorContainer: {
    height: "100%",
    width: "100%",
    display: "inline-flex",
    padding: "0 20px",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  cancelButton: {
    background: "#f3f3f3",
    outline: "none",
    cursor: "pointer",
    marginRight: 10,
    padding: 2,
    display: "inline-flex",
    border: "none",
    borderRadius: "50%",
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
  saveButton: {
    background: "#f3f3f3",
    outline: "none",
    cursor: "pointer",
    padding: 2,
    display: "inline-flex",
    border: "none",
    borderRadius: "50%",
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
  deleteButton: {
    background: "#f3f3f3",
    outline: "none",
    cursor: "pointer",
    marginLeft: 9,
    padding: 4,
    display: "inline-flex",
    border: "none",
    borderRadius: "50%",
    boxShadow: "1px 1px 2px 0px rgb(0 0 0 / .3)",
  },
};

export { EDIT_SVG, CANCEL_SVG, SAVE_SVG, TRASH_ICON, styles };