import React, { useState, useEffect } from "react";
import { css } from "glamor";
import { toast } from "react-toastify";
import { TextField, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import formData from "./data";
import axios from "axios";
import loadMetaData, {createHierarchy} from "../LoadMetaData";
import DropdownWithMeta from "../DropDownWithMeta";
import { Button, Typography } from "@material-ui/core/";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIosRounded";
import UITheme from "@bamboobox/b2-theme";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import SimpleBackdrop from "./SimpleBackdrop";
import {
  validateUrl,
  validateStringRegex,
  validateEmail,
  validateFloat,
  validateInteger,
} from "./validateInput";
//import TypeAheadService from './TypeAheadService'
//import FieldMatchingService from './FieldMatchingComponent';
import ValidationUIComponent from "./ValidationUIComponent";
import { API_BASE_URL } from "../constants";
import { IconButton } from "@material-ui/core";
import "./gridindex.css"
import Alert from '@mui/material/Alert';
import "./../toast.css"
const localhost = API_BASE_URL;

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "32ch",
      margin: "0 120px 32px 24px",
    },
  }
}));

const FormPropsTextFields = (props) => {
  const userData = useAuthState();
  // let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

  const [backdropState, setBackdropState] = useState(true);

  const classes = useStyles();
  const [data, setData] = useState(undefined);
  const [tenantId, setTenantId] = useState(userData.user.tenantId);
  const [accountId, setAccountId] = useState(null);
  const [metadata, setMetaData] = useState([]);
  const [stateMetaData, setStateMetataData] = useState([]);
  const [fsExecdata, setfsExecMetaData] = useState([]);
  const [isExecMetaData, setIsExecMetataData] = useState([]);
  const [allMetadata, setAllMetaData] = useState([]);
  const [tenantField, setTenantField] = useState({});
  var accountClassificationList = [];
  var industriesList = [];
  var subindustriesList = [];
  var countriesList = [];
  var crmAccountRegionsList = [];
  var statesListByCountry = [];
  var companyType = [];
  // var companyHierarchy = [];
  var capillaryGeography = [];
  var accountRegion = [];
  var crmBusinessFormatList = [];
  var isExecList = [];
  var fsExecList = [];
  var contactSource = [];
  let column = {"field": ""}

  toast.configure({
    autoClose: 6000,
    draggable: true,
    hideProgressBar: false,
    position: toast.POSITION.BOTTOM_LEFT,
    icon: false,
  });

  function onSave(e) {
    if((!data.companyName) || (!data.companyName.trim()) ||
    (!data.companyWebsite) || (!data.companyWebsite.trim()))
    {
      toast.error(<Alert severity="error">Name and URL are mandatory fields</Alert>,{
        closeOnClick: true,
        autoClose: true,
        closeButton: true,
        newestOnTop: true
      });
    }
 
  else{
       axios.post(
         localhost + "/b2pm/savedata/account",
         dataAdapterFlatToServer(data, tenantId, accountId),
         { withCredentials: true }
       )
       .then((response) => {
         if (response.status == 200 && response.data.rowSelected[0].msg ==="SUCCESS") {
           console.log("Saved");
           props.setAcType("Existing");
           props.setNewDisabled(false);
           props.setEditDisabled(false);
 
           props.setShowNewAccount(false);
         
         } else {
         
           let errorMsg = "Internal Server Error"
           
           if (response.data.rowSelected[0].msg){
             errorMsg = response.data.rowSelected[0].msg
           }
 
            toast.error(<Alert severity="error">Error: {errorMsg}</Alert>,{
              closeOnClick: true,
              autoClose: true,
              closeButton: true,
              newestOnTop: true
            });
         }
       });
     }
   }

  function dataAdapterFlatToServer(flatRow, tenantId, accountId) {
    var obj = {};
    let index = 0;
    obj["rowSelected"] = [{ account: {}, funding: {}, crm: {} }];
    obj["tenantId"] = tenantId;
    obj.rowSelected[0]["account"]["accountId"] = accountId;

    let formAccountFields = [
      "companyName",
      "companyWebsite",
      "companyPrimaryIndustryCategory",
      "companySubIndustryCategory",
      "companyType",
      // "companyHierarchy",
      "companyParent",
      "companyParentWebsite",
      "companyGroupName",
      "companyGroupWebsite",
      "companyPhone",
      "companyCity",
      "companyState",
      "companyCountry",
      "companyDescription",
      "companyTwitter",
      "companyLinkedin",
      "companyFacebook",
      "companyClassification",
      "companyAccountRegion",
      "companyBrandRevenueNumerical",
      "companyTechAttributes",
    ];
    for (index = 0; index < formAccountFields.length; index++) {
      obj.rowSelected[0]["account"][formAccountFields[index]] =
        flatRow[formAccountFields[index]];
    }

    let formFundingFields = [
      "companyFundingList",
      "companyTotalFundingAmount",
      "companyRecentFundingAmount",
      "companyRecentFundingDate",
    ];
    for (index = 0; index < formFundingFields.length; index++) {
      obj.rowSelected[0]["funding"][formFundingFields[index]] =
        flatRow[formFundingFields[index]];
    }

    let formCrmFields = [
      "crmBusinessFormat",
      "crmISExecutive",
      "crmFSExecutive",
      "crmFmExecutive",
      "crmAccountRegion",
      "crmNumberOfStores",
      "crmAccountGeography",
    ];
    for (index = 0; index < formCrmFields.length; index++) {
      obj.rowSelected[0]["crm"][formCrmFields[index]] =
        flatRow[formCrmFields[index]];
    }
    return obj;
  }
  function onCancel(e) {
    props.setAcType("Existing");
    props.setNewDisabled(false);
    props.setEditDisabled(false);
    props.setShowNewAccount(false);
  }

  if (metadata != undefined && metadata.length > 0) {
    console.log("within metadata", metadata );
    accountClassificationList = metadata.find(
      (obj) => obj.name == "accountClassification"
    );
    if (accountClassificationList) {
      accountClassificationList = accountClassificationList.values;
    } else {
      accountClassificationList = [];
    }

    contactSource = metadata.find(obj => obj.name == "contactSource");
    if (contactSource) {
      contactSource = contactSource.values;
    }

    industriesList = metadata.find((obj) => obj.name == "industry");
    if (industriesList) {
      industriesList = industriesList.values;
    } else {
      industriesList = [];
    }

    crmBusinessFormatList = metadata.find(
      (obj) => obj.name == "businessFormat"
    );
    if (crmBusinessFormatList) {
      crmBusinessFormatList = crmBusinessFormatList.values;
    } else {
      crmBusinessFormatList = [];
    }

    countriesList = metadata.find((obj) => obj.name == "country");
    if (countriesList) {
      countriesList = countriesList.values;
    } else {
      countriesList = [];
    }
    // statesListByCountry = stateMetaData;
    subindustriesList = metadata.filter((obj) => obj.name == "subIndustry");
    if (subindustriesList) {
      if (subindustriesList.length === 1) {
        subindustriesList = subindustriesList[0].values;
      } else if (subindustriesList.length === 2) {
        subindustriesList = subindustriesList[1].values;
      } else {
        subindustriesList = [];
      }
    }
     crmAccountRegionsList = metadata.filter(
       (obj) => obj.name == "accountRegion"
     );
     if (crmAccountRegionsList) {
       if (crmAccountRegionsList.length === 1) {
         crmAccountRegionsList = crmAccountRegionsList[0].values;
      } else if (crmAccountRegionsList.length === 2) {
        crmAccountRegionsList = crmAccountRegionsList[1].values;
       } else {
         crmAccountRegionsList = [];
       }
     }
    companyType = metadata.find((obj) => obj.name == "companyType");
    if (companyType) {
      companyType = companyType.values;
    } else {
      companyType = [];
    }
    // companyHierarchy = metadata.find((obj) => obj.name == "companyHierarchy");
    // if (companyHierarchy) {
    //   companyHierarchy = companyHierarchy.values;
    // } else {
    //   companyHierarchy = [];
    // }
    capillaryGeography = metadata.find((obj) => obj.name == "geography");
    if (capillaryGeography) {
      capillaryGeography = capillaryGeography.values;
    } else {
      capillaryGeography = [];
    }
    if (fsExecdata) {
      fsExecList = [];
      console.log("fsExecdata", fsExecdata);
      for (let i = 0; i < fsExecdata.length; i++) {
        fsExecList.push(fsExecdata[i]);
        fsExecList[i].value = fsExecdata[i].name;
      }
    } else {
      fsExecList = [];
    }

    if (isExecMetaData) {
      isExecList = [];
      console.log("isExecMetaData", isExecMetaData);
      for (let i = 0; i < isExecMetaData.length; i++) {
        isExecList.push(isExecMetaData[i]);
        isExecList[i].value = isExecMetaData[i].name;
      }
    } else {
      isExecList = [];
    }
  }

  function updateData(incomingData) {
    setTenantId(tenantId);
    if (
      props.AcType == "Existing" &&
      window.name != "newaccount" &&
      window.name != "SearchBarInput"
    ) {
      setData({
        ...incomingData.rowSelected[0].account,
        ...incomingData.rowSelected[0].funding,
        ...incomingData.rowSelected[0].crm,
      });
      setAccountId(props.AccountId);
    } else if (props.AcType == "NewAccount" || window.name == "newaccount") {
      window.name = "";
      setData({
        ...formData.rowSelected[0].account,
        ...formData.rowSelected[0].funding,
        ...formData.rowSelected[0].crm,
      });
      setAccountId(null);
    } else {
      setAccountId(props.AccountId);
      fetch(
        localhost +
          "/b2pm/accountdetailed/account?tenantId=" +
          tenantId +
          "&accountId=" +
          accountId,
        { credentials: "include" }
      )
        .then((response) => response.json())
        .then((data) => {
          setData({
            ...data.rowSelected[0].account,
            ...data.rowSelected[0].funding,
            ...data.rowSelected[0].crm,
          });
        });
    }
  }

const [isEnable,setIsEnable] = useState(true)

  function onChangeField(e, option) {

    setIsEnable(false);
    // if(e.target.value.length > 0) {
    //      setIsEnable(false);
    // }
    // else{
    //   setIsEnable(true);
    // }
    console.log("onChangeField", e.target)
    let formField = e.target.id;
    if (formField.indexOf("-option") > 0) {
      formField = formField.substring(0, formField.indexOf("-option"));
      setData({ ...data, ...{ [formField]: option.value } });
    } else if (formField) {
      setData({ ...data, ...{ [formField]: e.target.value } });
    }
  }

  useEffect(() => {
    createHierarchy(tenantId, setAllMetaData, setTenantField);
    props.AcType != "Existing"
      ? updateData(null)
      : fetch(
          localhost +
            "/b2pm/accountdetailed/account?tenantId=" +
            tenantId +
            "&accountId=" +
            props.AccountId,
          { credentials: "include" }
        )
          .then((response) => response.json())
          .then((data) => {
            updateData(data);
          });
  }, []);

  return data === undefined ? (
    <div>
      <SimpleBackdrop backdropState={backdropState} />
    </div>
  ) : (
    <div style={{ 
    padding: "16px",
    background: '#FFFFFF',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.1)',
    borderRadius: '18px 18px 0px 0px',
    margin:'20px'
    }}>
      <div
        class="flex-container"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "16px",
          position: "sticky",
          top: 0,
          left: 0,
        }}
      >
        <Typography variant="h6" style={{ marginLeft:'7px',fontWeight: 600 }}>
          <IconButton
            onClick={onCancel}
            style={{ height: "24px", width: "24px" }}
          >
            <ArrowBackIosIcon  style={{color:'#37CAD3'}}/>
          </IconButton>
          Account Details
        </Typography>
        <div className="button-section" style={{ marginBottom: 0 }}>
          {/* <Button
            onClick={onSave}
            variant="contained"
            color="primary"
            size="small"
            className={classes.button}
            startIcon={<SaveIcon />}
            width="80px"
            style={{ paddingTop: "3px" }}
            disabled={isEnable}
          >
            Save
          </Button> */}
          <Button
            onClick={onCancel}
            variant="outlined"
            color="primary"
            size="small"
            className={classes.button}
            // startIcon={<CancelIcon />}
            // width="80px"
            style={{ paddingTop: "3px",border:'none' }}
          >
            <CancelIcon style={{color: '#A0A0A0'}}/>
            {/* Cancel */}
          </Button>
        </div>
      </div>
      <form className={classes.root} noValidate autoComplete="off" >
        <div style={{ marginLeft: "16px",}}>
          <Typography
            style={{ fontWeight: 600, fontSize:"14px", marginBottom: "14px" }}
          >
            Organisation Information
          </Typography>
          <div className="input-section" style={{ overflowY:"auto", overflowX:"hidden", maxHeight:"60vh"}}>
            <div className="input-row">
              <ValidationUIComponent
                value={data.companyName}
                variant="outlined"
                id="companyName"
                label="Name*"
                onChanged={onChangeField}
                validator={validateStringRegex}
                message=" should not contain special characters"
              />
              <ValidationUIComponent
                value={data.companyWebsite}
                variant="outlined"
                id="companyWebsite"
                label="URL*"
                disabled={props.AcType==='Existing'}
                onChanged={onChangeField}
                validator={validateUrl}
                message=" is not a valid Website"
              />
            </div>
            <div className="input-row">
              <DropdownWithMeta
                id="companyPrimaryIndustryCategory"
                label="Industry"
                data={data}
                onChange={onChangeField}
                valueArray={industriesList}
                value={data.companyPrimaryIndustryCategory}
                allMetaData={allMetadata}
                source="ACCOUNT"
              />
              <DropdownWithMeta
                id="companySubIndustryCategory"
                label="Sub-Industry"
                data={data}
                onChange={onChangeField}
                valueArray={subindustriesList}
                value={data.companySubIndustryCategory}
                parent="companyPrimaryIndustryCategory"
                allMetaData={allMetadata}
                source="ACCOUNT"
              />
              <DropdownWithMeta
                id="companyType"
                label="Company Type"
                data={data}
                onChange={onChangeField}
                valueArray={companyType}
                value={data.companyType}
                allMetaData={allMetadata}
                source="ACCOUNT"
              />
            </div>
            <div className="input-row">
              <DropdownWithMeta
                id="companyHierarchy"
                label="Company Hierarchy"
                data={data}
                onChange={onChangeField}
                
                value={data.companyHierarchy}
                allMetaData={allMetadata}
                source="ACCOUNT"
              />
              <ValidationUIComponent
                value={data.companyParent}
                variant="outlined"
                id="companyParent"
                label="Parent Name"
                onChanged={onChangeField}
                validator={validateStringRegex}
                message=" should not contain special characters"
              />

              <ValidationUIComponent
                value={data.companyParentWebsite}
                variant="outlined"
                id="companyParentWebsite"
                label="Parent URL"
                onChanged={onChangeField}
                validator={validateUrl}
                message=" is not a valid Website"
              />
            </div>
            <div className="input-row">
              <ValidationUIComponent
                value={data.companyGroupName}
                variant="outlined"
                id="companyGroupName"
                label="Group Name"
                onChanged={onChangeField}
                validator={validateStringRegex}
                message=" should not contain special characters"
              />

              <ValidationUIComponent
                value={data.companyGroupWebsite}
                variant="outlined"
                id="companyGroupWebsite"
                label="Group URL"
                onChanged={onChangeField}
                validator={validateUrl}
                message=" is not a valid Website"
              />
            </div>
          </div>
          {/* <Divider /> */}
          <Typography
            style={{
              paddingTop: "24px",
              fontWeight: 600,
              marginBottom: "14px",
              fontSize:"14px", color:"#595959",
            }}
          >
            Contact Information
          </Typography>
          <div className="input-section">
            <div className="input-row">
              <ValidationUIComponent
                value={data.companyPhone}
                variant="outlined"
                id="companyPhone"
                label="Phone"
                onChanged={onChangeField}
                validator={validateStringRegex}
                message=" should not contain special characters"
              />
            </div>
            <div className="input-row">
              <DropdownWithMeta
                id="companyCountry"
                label="Mailing Country"
                data={data}
                onChange={onChangeField}
                valueArray={countriesList}
                value={data.companyCountry}
                message=" should not contain special characters"
                allMetaData={allMetadata}
                source="ACCOUNT"
              />
              <ValidationUIComponent
                value={data.companyState}
                variant="outlined"
                id="companyState"
                label="Mailing State"
                onChanged={onChangeField}
                validator={validateStringRegex}
                message=" should not contain special characters"
              />
              <ValidationUIComponent
                value={data.companyCity}
                variant="outlined"
                id="companyCity"
                label="Mailing City"
                onChanged={onChangeField}
                validator={validateStringRegex}
                message=" should not contain special characters"
              />
            </div>
          </div>
          {/* <Divider /> */}
          <Typography
            style={{
              paddingTop: "24px",
              fontWeight: 600,
              marginBottom: "14px",
              fontSize:"14px", color:"#595959",
            }}
          >
            Additional Information
          </Typography>
          <div className="input-section">
            <div className="input-row">
              <ValidationUIComponent
                value={data.companyDescription}
                variant="outlined"
                id="companyDescription"
                label="Company Description"
                onChanged={onChangeField}
                validator={validateStringRegex}
                message=" should not contain special characters"
              />
            </div>
            <div className="input-row">
              <ValidationUIComponent
                value={data.companyTwitter}
                variant="outlined"
                id="companyTwitter"
                label="Twitter URL"
                onChanged={onChangeField}
                validator={validateUrl}
                message=" is not a valid Website"
              />

              <ValidationUIComponent
                value={data.companyLinkedin}
                variant="outlined"
                id="companyLinkedin"
                label="LinkedIn URL"
                onChanged={onChangeField}
                validator={validateUrl}
                message=" is not a valid Website"
              />

              <ValidationUIComponent
                value={data.companyFacebook}
                variant="outlined"
                id="companyFacebook"
                label="Facebook URL"
                onChanged={onChangeField}
                validator={validateUrl}
                message=" is not a valid Website"
              />
            </div>
            <div className="input-row">
              <DropdownWithMeta
                id="crmFSExecutive"
                label="Field Sales"
                data={data}
                onChange={onChangeField}
                valueArray={fsExecList}
                value={data.crmFSExecutive}
                allMetaData={allMetadata}
                source="ACCOUNT"
                
              />
              <DropdownWithMeta
                id="crmISExecutive"
                label="Inside Sales"
                data={data}
                onChange={onChangeField}
                valueArray={isExecList}
                value={data.crmISExecutive}
                allMetaData={allMetadata}
                source="ACCOUNT"
              />
            </div>

            <div className="input-row">
              <ValidationUIComponent
                value={data.crmNumberOfStores}
                variant="outlined"
                id="crmNumberOfStores"
                label="No of Stores"
                onChanged={onChangeField}
                validator={validateInteger}
                message=" should be a number"
              />
              <DropdownWithMeta
                id="crmAccountGeography"
                label="Capillary Geography"
                data={data}
                onChange={onChangeField}
                valueArray={capillaryGeography}
                value={data.crmAccountGeography}
                allMetaData={allMetadata}
                source="ACCOUNT"
              />
              
            </div>
            
            <div className="input-row">
              <DropdownWithMeta
                id="companyClassification"
                label="Account Classification"
                data={data}
                onChange={onChangeField}
                valueArray={accountClassificationList}
                value={data.companyClassification}
                allMetaData={allMetadata}
                source="ACCOUNT"
              />
              { <DropdownWithMeta
                id="companyAccountRegion"
                label="Account Region"
                data={data}
                onChange={onChangeField}
                
                value={data.companyAccountRegion}
                parent="crmAccountGeography"
                allMetaData={allMetadata}
                source="ACCOUNT"
              /> }
              <DropdownWithMeta
                id="crmBusinessFormat"
                label="Business Format"
                data={data}
                onChange={onChangeField}
                valueArray={crmBusinessFormatList}
                value={data.crmBusinessFormat}
                allMetaData={allMetadata}
                source="ACCOUNT"
              />
            </div>

       

            <div className="input-row">
              <ValidationUIComponent
                value={data.companyBrandRevenueNumerical}
                variant="outlined"
                id="companyBrandRevenueNumerical"
                label="Annual Revenue"
                onChanged={onChangeField}
                validator={validateFloat}
                message=" should be a decimal value"
                adornment="$"
                placeholder="1000000.00"
              />
            </div>
            <div className="input-row">
              <ValidationUIComponent
                value={data.companyTechAttributes}
                variant="outlined"
                id="companyTechAttributes"
                label="Tech Attributes"
                onChanged={onChangeField}
                validator={validateStringRegex}
                message=" should not contain special characters"
              />
            </div>
          </div>
        </div>
      </form>
      <div style={{position:'sticky',bottom:'10px',float:'right',padding:'inherit'}}>
      <Button
            onClick={onSave}
            variant="contained"
            // color="#21DA8C"
            size="small"
            className={classes.button}
            // startIcon={<SaveIcon />}
            style={isEnable?{ borderRadius:'16px',
                    background:'rgb(160, 160, 160)',
                    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',width:"74px",
                    height:'32px' }:
                    { borderRadius:'16px',
                    background:'#21DA8C',
                    boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)',width:"74px",
                    height:'32px' }
                  }
            // style={isEnable?{background:'#21DA8C'}:{background:'rgb(160, 160, 160)'}}
            disabled={isEnable}
          >
            <Typography
            style={{ 
              fontStyle: 'normal',
              fontWeight: '800',
              fontSize: '13px',
              lineHeight: '22px',
              alignItems: 'center',
              letterSpacing: '0.46px',
              textTransform: 'uppercase',
              color: '#FFFFFF'

            }}           
             
            >
            UPDATE
            </Typography>
          </Button>
        </div>
    </div>
  );
};

export default FormPropsTextFields;