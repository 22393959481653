import uuid from 'uuid-random';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import {validateUrl, validateEmail, validateFloat, validateInteger} from './validateInput';
import Alert from '@mui/material/Alert';
import "./../toast.css"

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});

export function saveAccountfromRow(row) {
         // var account = [..row];
         var account =
         {
             
                "accountId": row.accountId,
                "companyName": row.companyName,
                "companyAccountRegion": row.companyAccountRegion,
                "companyGroupName": row.companyGroupName,
                "companyWebsite": row.companyWebsite,
                "companyParentWebsite": row.companyParentWebsite,
                "companyGroupWebsite": row.companyGroupWebsite,
                "companyPrimaryIndustryCategory": row.companyPrimaryIndustryCategory,
                "companySubIndustryCategory": row.companySubIndustryCategory,
                "companyBrandRevenueNumerical": row.companyBrandRevenueNumerical,
                "companyEmployeeCount": row.companyEmployeeCount,
                "companyFax": row.companyFax,
                "companyPhone": row.companyPhone,
                "companyStreet": row.companyStreet,
                "companyCity": row.companyCity,
                "companyState": row.companyState,
                "companyCountry": row.companyCountry,
                "companyContinent": row.companyContinent,
                "companyZipCode": row.companyZipCode,
                "companyDescription": row.companyDescription,
                "companyLinkedin": row.companyLinkedin,
                "companyFacebook": row.companyFacebook,
                "companyTwitter": row.companyTwitter,
                "companyParent": row.companyParent,
                // "companyHierarchy": row.companyHierarchy,
                "companyLocationCount": row.companyLocationCount,
                "companyProducts": row.companyProducts,
                "companyType": row.companyType,
                "companyClassification": row.companyClassification,
                "companyTechAttributes": row.companyTechAttributes,
                "companyHashTag": row.companyHashTag,
                // "companyAccountRegion": row.companyAccountRegion
         }
         return account;
     }
    
    
     
    
 export   function saveFundingfromRow(row) {
         //var funding = [..row];
         var funding = {
             "companyRecentFundingAmount": row.companyRecentFundingAmount,
             "companyRecentFundingDate": row.companyRecentFundingDate,
             "companyTotalFundingAmount": row.companyTotalFundingAmount,
             "companyFundingList": row.companyFundingList
         }
    
         return funding;
    
     }
    
export    function saveCRMfromRow(row)
    {   

        //var crm = [...row];
        var crm = {

                // "crmAccountRegion": row.crmAccountRegion,
                "crmFmExecutive": row.crmFmExecutive,
                "crmBusinessFormat": row.crmBusinessFormat,
                "crmISExecutive": row.crmISExecutive,
                "crmFSExecutive": row.crmFSExecutive,
                "crmNumberOfStores": row.crmNumberOfStores,
                "crmAccountGeography": row.crmAccountGeography,
                "crmContactGeography":row.crmContactGeography,
                "crmPersonaCode": row.crmPersonaCode
    }
    
         return crm;
    
    }


    export function saveContactfromRow(row) {
    
    var contact = {
    "contactId": row.contactId,
    "contactSource": row.contactSource,
    "companyName": row.companyName,
    "companyGroupName": row.companyGroupName,
    "companyWebsite": row.companyWebsite,
    "contactRole": row.contactRole,
    "contactStreet": row.contactStreet,
    "contactCity": row.contactCity,
    "contactState": row.contactState,
    "contactCountry": row.contactCountry,
    "contactZipCode": row.contactZipCode,
    "contactFirstName": row.contactFirstName,
    "contactLastName": row.contactLastName,
    "contactTitle": row.contactTitle,
    "contactDepartment": row.contactDepartment,
    "contactSubDepartment": row.contactSubDepartment,
    "contactPositionLevel": row.contactPositionLevel,
    "contactLinkedin": row.contactLinkedin,
    "contactFacebook": row.contactFacebook,
    "contactTwitter": row.contactTwitter,
    "contactMobile": row.contactMobile,
    "contactPhone": row.contactPhone,
    "contactSecondaryNumber": row.contactSecondaryNumber,
    "contactEmail": row.contactEmail,
    "contactPersonalEmail": row.contactPersonalEmail,
    "contactSecondaryEmail": row.contactSecondaryEmail,
    "contactEducationHistory": row.contactEducationHistory,
    "contactEmploymentHistory": row.contactEmploymentHistory,
    "contactIsDefunct": row.contactIsDefunct,
    "contactMobilePhoneDoNotCall": row.contactMobilePhoneDoNotCall,
    "contactDirectPhoneDoNotCall": row.contactDirectPhoneDoNotCall,
    "contactEmailUnsubscribe": row.contactEmailUnsubscribe,
    "contactRegion":row.contactRegion
    }
    
    return contact;
    
    }
    
 
export    function addNewOnDelete(rowsClone){   

   
      var newRow= {
        "id":uuid(), 
        "row_id": rowsClone.length+2,
        "msg": "",
        "accountSource": "Manual",
        "accountId": "",
        "companyName": "",
        "companyGroupName": "",
        "companyWebsite": "",
        "companyParentWebsite": "",
        "companyGroupWebsite": "",
        "companyPrimaryIndustryCategory": "",
        "companySubIndustryCategory": "",
        "companyBrandRevenueNumerical": "",
        "companyEmployeeCount": "",
        "companyFax": "",
        "companyPhone": "",
        "companyStreet": "",
        "companyCity": "",
        "companyState": "",
        "companyCountry": "",
        "companyContinent": "",
        "companyZipCode": "",
        "companyDescription": "",
        "companyLinkedin": "",
        "companyFacebook": "",
        "companyTwitter": "",
        "companyParent": "",
        // "companyHierarchy": "",
        "companyLocationCount": "",
        "companyProducts": "",
        "companyType": "",
        "companyClassification": "",
        "companyTechAttributes": "",
        "companyHashTag": "",
        "companyRecentFundingAmount": "",
        "companyRecentFundingDate": "",
        "companyTotalFundingAmount": "",
        "companyFundingList": "",
        // "crmAccountRegion": "",
        "crmFmExecutive": "",
        "crmNumberOfStores": "",
        "crmBusinessFormat": "",
        "crmISExecutive": "",
        "crmFSExecutive": "",
        "crmAccountGeography":""
        
      };
  
     return newRow;
    }

// export function getAllRowsDatawithId(inp, counter) {
//     var row = {
//       id: counter,
//       row_id: counter,
//       ...inp.account,
//       ...inp.funding,
//       ...inp.crm,
//     };
  
//     return row;
//   }

  export function getAllRowsDatawithId(inp, counter) {
    var row = {
      id: counter,
      row_id: counter,
      ...inp.contact,
      ...inp.account,
      ...inp.funding,
      ...inp.crm,
    };
  
    return row;
  }

  export  function getAllRowsData(inp, counter) {
    var row = {
      id: uuid(),
      row_id: counter,
      ...inp.account,
      ...inp.contact,
      ...inp.funding,
      ...inp.crm,
    };
  
    return row;
  }

 export function emptyRow(idOfRow) {
   
    var obj = {
    "id": idOfRow, 
    
    "row_id": idOfRow,
    "contactId": "",
    "contactFirstName": "",
    "contactLastName": "",
    "contactTitle": "",
    "contactDepartment": "",
    "contactSubDepartment": "",
    "contactPositionLevel": "",
    "contactLinkedin": "",
    "contactFacebook": "",
    "contactTwitter": "",
    "contactMobile": "",
    "contactPhone": "",
    "contactSecondaryNumber": "",
    "contactEmail": "",
    "contactPersonalEmail": "",
    "contactSecondaryEmail": "",
    "contactEducationHistory": "",
    "contactEmploymentHistory": "",
    "contactIsDefunct": "",
    "contactMobilePhoneDoNotCall": "",
    "contactDirectPhoneDoNotCall": "",
    "contactEmailUnsubscribe": "",
    "contactRegion":"",
    
    "accountId": "",
    "companyName": "",
    "companyGroupName": "",
    "companyFranchiseeParent": "",
    "companyWebsite": "",
    "companyParentWebsite": "",
    "companyGroupWebsite": "",
    "companyPrimaryIndustryCategory": "",
    "companySubIndustryCategory": "",
    "companyBrandRevenueNumerical":"",
    "companyParentRevenue": "",
    "companyGroupRevenue": "",
    "companyEmployeeCount": "",
    "companyParentEmployees": "",
    "companyGroupEmployees": "",
    "companyFax": "",
    "companyPhone": "",
    "companyStreet": "",
    "companyCity": "",
    "companyState": "",
    "companyCountry": "",
    "companyContinent": "",
    "companyZipCode": "",
    "companyDescription": "",
    "companyLinkedin": "",
    "companyFacebook": "",
    "companyTwitter": "",
    "companyParent": "",
    "companyLocationCount": "",
    "companyProducts": "",
    "companyEmployeeGrowth": "",
    "companyType": "",
    "companyClassification": "",
    "companyTechAttributes": "",
    "companyHashTag": "",
    // "companyHierarchy":"",
    "companyRecentFundingAmount": "",
    "companyRecentFundingDate": "",
    "companyTotalFundingAmount": "",
    "companyFundingList": "",
    "crmNumberOfStores": "",
    "crmBusinessFormat": "",
    "crmISExecutive": "",
    "crmFSExecutive": "",
    // "crmAccountRegion": "",
    "crmFmExecutive": "",
    "crmPersonaCode":"",
    "crmAccountGeography":"",
    "accountSource":"Manual",
    "contactSource":"",
    "enriched":0
    
   
    };
    return obj; 
  }


export function check_mandatory_validate_accountFields(row){
  let mandatoryFieldsError = "";
  let validationFieldError = "";
  let validationFieldErrorList = []
  if (!(row.companyWebsite) 
  || !(row.companyWebsite).trim())
  {
    mandatoryFieldsError+="Company Website, "
  }

  if ( !(row.companyName)
  || !(row.companyName).trim())
  {
    mandatoryFieldsError+="Company Name, "
  }
  if (mandatoryFieldsError){
    mandatoryFieldsError+="are mandatory and are missing"
  }

  if (!validateUrl(row.companyWebsite) ){
    validationFieldError += "Company Website, "
  }

  if (!validateUrl(row.companyParentWebsite) ){
    validationFieldError += "Company Parent Website, "
  }

  if (!validateUrl(row.companyGroupWebsite) ){
    validationFieldError += "Company Group Website, "
  }

  if (!validateUrl(row.companyLinkedin) ){
    validationFieldError += "Company Linkedin, "
  }

  if (!validateUrl(row.companyFacebook) ){
    validationFieldError += "Company Facebook, "
  }

  if (!validateUrl(row.companyTwitter) ){
    validationFieldError += "Company Twitter, "
  }

  if (validationFieldError) {
    validationFieldError+="Fields do not have website"
    validationFieldErrorList.push(validationFieldError)
    validationFieldError="";
  }

  if (!validateInteger(row.crmNumberOfStores) ){
    validationFieldError += "Number of stores, "
  }

  if (validationFieldError) {
    validationFieldError+="Fields should contain number only"
    validationFieldErrorList.push(validationFieldError)
    validationFieldError="";
  }

  if (!validateFloat(row.companyBrandRevenueNumerical) ){
    validationFieldError += "Company Brand Revenue, "
  }

  if (validationFieldError) {
    validationFieldError+="Fields should contain decimal only"
    validationFieldErrorList.push(validationFieldError)
    validationFieldError="";
  }


  return [mandatoryFieldsError, validationFieldErrorList];
  }

  

  export function check_mandatory_validate_contactFields(row){
    let mandatoryFieldsError = "";
    let validationFieldError = "";
    let validationFieldErrorList = []
    if (!(row.companyWebsite) || !(row.companyWebsite).trim())
    {
      mandatoryFieldsError+="Company Website, "
    }
  
    if ( !(row.companyName) || !(row.companyName).trim())
    {
      mandatoryFieldsError+="Company Name, "
    }
    if (!(row.contactLastName) || !(row.contactLastName).trim())
    {
      mandatoryFieldsError+="Last Name, "
    }
  
    if ( !(row.contactFirstName) || !(row.contactFirstName).trim())
    {
      mandatoryFieldsError+="First Name, "
    }
    if (!(row.contactEmail) || !(row.contactEmail).trim())
    {
      mandatoryFieldsError+="Contact Email, "
    }
    if (!(row.contactSource) || !(row.contactSource).trim())
    {
      mandatoryFieldsError+="Contact Source, "
    }
    if (mandatoryFieldsError){
      mandatoryFieldsError+="are mandatory and are missing"
    }
  
    if (!validateUrl(row.companyWebsite) ){
      validationFieldError += "Company Website, "
    }
  
    if (!validateEmail(row.contactEmail) ){
      validationFieldError += "Official Email, "
    }
  
    if (!validateEmail(row.contactSecondaryEmail) ){
      validationFieldError += "Other Email, "
    }
  
    if (!validateUrl(row.contactLinkedin) ){
      validationFieldError += "Contact Linkedin Url, "
    }
  
    if (validationFieldError) {
      validationFieldError+="Fields do not have website"
      validationFieldErrorList.push(validationFieldError)
      validationFieldError="";
    }
  
    // if (!validateInteger(row.crmNumberOfStores) ){
    //   validationFieldError += "Number of stores, "
    // }
  
    // if (validationFieldError) {
    //   validationFieldError+="Fields should contain number only"
    //   validationFieldErrorList.push(validationFieldError)
    //   validationFieldError="";
    // }
  
    // if (!validateInteger(row.companyBrandRevenueNumerical) ){
    //   validationFieldError += "Company Brand Revenue, "
    // }
  
    // if (validationFieldError) {
    //   validationFieldError+="Fields should contain decimal only"
    //   validationFieldErrorList.push(validationFieldError)
    //   validationFieldError="";
    // }
  
  
    return [mandatoryFieldsError, validationFieldErrorList];
    }


    export function trimOrSendEmpty(input){
      
      if (!input) {
        console.log("trimOrSendEmpty empty" )
        return "";
      }
      console.log("trimOrSendEmpty", input, input.trim().toLowerCase())
      return input.trim().toLowerCase();
    }
