import React from 'react';
import 'date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';


function SingleDatePickerComponent(props) {

    
    const [selectedDate, setSelectedDate] = React.useState(props.attributeData.values[0]);


    const handleDateChange = (value) => {
        console.log('value in handle function is',value);
        setSelectedDate(value);
        var tempDate = value;
        // tempDate.setUTCDate(tempDate.getDate())
        // tempDate.setUTCHours(0, 0, 0, 0)
        props.onAttributeChange([tempDate],"values")
        console.log("handleDateChange ", value, new Date(value));
    };


    React.useEffect(() => {
        setSelectedDate(props.attributeData.values[0]||null)
    }, [props]);
    
    return (
        <React.Fragment>
            
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                 <KeyboardDatePicker
                    disableToolbar
                    disabled={props?.disabled||false}
                    maxDate={new Date()}
                    variant="inline"
                    inputVariant="outlined"
                    format="dd/MM/yyyy"
                    placeholder='DD/MM/YYYY'
                    InputProps={{
                        style: {
                            fontSize: 16,
                            height: 38,
                            color:"rgba(3, 3, 3, 0.6)",
                        }
                    }}
                    style={{ width: '18vw'}}
                    value={selectedDate||null}
                    autoOk={true}
                    onChange={handleDateChange}
                    KeyboardButtonProps={{
                        'aria-label': 'change date',
                    }}
                />
               
            </MuiPickersUtilsProvider>
        </React.Fragment>


    );
}

export default SingleDatePickerComponent;