import React from 'react';
import { useState, useEffect } from 'react';
import KeyboardArrowLeftRoundedIcon from '@mui/icons-material/KeyboardArrowLeftRounded';
import ChevronRightRoundedIcon from '@mui/icons-material/ChevronRightRounded';
import './Pagination.css';

const Pagination = ({
    tableManager,
    page = tableManager.paginationApi.page, 
    onChange = tableManager.paginationApi.setPage
}) => {
    const {
        config: {
            texts: {
                prev: prevText,
                page: pageText,
                next: nextText,
                of: ofText
            },
            additionalProps: { pagination: additionalProps = {} },
        },
        paginationApi: { totalPages, pageSize },
    } = tableManager;

    const [arr, setArr] = useState([]);

    useEffect(() => {

        console.log('coming inside useEffect line 29');
        if (totalPages < 6) {
            let newArr = []
            for (let r = 1; r <= totalPages; r++) {
                newArr.push(r);
            }
            setArr((prevArr) => newArr);
        }
        else {
            console.log('useEffect running');
            setArr((prevArr) => [1, 2, 3, 4, 5, 6]);
        }
    }, [totalPages]);

    // console.log(totalPages, sizeOfArray, "sizeOfArray");

    const [selectedPage, setSelectedPage] = useState(1);

    const handleClick = (event) => {
        if (event.target.value == arr[arr.length - 1] && arr[arr.length - 1] < totalPages) {
            let newArr;
            if(arr[arr.length - 1] + 1 == totalPages){
                newArr = arr.map((item)=> {
                    return (item + 1);
                })
            }
            else{
            newArr = arr.map((item) => {
                return (item + 2);
            })
        }
            setArr((prevArr) => newArr);
        }

        if (event.target.value == arr[0] && event.target.value != 1) {
            let newArr = arr.map((item) => {
                return (item - 2);
            })
            setArr((prevArr) => newArr);
        }
        console.log("event.target.value")
        setSelectedPage((prevState) => event.target.value);
        onChange(event.target.value * 1);
    }

    const handlerNextPage = (event) => {
        console.log(event.target.value, arr[arr.length - 1], "from handleNextPage")
        if (selectedPage == arr[arr.length - 1] && arr[arr.length - 1] != totalPages) {
            let newArr = arr.map((item) => {
                return (item + 1);
            })
            setArr((prevArr) => newArr);
        }
        setSelectedPage(page + 1);
        onChange(page + 1);
    }

    const handlerPrevPage = (event) => {
        if (selectedPage == arr[0] && event.target.value != 1) {
            let newArr = arr.map((item) => {
                return (item - 1);
            })
            setArr((prevArr) => newArr);
        }
        setSelectedPage(page - 1);
        onChange(page - 1);
    }

    let backButtonDisabled = page-1 < 1;
    let nextButtonDisabled = page+1 > totalPages;

    let classNames = ('rgt-footer-pagination ' + (additionalProps.className || '')).trim();

    return (
        <div {...additionalProps} className={classNames}>
            <button 
                className={`pagination-button rgt-footer-pagination-button${backButtonDisabled ? ' rgt-disabled-button' : ''}`}
                disabled={page-1 < 1} 
                onClick={handlerPrevPage}
                style={{background: 'white !important'}}
            ><KeyboardArrowLeftRoundedIcon style={{ color: '#37CAD3'}} /></button>

            <div className='rgt-footer-pagination-input-container'>
                {/* <span>{pageText} </span> */}
                {
                    arr.map((item) => {
                        return <button
                            key={item}
                            style={item == selectedPage ? { textDecoration: 'underline', textDecorationThickness: '2px', textUnderlineOffset:"2px", fontWeight: `600`} : {paddingBottom: '5px'}}
                            className='pagination-button rgt-footer-page-input'
                            type='number'
                            value={item}
                            onClick={handleClick}
                        >
                            {item}
                        </button>
                    })
                }
                {/* <input 
                    onClick={event => event.target.select()}
                    className='rgt-footer-page-input'
                    type='number' 
                    value={totalPages ? page : 0} 
                    onChange={event => onChange(event.target.value*1)}
                />
                <span>{ofText} {totalPages}</span> */}
            </div>

            <button 
                className={`pagination-button rgt-footer-pagination-button${nextButtonDisabled ? ' rgt-disabled-button' : ''}`}
                disabled={page+1 > totalPages} 
                onClick={handlerNextPage}
                style={{background: 'white!important'}}
            ><ChevronRightRoundedIcon style={{ color: '#37CAD3'}} /></button>
        </div>
    )
};

export default Pagination;