import React, { useState, useEffect } from "react";
import { Select, Menu, MenuItem, Button } from "@material-ui/core";
import InputLabel from "@material-ui/core/InputLabel";
import FormControl from "@material-ui/core/FormControl";
import { nanoid } from "nanoid";
import _ from "lodash";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "./modal";
import { makeStyles } from "@material-ui/core/styles";
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';
import { Tooltip } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  formControl: {
      // margin: theme.spacing(1),
      minWidth: 130,
  },
rect5Text: {
    position: "absolute",
    width: "100px",
    height: "22px",
    left: "0px",
    top: "16%",
    bottom: "20%",

    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    /* identical to box height */
    color: "#21DA8C"
  },
  root:{
    color:"#8A8A8A",
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  },
  icon: {
    color: "#21DA8C",
    fill: '#21DA8C',
    top:"0px",
    right: "0px",
    minHeight: '2.3rem',
    minWidth: '2.3rem',
    background: "linear-gradient(0deg, rgba(55, 234, 158, 0.08), rgba(55, 234, 158, 0.08)), #FFFFFF",
    borderRadius: "99px",
  },
  selected: {
    borderRadius:"16px",
    background:"#21DA8C !important" 
  },
  select:{
    "& .MuiSelect-select:focus": {
      background: '#ffffff',
  },
  },
  scroll:{
    
    "::-webkit-scrollbar-track": {
        backgroundColor:"#FFFFFF!important",
      },
      
      "::-webkit-scrollbar": {
        width: "7px!important",
        height: "5px!important",
        backgroundColor: "#FFFFFF!important",
      },
      
      "::-webkit-scrollbar-thumb": {
        backgroundColor: "rgba(0,0,0,0.3) !important",
        borderRadius: "4px!important",
      }
  }
}))
export const FilterContext = React.createContext(null);

function Filters(props) {
  const [showModal, setShowModal] = useState(props.showModal || false);
  const [actionType, setActionType] = useState("");
  const [attributeList, setAttributeList] = useState(props?.attributeData);
  const [showErrors, setShowErrors] = useState(false);
  const [filterName, setFilterName] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("");
  const [open, setOpen] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [deletedFilter, setDeletedFilter] = useState("");
  const {
    options,
    customStyle,
    getFilterData,
    attributesValueURL,
    onClickFilter,
    onModalClose,
    // chipToFocus,
    // chipClicked,
    // setChipClicked,
  } = props;

  useEffect(() => {
    setAttributeList([
      {
        attrId: nanoid(),
        values: [],
        attribute: "",
        relation: "",
        isValid: false,
      },
      ...props?.attributeData,
    ]);
  }, [props.attributeData]);

  useEffect(() => {
    if (props.showModal === false) {
      handleClose();
      setFilterName("");
    }
    setShowModal(props.showModal);
  }, [props.showModal]);

  const { Provider } = FilterContext;

  function onFilterChange(data) {
    const { attrData, elementIndex } = data;

    const attrList = [...attributeList];
    attrList[elementIndex] = attrData;
    setAttributeList(attrList);
  }

  function onAddFilterItem(attributeData, elementIndex) {
    if (attributeData?.isValid) {
      const attrList = [...attributeList];
      attrList[elementIndex] = attributeData;
      setShowErrors(false);
      setAttributeList([
        {
          attrId: nanoid(),
          values: [],
          attribute: "",
          relation: "",
          isValid: false,
        },
        ...attrList,
      ]);
    } else {
      setShowErrors(true);
    }
  }

  function deleteFilter(elementIndex) {
    const attrList = [...attributeList];
    attrList.splice(elementIndex, 1);
    setAttributeList(attrList);
  }

  function onClearAttributes(elementIndex) {
    const attrList = [...attributeList];

    attrList[0] = { ...attrList[0], relation: "", attribute: "", values: [] };

    setAttributeList(attrList);
  }

  function applyFilters(flag) {
    // setChipClicked(false);
    const attrList = [...attributeList];
    console.log("applyFilters", attrList)
    attrList.splice(0, 1);
    let errors = _.filter(attrList, { isValid: false });
    if (!attrList?.length) {
      errors[0] = [{ relation: "", values: [], attribute: "", isValid: false }];
    }
    setShowErrors(errors?.length);
    getFilterData({
      attrList,
      actionType: flag,
      isValid: !errors.length,
      filterName,
    });
    if (!errors?.length) {
      // if(flag !== 'SAVE_APPLY'&& flag !== 'ONLY_APPLY') {
      //     onModalClose();
      // }
    }
  }

  //   function onModalClose() {
  //     setShowModal(false);
  //     setAttributeList([]);
  //     setFilterName('');
  //   }

  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const classes=useStyles()

  return (
    <Provider
      value={{
        onFilterChange,
        attributeList,
        onAddFilterItem,
        deleteFilter,
        onClearAttributes,
        showErrors,
        attributesValueURL,
        actionType,
        applyFilters,
        filterName,
        // chipClicked,
        // setChipClicked,
      }}
    >
      <FormControl
        size="small"
        variant="outlined"
        className={classes.formControl}
        style={{outlineColor:"#21DA8C"}}
      >
        {/* <InputLabel
          id="demo-simple-select-label"
          className={classes.rect5Text}
          style={{ top: "0px", height: "30px" }}
        >
          <div style={{display:"flex",gap:"12px", borderRadius: "95px"}}>
          <div>
           {open===false&&<svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
           <path d="M7.29785 15C7.29785 15.5523 7.74557 16 8.29785 16H10.2979C10.8501 16 11.2979 15.5523 11.2979 15V13.3077C11.2979 12.7554 10.8501 12.3077 10.2979 12.3077H8.29785C7.74557 12.3077 7.29785 12.7554 7.29785 13.3077V15ZM1.29785 0C0.745567 0 0.297852 0.447715 0.297852 1V2.69231C0.297852 3.24459 0.745567 3.69231 1.29785 3.69231H17.2979C17.8501 3.69231 18.2979 3.24459 18.2979 2.69231V1C18.2979 0.447716 17.8501 0 17.2979 0H1.29785ZM3.29785 8.84615C3.29785 9.39844 3.74557 9.84615 4.29785 9.84615H14.2979C14.8501 9.84615 15.2979 9.39844 15.2979 8.84615V7.15385C15.2979 6.60156 14.8501 6.15385 14.2979 6.15385H4.29785C3.74557 6.15385 3.29785 6.60156 3.29785 7.15385V8.84615Z" fill="#21DA8C"/>
          </svg>}
          </div>
          <div>
          Filter
          </div>
          </div>
        </InputLabel> */}
        
        <Select
          className={classes.select}
          open={open}
          displayEmpty={true}
          onClose={handleClose}
          onOpen={handleOpen}
          labelId="filter-label"
          id="filter-setup"
          value={selectedFilter}
          // renderValue={(value) => <label>{value}</label>}
                style={{borderRadius:"95px", height:"36px", paddingTop:"8px"}}
                inputProps={{
                    classes: {
                        icon: classes.icon,
                        root: classes.root,
                    },
                }}
          //       label="Filter"
          startAdornment={
            <div style={{marginRight:'2px'}}>
            <svg width="19" height="16" viewBox="0 0 19 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.29785 15C7.29785 15.5523 7.74557 16 8.29785 16H10.2979C10.8501 16 11.2979 15.5523 11.2979 15V13.3077C11.2979 12.7554 10.8501 12.3077 10.2979 12.3077H8.29785C7.74557 12.3077 7.29785 12.7554 7.29785 13.3077V15ZM1.29785 0C0.745567 0 0.297852 0.447715 0.297852 1V2.69231C0.297852 3.24459 0.745567 3.69231 1.29785 3.69231H17.2979C17.8501 3.69231 18.2979 3.24459 18.2979 2.69231V1C18.2979 0.447716 17.8501 0 17.2979 0H1.29785ZM3.29785 8.84615C3.29785 9.39844 3.74557 9.84615 4.29785 9.84615H14.2979C14.8501 9.84615 15.2979 9.39844 15.2979 8.84615V7.15385C15.2979 6.60156 14.8501 6.15385 14.2979 6.15385H4.29785C3.74557 6.15385 3.29785 6.60156 3.29785 7.15385V8.84615Z" fill="#21DA8C"/>
            </svg>
            </div>
          }
          renderValue={(value) =>{
            if(value.length==0) return <div style={{alignItems: 'center',marginLeft:'2px',paddingBottom:'3px',color:'#21DA8C'}}>Filter</div>
            return <Tooltip title={value} placement="bottom">
              <div style={{marginLeft:'2px',paddingBottom:'3px', overflow:"hidden",textOverflow:"ellipsis", width:"70px",maxWidth:"70px"}}>{value}</div>
              </Tooltip>

              }}

                IconComponent ={ArrowDropDownOutlinedIcon}
                defaultValue="none"
                MenuProps={
                    
                  {
                   PaperProps: {
                       style: {
                         borderRadius: "16px",
                       },
                     },
                    getContentAnchorEl: () => null,
                    anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
                     transformOrigin: { vertical: 'top', horizontal: 'right' },
        
                      }
                    
                   }
          
        >
          <MenuItem
            margin="dense"
            value="Add Filter"
            style={{
              display: "flex",
              alignItems: "center",
              width:'100%',
              height: "36px",
              justifyContent: "center",
              // width: 200,
              background: "#fff",
              padding: "0 8px",
              lineHeight: "24px",
              letterSpacing: "0.15px",
              fontSize: "16px",
              // borderBottom: "1px solid #ccc",
            }}
            onClick={(event) => {
              setAttributeList([
                {
                  attrId: nanoid(),
                  values: [],
                  attribute: "",
                  relation: "",
                  isValid: false,
                  index: 0,
                },
              ]);
              // setShowModal(true);
              onClickFilter("", "add");
              setSelectedFilter("Add Filter");
              setActionType("ADD");
              event.stopPropagation();
            }}
          >
            <span style={{display:"inline-flex" ,color:"rgba(0, 0, 0, 0.87)"}}>
            <AddIcon style={{color:"rgba(0, 0, 0, 0.87)"}}/> New Filter
            </span>
          </MenuItem>

          {options?.map((option, index) => (
            <MenuItem style={{ padding: 0, width: "100%" }} key={index}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                  height: "36px",
                  background: "#fff",
                  padding: "0 8px",
                  // borderBottom: "1px solid #ccc",
                  color:"#rgba(0, 0, 0, 0.87)",
                  lineHeight: "24px",
                  letterSpacing: "0.15px",
                  fontSize: "16px",
                }}
                classes={{ selected: classes.selected }}
                onClick={() => {
                  setSelectedFilter(option?.name);
                  onClickFilter(option?.name, "item");
                }}
              >
                <span
                  style={{
                    margin: 0,
                    // marginLeft: 15,
                    marginLeft:'5px',
                    width: 100,
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color:"rgba(0, 0, 0, 0.87)"
                  }}
                >
                  {option?.name}
                </span>
                <div>
                  <IconButton aria-label="edit" style={{ padding: "0", marginRight: "5px" }}>
                    <EditIcon
                      onClick={(event) => {
                        onClickFilter(option?.name, "edit");
                        // setShowModal(true);
                        setFilterName(option?.name);
                        setActionType("EDIT");
                        event.stopPropagation();
                      }}
                      style={{ color: "#939393" }}
                    />
                  </IconButton>
                  <IconButton aria-label="delete" style={{ padding: "0", marginRight: "5px" }}>
                    <DeleteIcon
                      onClick={(event) => {
                        // onClickFilter(option?.name,'delete');
                        setOpenConfirmation(true);
                        setDeletedFilter(option?.name);
                        event.stopPropagation();
                      }}
                      style={{ color: "#939393" }}
                    />
                  </IconButton>
                </div>
              </div>
            </MenuItem>
          ))}

          
          {/* <MenuItem
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 200,
              background: "#fff",
              padding: "0 8px",
            }}
            value="None"
            onClick={() => {
              setSelectedFilter("None");
              onClickFilter("none");
            }}
          >
            None
          </MenuItem> */}
        </Select>
      </FormControl>
      {showModal && (
        <Modal
          showModal={showModal}
          onModalClose={(e) => {
            onModalClose();
            setShowModal(false);
            setFilterName("");
            handleClose();
            setActionType('');
          }}
          actionType={actionType}
          setActionType={setActionType}
          changeFilterName={setFilterName}
          filterName={filterName}
          showErrors={showErrors}
        />
      )}
      <Dialog
        open={openConfirmation}
        onClose={openConfirmation}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Do you want to delete?
        </DialogTitle>
        <DialogContent>
          <div style={{ display: "flex" }}>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: "10%" }}
              onClick={(e) => {
                setOpenConfirmation(false);
                handleClose();
              }}
            >
              No
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "10%" }}
              onClick={(e) => {
                setOpenConfirmation(false);
                onClickFilter(deletedFilter, "delete");
                handleClose();
              }}
            >
              Yes
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    </Provider>
  );
}

export default Filters;
