let CATEGORY = [
    { title: "Industry", groupName: "ACCOUNT" },
    { title: "Sub Industry", groupName: "ACCOUNT" },
    { title: "Company Revenue", groupName: "ACCOUNT" },
    { title: "Account Classification", groupName: "ACCOUNT" },
    { title: "Capillary Geography", groupName: "ACCOUNT" },
    { title: "Company Type", groupName: "ACCOUNT" },
    { title: "Company Hierarchy", groupName: "ACCOUNT" },
    { title: "Account Region", groupName: "ACCOUNT" },
    { title: "Number of Contacts", groupName: "ACCOUNT" },
    { title: "Number of Stores", groupName: "ACCOUNT" },
    { title: "Account Source", groupName: "ACCOUNT" },
    { title: "Company Name", groupName: "ACCOUNT" },
    { title: "Company Country", groupName: "ACCOUNT" },
    { title: "Company State", groupName: "ACCOUNT" },
    { title: "Company City", groupName: "ACCOUNT" },
    { title: "Company Phone", groupName: "ACCOUNT" },
    { title: "Brand Revenue Number", groupName: "ACCOUNT" },
    { title: "Business Format", groupName: "ACCOUNT" },
    { title: "Group Company Name", groupName: "ACCOUNT" },
    { title: "Parent Company Name", groupName: "ACCOUNT" },
    { title: "Parent Company Website", groupName: "ACCOUNT" },
    { title: "Group Company Website", groupName: "ACCOUNT" },
    { title: "Country (Bombora)", groupName: "ACCOUNT" },
    { title: "Company Industry (Bombora)", groupName: "ACCOUNT" },
    { title: "Company Size (Bombora)", groupName: "ACCOUNT" },

    { title: "Field Sales", groupName: "ASSIGNED TO" },
    { title: "Inside Sales", groupName: "ASSIGNED TO" },

    { title: "Account Creation Date", groupName: "DATE" },
    { title: "Account Modification Date", groupName: "DATE" },
    { title: "Contact Creation Date", groupName: "DATE" },
    { title: "Contact Modification Date", groupName: "DATE" },


];

export const CATEGORIES=CATEGORY.sort((a, b)=> {
    if (a.groupName === b.groupName){
      return a.title < b.title ? -1 : 1
    } else {
      return a.groupName < b.groupName ? -1 : 1
    }
  })

export const NUMERIC_FIELDS = [
    'Number of Stores',
    'Company Revenue',
    'Brand Revenue Number',
    'Number of Contacts'
];

export const DATE_FIELDS = [
    'Account Creation Date',
    'Account Modification Date',
    'Contact Creation Date',
    'Contact Modification Date'
];

// export const CATEGORIC_FIELDS = [
//     "Mailing Country",
//     "Company Type",
//     "Field Sales",
//     "Inside Sales",
//     "Field Marketing",
//     "Capillary Geography",

//     "Industry",
//     "Sub Industry",
//     "Company Revenue",
//     "Account Classification",
//     "Mailing Country",
//     "Company Type",
//     "Company Hierarchy",
//     "Account Region",
//     "Field Sales",
//     "Inside Sales",
//     "Number of Contacts",
//     "Account Source",
//     "Company Name",
//     "Group Name",
//     "Primary Company",
//     "Company Phone",
//     "Company Fax",
//     "Company Street",
//     "Company City"
// ]