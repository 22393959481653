import { SvgIcon } from "@material-ui/core";

export default function NewAccount(props) {
  return (
    <SvgIcon {...props}>

      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.9999 8V0H3.99988V4H-0.00012207V18H7.99988V14H9.99988V18H17.9999V8H13.9999ZM3.99988 16H1.99988V14H3.99988V16ZM3.99988 12H1.99988V10H3.99988V12ZM3.99988 8H1.99988V6H3.99988V8ZM7.99988 12H5.99988V10H7.99988V12ZM7.99988 8H5.99988V6H7.99988V8ZM7.99988 4H5.99988V2H7.99988V4ZM11.9999 12H9.99988V10H11.9999V12ZM11.9999 8H9.99988V6H11.9999V8ZM11.9999 4H9.99988V2H11.9999V4ZM15.9999 16H13.9999V14H15.9999V16ZM15.9999 12H13.9999V10H15.9999V12Z" fill="#21DA8C"/>
      </svg>
    </SvgIcon>
  );
}
