import React,{useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import "./gridindex.css";
import UITheme from "@bamboobox/b2-theme"
import getColumns from "./getContactColumns";
import axios from 'axios';
import {API_BASE_URL} from '../constants.js'
import { makeStyles, useTheme ,withStyles} from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { red,green } from '@material-ui/core/colors'
import Delete from '@material-ui/icons/Delete';
import {useAuthState, AuthProvider} from "@bamboobox/b2logincheck";
import Alert from '@mui/material/Alert';
import "./../toast.css"

const localhost = API_BASE_URL;

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});


export default function AlertDialog(props) {
  const [open, setOpen] = React.useState(false);
  const TRASH_ICON = <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
  <rect x="0.5" width="24" height="24" rx="12" fill="#F2F2F2"/>
  <path d="M7.77778 17.4444C7.77778 18.3 8.47778 19 9.33333 19H15.5556C16.4111 19 17.1111 18.3 17.1111 17.4444V8.11111H7.77778V17.4444ZM17.8889 5.77778H15.1667L14.3889 5H10.5L9.72222 5.77778H7V7.33333H17.8889V5.77778Z" fill="#F7685B"/>
  </svg>
  


  const styles = {
 select: { marginBottom: '20px', marginTop: '20px', width: '95%', height: '50%', outline:'none',display: 'flex', justifyContent: 'flex-center', alignItems: 'center', borderColor: '#0000', borderBottom: '1px solid #eee', borderRadius: '0%', background: '#fefefe',padding: '0%', },
 editButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', padding: 4, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
 buttonsCellEditorContainer: { height: '100%', width: '100%', display: 'inline-flex', padding: '0 20px', justifyContent: 'flex-end', alignItems: 'center' },
 cancelButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', marginRight: 10, padding: 2, display: 'inline-flex', border: 'none', borderRadius: '50%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
 saveButton: { background: '#f3f3f3', outline: 'none', cursor: 'pointer', padding: 2, display: 'inline-flex', border: 'none', borderRadius: '30%', boxShadow: '1px 1px 2px 0px rgb(0 0 0 / .3)' },
 deleteButton: { background: '#f3f3f3', cursor: 'pointer',marginLeft: 0, padding: 0, display: 'flex', border: 'none', borderRadius: '50%'}

}

const [state, setState] = React.useState({

  vertical: 'top',
  horizontal: 'center',
});

const { vertical, horizontal } = state;
const userData = useAuthState();
let tenantId = userData.user.tenantId;
let userId = userData.user.userUuid;
const [openSnackbar,setOpenSnackbar] = useState(false);
const [message,setMessage] = useState("");
const [severity,setSeverity] = useState("");
// function Alert(props) {
//   return <MuiAlert elevation={6} variant="filled" {...props} />;
// }
const closeSnackbar = (event, reason) => {
  if (reason === 'clickaway') {
      return;
  }
  setOpenSnackbar(false);
};


const NoButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    marginLeft: '2px',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },

  },
}))(Button);
const YesButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(green[500]),
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
}))(Button);

const handleClickOpen = () => {
  setOpen(true);
};

const handleClose = () => {
  setOpen(false);
};
const deleteSingleRow = (rowIndex,rowsClone) =>{

    // if (window.confirm('Are you sure you want to delete this contact ?')) {
    //   // Save it!
     console.log(props.rowsClone)
    if(props.rowsClone[props.rowIndex-1]["contactId"] === ""
    || props.rowsClone[props.rowIndex-1]["contactId"] === null)
    {
      console.log("Hi")
      // setOpen(false)
      // setSeverity("info")
      // setMessage("You can't delete an empty or an unsaved row!")
      rowsClone.splice(0,1); 
    var data = rowsClone;
    props.setRowsData([...data]);
     // setOpenSnackbar(true);
   // alert("You can't delete an empty or an unsaved row");
    }
    else{
    // console.log(rowIndex);
    //setOpenSnackbar(false);
    var cId = props.rowsClone[props.rowIndex-1]["contactId"];
    console.log(cId); 
    axios.delete(localhost+"/b2pm/deletedata/"+tenantId+"/"+userId+"/contact/"+cId,{withCredentials:true}).then((contact) => {
      props.rowsClone.splice(props.rowIndex-1,1); 
      var data = props.rowsClone;
      props.setRowsData([...data]);
      console.log(contact.status)
      if(contact.status === 200)
      {
        

      }

  });
 

   
    }

    toast.error(<Alert severity="error">Contact Deleted successfully</Alert>,{
      closeOnClick: true,
      autoClose: true,
      closeButton: true,
      newestOnTop: true
    });
    
  
      console.log('Thing was saved to the database.');
     // setOpenSnackbar(false);
    } 
  
  
// style={styles.deleteButton} 
  return (
    <div>

      <button style={styles.deleteButton}  color="primary" onClick={handleClickOpen}>
        <Delete fontSize="small" style={{color:UITheme.palette.error.light}}/>
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete the contact?"}</DialogTitle>
        <DialogContent>
          {/* <DialogContentText id="alert-dialog-description"> */}
            
          {/* </DialogContentText> */}
        </DialogContent>
        <DialogActions>
        <Button variant="contained" color="primary" onClick={()=> deleteSingleRow(props.rowIndex,props.rowsClone)}>
           Yes
          </Button>
          <Button variant="outline" color="primary"  onClick={handleClose} autoFocus>
            No
          </Button>
         
        </DialogActions>
      </Dialog>


    </div>

    
  );
}
