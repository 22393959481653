import { TextField } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css'; 
import { Icon } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import React, { useState, useEffect } from "react";
import Alert from '@mui/material/Alert';
import "./../toast.css"

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});


function ValidationUIComponent(props){
    
    let value=props.value
    let onChange=props.onChanged
    let data=props.data
    let column=props.column;
    let validator = props.validator;
    let message = props.message;
    let placeholder = props.placeholder
    let [disabled, setDisabled] = useState(props.disabled || false);
    let adornment=(props.adornment)?props.adornment:null;
    let label="";
    if (props.label && props.label.trim()){
      label=props.label;
    }
    let id=props.id;
    let variant="standard";
    
    if (props.variant && props.variant.trim()){
      variant=props.variant;
    }

    let styled={ width: "95%", margin: "2", marginBottom:"1%" }
    if (id){
       styled={}
    }

    var onFocusLost=async(event)=> {

        let inpValue= event.target.value;
        if (!inpValue || !inpValue.trim()) {
            console.log("empty value entered")
          return;
        }
        if (validator){
            console.log("validator is not empty")
            if(!validator(inpValue)) {
                console.log("wrong format of data entered")
                if (column){
                  toast.error(<Alert severity="error">{column.label+message}</Alert>,{
                    closeOnClick: true,
                    autoClose: true,
                    closeButton: true,
                    newestOnTop: true
                  });
                  return;
            }
            if (id){
              let temp = label;
              if (temp.includes("*")){
                try{
                temp=temp.slice(0, -1)
                }catch(e){
                  console.log(e)
                }
              }
                toast.error(<Alert severity="error">{temp+message}</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true
                });
              return;
            }
          }
        }
        console.log("onFocusLost",inpValue);
       
        
      }

      const handleChange = (event) => {
        let newValue = event.target.value;
        // console.log(newValue);
        if (column){
        onChange({ ...data, [column.field]: newValue });
        }
        if (id){
          props.onChanged(event);
        }

      };


    return (
        (adornment)?
        <TextField id={props.id} label={label} disabled={disabled} value={value} variant={variant} onBlur={onFocusLost} autoComplete="off"  onChange={handleChange} placeholder={placeholder} style={styled} InputProps={{
          startAdornment: <InputAdornment position="start">{adornment}</InputAdornment>
        }}/>
        :
<TextField id={props.id} disabled={disabled} label={label} value={value} variant={variant} onBlur={onFocusLost} autoComplete="off"  onChange={handleChange} placeholder={placeholder} style={styled}/>
        
      )
}

export default ValidationUIComponent;