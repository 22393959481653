import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
// import MyAwesomeTable from './ContactCreation';
import MyAwesomeTable2 from './AccountCreation';
import reportWebVitals from './reportWebVitals';

import "./gridindex.css";

// ReactDOM.render(
//   <React.StrictMode>
//     <App /> 
//     {/* <MyAwesomeTable /> */}
//   </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
