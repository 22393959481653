import  {AccountDetail}  from "./components_detailview/AccountDetail";
import MyAwesomeTable2 from "./AccountCreation";
import { useClearCache } from 'react-clear-cache';
import React from "react";
import { useEffect } from 'react';
import {
    HashRouter as Router,
    Route,
    Link,
    Switch
  } from 'react-router-dom';


export default function RApp() {

  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  useEffect(() => {
    console.log("latest version",isLatestVersion);
    if(!isLatestVersion) {
        // e.preventDefault();
        emptyCacheStorage();
        window.location.reload(true);
          
    }
}, [isLatestVersion])
    return (
        <Router>
            <Switch>
                <Route exact path="/AccountDetail/:routeAccountId" component={AccountDetail} ></Route>
                <Route exact path="/" component={MyAwesomeTable2} ></Route>
                <Route exact path="/filter/:id/:previousPage/:filterAppliedOnPreviousPage" component={MyAwesomeTable2} ></Route>
            </Switch>
        </Router>
    );
}