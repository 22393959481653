import React, {useContext,useState} from "react";
import Dialog from "@material-ui/core/Dialog";
import { styled } from "@mui/system";
import {
  Divider,
  TextField,
  DialogContent,
  IconButton,
} from "@material-ui/core/";
import CancelIcon from "@material-ui/icons/Cancel";
import Tooltip from "@material-ui/core/Tooltip";
import AttributeList from "../attributelist";
import Footer from "../footer";
import { FilterContext } from "../index";
import Button from "@material-ui/core/Button";
import "./index.css"

const Dialog1 = styled(Dialog)(({ theme }) => ({

  [`.MuiPaper-rounded`]:{
    borderRadius: '18px',
  },
}))

function Modal({
  showModal,
  onModalClose,
  changeFilterName,
  filterName,
  showErrors,
  icp,
  actionType,
  setActionType,
  // chipClicked,

}) {
  const { applyFilters} = useContext(FilterContext);

  const [checked, setChecked] = useState(false);
  // console.log('chipClicked is',chipClicked);
  console.log("Action type",actionType)
  return (
    <Dialog1
      maxWidth="lg"
      open={showModal}
      onClose={onModalClose}
      aria-labelledby="form-dialog-title"
      disableBackdropClick={true}
      style={{width:"70vw", height:"auto", left: "16vw"}}
      className="modal"
    >
      <DialogContent id="customized-dialog-title" draggable style={{ minWidth: 700, position: "relative" }}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              maxWidth: "66vw",
              height: "fit-content",
            }}
          >
            </div>
          <div style={{display:"flex"}}>
          <TextField
                disabled={(actionType==='EDIT' || checked===false)?true:false}
                className="text"
                  style={{
                    width: '33.5vw',
                    height:"32px",
                    borderRadius: 4,
                    borderWidth: 1,
                    backgroundColor: (actionType ==='EDIT' || checked===false)?"rgba(0, 0, 0, 0.12)":"#FFFFFF",
                    fontStyle: "italic",
                    color: (actionType ==='EDIT' || checked===false)?"#6E6E6E":"#8A8A8A",
                  }}
                placeholder="Filter Name"
                variant="outlined"
                value={filterName}
                id="filter-name-header"
                // error={showErrors && !filterName}
                // error={showErrors}
                onInput={(e) => {
                  changeFilterName(e.target.value);
                }}
              />
          <div style={{padding:"10px",width:"30vw"}}>
            {/* {actionType === "ADD" || chipClicked ? ( */}
            {actionType === "ADD" ? (
            <div  style={{padding:"7px"}}>
                  <input
                    type="checkbox"
                    checked={checked}
                    onChange={(e) => {
                      setChecked(e.target.checked)
                    }}
                  />
                  <label style={{ color: "#000000" }}>Save Filter</label>
                </div>
              ) : (
                <Button
                  style={{
                    width: 100,
                    padding: 10,
                    background: filterName===""?"#6E6E6E":"#21DA8C",
                    color: "#FFFFFF",
                    height:"32px",
                    paddingTop: "12px",
                    fontWeight:700
                  }}
                  onClick={() => {
                    
                    if(filterName === ''){
                      document.getElementById("filter-name-header").focus();
                      console.log('in edit and saving without name');
                    }else{
                      applyFilters("SAVE");
                      console.log('in edit and saving without name');
                    }
                    setActionType('');
                  }}
                >
                  SAVE
                </Button>
            )}
          </div>
          <Tooltip disableFocusListener title="Close">
              <IconButton aria-label="close" style={{ padding: "0", height:"20px", marginTop:"3.2vh" }}>
                <CancelIcon style={{color:"#A0A0A0"}} onClick={onModalClose} />
              </IconButton>
            </Tooltip>
          </div>
            {/* <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
              }}
            >
              <p style={{color:"#595959",
                  fontSize:"14px",
                  fontWeight:"bold",
                  align:"left",
                  verticalAlign:"top",}}>Filter Name</p>
              
            </div> */}
            
          
          
          <Divider style={{margin:"8px 0px 24px 0px"}}/>
          <AttributeList icp={icp} />
          <Footer checked={checked} setChecked={setChecked} setActionType={setActionType} onModalClose={onModalClose}/>
      </DialogContent>
    </Dialog1>
  );
}

export default React.memo(Modal);