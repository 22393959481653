import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import SaveIcon from '@material-ui/icons/Save';
import axios from 'axios'
 
 import {API_BASE_URL} from '../constants';


const localhost = API_BASE_URL;

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));



function SaveButton(props) {
  const classes = useStyles();
 //http://localhost:8080
 //b2prospectapi.bamboobox.in
   function onCancel(e) {
       props.setAcType("Existing");
      props.setShowNewAccount(false);
    };
  function onSave(e) {
    let state = {articleId: -1};
    e.preventDefault();
    axios.post(localhost+'/b2pm/savedata/account',window.updatedFormData,{withCredentials:true})
    .then((response) => {
      //  alert(JSON.stringify(response.data.rowSelected[0].account.accountId));
       if(response.status == 200)
          {
            console.log("Saved")
              props.setAcType("Existing");
            props.setShowNewAccount(false);
          }
        });

  
  }

  return (
    <div class="flex-container">
      <div> <h3>Account Details</h3> </div>
      <div>
      <Button onClick={onSave}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
        starticon={<SaveIcon />}
      >
        Save
      </Button>
        <Button onClick={onCancel}
        variant="contained"
        color="primary"
        size="small"
        className={classes.button}
       
      >
        Cancel
      </Button>
      </div>
    </div>
  );
}

export default SaveButton;