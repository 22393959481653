import React from "react";
import "date-fns";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import "./DateRangePickerComponent.css";
import Button from "@material-ui/core/Button";
import Popover from '@mui/material/Popover';

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { MuiThemeProvider } from "@material-ui/core";
import UItheme from "@bamboobox/b2-theme"
import DateRangeRoundedIcon from '@mui/icons-material/DateRangeRounded';
function convertDateRangeToFormattedString(oldDate, currentDate) {
  let monthArray = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var tempOldDate = new Date(oldDate)
      tempOldDate.setUTCDate(tempOldDate.getDate())
      tempOldDate.setUTCHours(0, 0, 0, 0)
  var tempCurrentDate = new Date(currentDate)
    tempCurrentDate.setUTCDate(tempCurrentDate.getDate())
    tempCurrentDate.setUTCHours(0, 0, 0, 0)
  let dateString = `${tempOldDate.getDate()} ${monthArray[tempOldDate.getMonth()]
    } ${tempOldDate.getFullYear().toString().substring(2, 4)} - ${tempCurrentDate.getDate()} ${monthArray[tempCurrentDate.getMonth()]
    } ${tempCurrentDate.getFullYear().toString().substring(2, 4)}`;
  console.log(
    "convertDateRangeToFormattedString",
    tempOldDate,
    tempCurrentDate,
    dateString
  );
  return dateString;
}

function DateRangePickerComponent(props) {
  const frequencyValues = ["Weeks", "Months", "Quarters", "Years"];

  const [open, setOpen] = React.useState(false);

    const [currentDate, setCurrentDate] = React.useState((props?.attributeData?.values[1])?new Date(props.attributeData.values[1]) : new Date());
    const [oldDate, setOldDate] = React.useState((props?.attributeData?.values[0])?new Date(props.attributeData.values[0]):  new Date(Date.now() - 86400000));
    
  
    const [displayedDate, setDisplayedDate] = React.useState(
      convertDateRangeToFormattedString(oldDate, currentDate)
    );
    const [fromDateMax, setFromDateMax] = React.useState(new Date());
    const [fromDateMin, setFromDateMin] = React.useState(undefined);
    const [toDateMax, setToDateMax] = React.useState(new Date());
    const [toDateMin, setToDateMin] = React.useState(undefined);
  
    const [radioBtnValue, setRadioBtnValue] = React.useState("fixed");

    const [numberFieldvalue, setNumberFieldvalue] = React.useState("3");
    const [intervalValue, setIntervalValue] = React.useState("Weeks");
    const [prevOldDate, setPrevOldDate] = React.useState(new Date(oldDate))
    const [prevCurrentDate, setPrevCurrentDate] = React.useState(new Date(currentDate))
    const [prevIntervalValue, setPrevIntervalValue] = React.useState(intervalValue)
    const [prevNumberFieldValue, setPrevNumberFieldValue] = React.useState(numberFieldvalue);
    const [flag,setFlag] = React.useState(0)
    let setFromDate = props?.setFromDate;
    let setToDate = props?.setToDate;
    React.useEffect(() => {
      if(flag==0 && currentDate && oldDate){
        var tempOldDate = new Date(oldDate)
        tempOldDate.setUTCDate(tempOldDate.getDate())
        tempOldDate.setUTCHours(0, 0, 0, 0) 
        var tempCurrentDate = new Date(currentDate)
        tempCurrentDate.setUTCDate(tempCurrentDate.getDate())
        tempCurrentDate.setUTCHours(0, 0, 0, 0)
        props.onAttributeChange([tempOldDate, tempCurrentDate], "values");
        setFlag(1)
      }
    })

  // const handleIntervalChange = (event) => {
  //   // console.log("changed interval to ", event?.target?.value)

  //   // if (event?.target?.value === "Days" && numberFieldvalue > 366) { console.log("Days selected setting number to 366");setNumberFieldvalue("366");}
  //   // if (event?.target?.value === "Weeks" && numberFieldvalue > 53) {console.log("Weeks selected setting number to 53");setNumberFieldvalue("53");}
  //   // if (event?.target?.value === "Months" && numberFieldvalue > 12) {console.log("Months selected setting number to 12");setNumberFieldvalue("12");}
  //   // if (event?.target?.value === "Quarters" && numberFieldvalue > 4){ console.log("Quarters selected setting number to 4");setNumberFieldvalue("4");}
  //   // if (event?.target?.value === "Years" && numberFieldvalue > 1) {console.log("Years selected setting number to 1");setNumberFieldvalue("1");}

  //   let todayDate = new Date();
  //   setCurrentDate(new Date());

  //   if (event.target?.value === "Days") {
  //     todayDate.setDate(todayDate.getDate() - parseInt(numberFieldvalue));
  //   }
  //   if (event.target?.value === "Weeks") {
  //     todayDate.setDate(
  //       todayDate.getDate() - parseInt((numberFieldvalue || 0) * 7)
  //     );
  //   }
  //   if (event.target?.value === "Months") {
  //     todayDate.setMonth(
  //       todayDate.getMonth() - parseInt(numberFieldvalue || 0)
  //     );
  //   }
  //   if (event.target?.value === "Quarters") {
  //     todayDate.setMonth(
  //       todayDate.getMonth() - parseInt((numberFieldvalue || 0) * 3)
  //     );
  //   }
  //   if (event.target?.value === "Years") {
  //     let tem = todayDate;
  //     console.log(
  //       "handleIntervalChange - In years - before",
  //       tem,
  //       tem.getFullYear() - parseInt(event.target.value || 0)
  //     );
  //     todayDate.setFullYear(
  //       todayDate.getFullYear() - parseInt(numberFieldvalue || 0)
  //     );
  //     console.log(
  //       "handleIntervalChange - In years - after",
  //       todayDate,
  //       new Date(todayDate)
  //     );
  //   }

  //   setOldDate(new Date(todayDate));

  //   console.log("setting interval to ", event?.target?.value);

  //   if (intervalValue !== event?.target?.value){
  //     setPrevIntervalValue(intervalValue);
  //     setIntervalValue(event.target.value);
  //   }
  // };

  const handleRadioBtnChange = (event) => {
    console.log("changed radio button to ", event?.target?.value);
    setRadioBtnValue(event?.target?.value);
    let e = { target: { value: "" } }
    e.target.value = intervalValue;
    // handleIntervalChange(e);
  };

  const handleNormalBtnClick = (event) => {
    if (open) {
      setAnchorEl(event.currentTarget);
      setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
      var fromDateValue = new Date(oldDate)
      fromDateValue.setUTCDate(fromDateValue.getDate())
      fromDateValue.setUTCHours(0, 0, 0, 0)

      var toDateValue = new Date(currentDate)
      toDateValue.setUTCDate(toDateValue.getDate())
      toDateValue.setUTCHours(0, 0, 0, 0)
      setFromDate(fromDateValue);
      setToDate(toDateValue);
      setOpen(false);
    } else {
      setOpen(true);
    }
  };

  const handleDateFromChange = (value) => {
    let tempDate = value;
    tempDate.setUTCDate(tempDate.getDate())
    tempDate.setUTCHours(0, 0, 0, 0)
    setToDateMin(tempDate.setDate(tempDate.getDate()));

    setPrevOldDate(oldDate)
    setOldDate(new Date(value));
    
    let d1 = new Date(value);
    var tempCurrentDate = new Date(currentDate)
    tempCurrentDate.setUTCDate(tempCurrentDate.getDate())
    tempCurrentDate.setUTCHours(0, 0, 0, 0)
    props.onAttributeChange([tempDate, tempCurrentDate], "values");
    
    
    console.log(
      "handleDateFromChange ",
      value,
      new Date(value),
      tempDate,
      toDateMin
    );
};

  const handleDateToChange = ( value) => {

    setCurrentDate(new Date(value));
    var toDateValue = value
    toDateValue.setUTCDate(toDateValue.getDate())
    toDateValue.setUTCHours(0, 0, 0, 0)
    setFromDateMax(toDateValue.setDate(toDateValue.getDate()));
    var tempOldDate = new Date(oldDate)
    tempOldDate.setUTCDate(tempOldDate.getDate())
    tempOldDate.setUTCHours(0, 0, 0, 0)
    let d1 = new Date(value);
    props.onAttributeChange([tempOldDate, toDateValue], "values")
    
    console.log("handleDateToChange ", value, new Date(value), toDateValue, fromDateMin);

  };

  const handleClickAway = () => {
    console.log(
      "handleClickAway",
      convertDateRangeToFormattedString(oldDate, currentDate)
    );
    setOpen(false);

    setDisplayedDate(convertDateRangeToFormattedString(oldDate, currentDate));
    var tempOldDate = new Date(oldDate)
      tempOldDate.setUTCDate(tempOldDate.getDate())
      tempOldDate.setUTCHours(0, 0, 0, 0)
    var fromDateValue = new Date(Date.UTC(tempOldDate.getFullYear(), tempOldDate.getMonth(), tempOldDate.getDate()))
    // fromDateValue.setUTCDate(fromDateValue.getDate())
    // fromDateValue.setUTCHours(0, 0, 0, 0)

    var tempCurrentDate = new Date(currentDate)
    tempCurrentDate.setUTCDate(tempCurrentDate.getDate())
    tempCurrentDate.setUTCHours(0, 0, 0, 0)
    var toDateValue = new Date(Date.UTC(tempCurrentDate.getFullYear(), tempCurrentDate.getMonth(), tempCurrentDate.getDate()))
    // toDateValue.setUTCDate(toDateValue.getDate())
    // toDateValue.setUTCHours(0, 0, 0, 0)
    setFromDate(fromDateValue);
    setToDate(toDateValue);
  };

  const handleCancelBtn = () => {
    setOpen(false)
    setOldDate(prevOldDate);
    setCurrentDate(prevCurrentDate)
    setNumberFieldvalue(prevNumberFieldValue);
    setIntervalValue(prevIntervalValue)
  }
  const [anchorEl, setAnchorEl] = React.useState(null);
  const popupOpen = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <MuiThemeProvider theme={UItheme}>
      <ClickAwayListener onClickAway={() => { }}>
        <span onClick={handleNormalBtnClick} style={{width:"18vw"}} >
          <Button
            variant="outlined"
            style={{display:"flex", justifyContent:"space-between",color:"#8A8A8A",borderColor:"#D2D2D2", height: "38px", marginTop:"8px", width: "18vw", fontSize:"16px",textTransform: "none" }}
            
          >
            {displayedDate}
            <DateRangeRoundedIcon style={{fill: 'rgba(3, 3, 3, 0.6)', paddingRight:"4px"}}/>
          </Button>

          {open ? (
        <Popover style={{ borderRadius:"18px"}}
          open={popupOpen}
          onClose={handleClose}
          anchorEl={anchorEl}
        >
        <MuiPickersUtilsProvider utils={DateFnsUtils} style={{padding:"8px"}}>
        <div style={{padding:"8px"}}>
                        From:
                        <br></br>
                        <KeyboardDatePicker
                            disableToolbar
                            minDate={fromDateMin}
                            maxDate={fromDateMax}
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            placeholder='DD/MM/YYYY'
                            InputProps={{
                                style: {
                                    fontSize: 16,
                                    height: 38,
                                    color:"rgba(3, 3, 3, 0.6)",
                                }
                            }}
                            style={{ width: '18vw'}}
                            value={oldDate}
                            autoOk={true}
                            onChange={handleDateFromChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
        </div>
        <div style={{padding:"8px"}}>
           
                        To:
                        <br></br>
                        <KeyboardDatePicker
                            disableToolbar
                            minDate={toDateMin}
                            maxDate={toDateMax}
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            placeholder='DD/MM/YYYY'
                            InputProps={{
                                style: {
                                    fontSize: 16,
                                    height: 38,
                                    color:"rgba(3, 3, 3, 0.6)",
                                }
                            }}
                            style={{ width: '18vw'}}
                            value={currentDate}
                            autoOk={true}
                            onChange={handleDateToChange}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
        </div>
                      </MuiPickersUtilsProvider>
                    
        <div style={{padding:"8px",display: "flex", marginTop: "15px", alignContent: "center",  justifyContent: "flex-end"}}>
                <Button variant="outlined" color="primary" onClick={handleCancelBtn} style={{marginRight: "24px"}}>
                  Cancel
                </Button>
                <Button variant="contained" color="primary" onClick={handleClickAway}>
                  Apply
                </Button>
              </div>
            </Popover>
          ) : null}
        </span>
      </ClickAwayListener>
    </MuiThemeProvider>
  );
}

export default DateRangePickerComponent;
