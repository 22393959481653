import React, { useState } from "react";
import { FilterContext } from "../index";
import AttributeItem from "../attributeitem";
import {Typography} from "@material-ui/core"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({

  scroll:{
    "&::-webkit-scrollbar-track": {
      backgroundColor: "#f5f5f5",
    },
    "&::-webkit-scrollbar": {
      width: "5px",
      height: "5px",
      backgroundColor: "#f5f5f5",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#dddd",
      border: "5px solid #d8d8d8",
      borderRadius: "16px",
    }
  }
}))

function AttributeList(props) {
  const classes = useStyles();
  const { attributeList, getFilterData } = React.useContext(FilterContext);
  return (
    <div className={classes.scroll} style={{overflowX:"hidden",overflowY:"auto",maxHeight:"50vh",}}>
      {/* style="display: flex; justify-content: space-between; align-items: center; padding: 20px;" */}
      <div style={{display:'flex', justifyContent:"space-between", marginTop:"12px", color:"#595959"}}>
      <Typography variant="subtitle2" style={{width:"19vw",fontWeight:"bold" }}>Attribute</Typography>
        <Typography variant="subtitle2" style={{width:"12.5vw",fontWeight:"bold"}}>Relation</Typography>
        <Typography variant="subtitle2" style={{width:"29.5vw",fontWeight:"bold"}}>Values</Typography>
        {/* <Typography variant="subtitle2" style={{position:"relative",left:"-48px"}}>Actions</Typography> */}
      </div>
      <div style={{display:"flex",  justifyContent:"space-between", flexWrap:"wrap"}} >
      {
        attributeList?.map((attribute, index) => (
          <div key={attribute?.attrId}>
            <AttributeItem key={attribute?.attrId} attributeData={attribute} elementIndex={index} icp={props.icp}/>
          </div>  
        ))
      }
    </div>
    </div>
  );
}
export default React.memo(AttributeList);