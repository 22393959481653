import React, { useContext, useState } from "react";
import { FilterContext } from "../index";
import DoneRoundedIcon from '@mui/icons-material/DoneRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
function Footer(props) {

  const { applyFilters, attributeList, filterName, actionType } = useContext(FilterContext);
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        alignItems: "center",
        justifyContent: "flex-end",
        marginTop: "32px",
        marginBottom: "16px",
        
      }}
    >
      
      <button
        style={{
          width: 100,
          paddingTop: 5,
          fontWeight: "700",
          letterSpacing: "8%",
          verticalAlign: "Top",
          background: "#FFFFFF",
          color: "#A0A0A0",
          border: "1px solid #A0A0A0",
          borderRadius: "18px",
          marginLeft: 10,
          cursor: "pointer",

        }}
        onClick={props.onModalClose}
      >
        <div style={{display:"flex"}}>
        <CloseRoundedIcon style={{color:"#A0A0A0", width:"20%",}}/>
        <div style={{width:"80%", paddingTop:"4px"}}>CANCEL</div>

        </div>

      </button>
      <button
        style={{
          width: "135px",
          paddingTop: 5,
          fontWeight: "700",
          letterSpacing: "8%",
          verticalAlign: "Top",
          background: attributeList?.length === 1 ? "#cccccc" : "#21DA8C",
          color: "#FFFFFF",
          border: "1px",
          borderRadius: "18px",
          marginLeft: 10,
          cursor: attributeList?.length === 1 ? "" : "pointer",
        }}
        disabled={attributeList?.length === 1}
        onClick={() => {
          if (props.checked) {
            if(filterName === ''){
              document.getElementById("filter-name-header").focus();
              console.log('on click of button when add and checked and no name');
            }else{
              applyFilters("SAVE_APPLY");
              console.log('on click of button when add and checked and with name');
            }
            
          } 
          else {
            if (actionType === "ADD") {
              applyFilters("ONLY_APPLY");
              console.log('on click of button when add and not checked ');
            } else {
              console.log('on click of button when add and not checked in else ');
              applyFilters("APPLY");
            }
          }
          props.setActionType('')
        }}
      >
        <div style={{display:"flex"}}>
        <DoneRoundedIcon style={{color:"#FFFFFF", width:"15%",}}/>
        <div style={{width:"85%", paddingTop:"4px"}}>APPLY FILTER</div>
        </div>
      </button>
    </div>
  );
}

export default Footer;