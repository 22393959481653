import React, { useState, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
// import GridTable from "@nadavshaar/react-grid-table";
import GridTable from "../grid_src"
import { css } from "glamor";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GetColumns from "./getContactColumns";
import { useColumnVisibilityPersist } from "@bamboobox/b2gridutils";
import { Button, createMuiTheme, Typography } from "@material-ui/core/";
//import "./index.css";
import "@bamboobox/b2-theme/grid/style.css";
import axios from "axios";
import { ThemeProvider } from "@material-ui/styles";
import uuid from "uuid-random";
import { makeStyles, useTheme, withStyles } from "@material-ui/core/styles";
import Paper from '@mui/material/Paper';

import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

import MuiAlert from "@material-ui/lab/Alert";
import { API_BASE_URL } from "../constants";
import ADD_CONTACT_SVG from "../icons/addContactIcon";
import ENRICH_SVG from "../icons/enrichIcon";
import UItheme from "b2-theme";
import ARROW_BACK from "@material-ui/icons/ArrowBackIos";
import { useAuthState, AuthProvider } from "@bamboobox/b2logincheck";
import { PersonAdd } from "@material-ui/icons";
import loadMetaData, {createHierarchy} from "./../LoadMetaData.js";
import { ViewColumn } from "@material-ui/icons";
import Alert from '@mui/material/Alert';
import "./../toast.css"

const localhost = API_BASE_URL;
const IconButton = withStyles((theme) => ({
  root: {
    ...theme.typography.button,
    boxSizing: "border-box",
    minWidth: 24,
    padding: "6px 6px",
  },
}))(Button);

toast.configure({
  autoClose: 6000,
  draggable: true,
  hideProgressBar: false,
  position: toast.POSITION.BOTTOM_LEFT,
  icon: false,
});

var obj1 = {
  id: 1, // gridData.current.data.rowSelected.length + 1,
  msg: "",
  row_id: 1,
  contactId: "",
  contactFirstName: "",
  contactLastName: "",
  contactTitle: "",
  contactDepartment: "",
  contactSubDepartment: "",
  contactPositionLevel: "",
  contactLinkedin: "",
  contactFacebook: "",
  contactTwitter: "",
  contactMobile: "",
  contactPhone: "",
  contactSecondaryNumber: "",
  contactEmail: "",
  contactPersonalEmail: "",
  contactSecondaryEmail: "",
  contactEducationHistory: "",
  contactEmploymentHistory: "",
  contactIsDefunct: "",
  contactMobilePhoneDoNotCall: "",
  contactDirectPhoneDoNotCall: "",
  contactEmailUnsubscribe: "",

  accountId: "",
  companyName: "",
  companyGroupName: "",
  companyFranchiseeParent: "",
  companyWebsite: "",
  companyParentWebsite: "",
  companyGroupWebsite: "",
  companyPrimaryIndustryCategory: "",
  companySubIndustryCategory: "",
  companyBrandRevenueNumerical: "",
  companyParentRevenue: "",
  companyGroupRevenue: "",
  companyEmployeeCount: "",
  companyParentEmployees: "",
  companyGroupEmployees: "",
  companyFax: "",
  companyPhone: "",
  companyStreet: "",
  companyCity: "",
  companyState: "",
  companyCountry: "",
  companyContinent: "",
  companyZipCode: "",
  companyDescription: "",
  companyLinkedin: "",
  companyFacebook: "",
  companyTwitter: "",
  companyParent: "",
  companyGtmSegment: "",
  companyGtmRegion: "",
  companyLocationCount: "",
  companyProducts: "",
  companyEmployeeGrowth: "",
  companyType: "",
  companyClassification: "",
  companyTechAttributes: "",
  companyHashTag: "",
  companyRecentFundingAmount: "",
  companyRecentFundingDate: "",
  companyTotalFundingAmount: "",
  companyFundingList: "",
  crmNumberofStores: "",
  crmAccountGeography: "",
  crmBusinessFormat: "",
  crmISExecutive: "",
  crmFSExecutive: "",
  crmDataSource: "",
  crmFmExecutive: "",
  // crmAccountRegion: "",
  contactSource: "",
};
const MyAwesomeTable = (props) => {
  const [allMetadata, setAllMetaData] = useState([]);
  const [tenantField, setTenantField] = useState({});
  const [columVisibilityTransformer, onColumnChangeSave] =
    useColumnVisibilityPersist("B2AMDetailedView");
  const [metadata, setMetaData] = useState([]);
  const [stateMetaData, setStateMetataData] = useState([]);
  const [tableManager, setTableManager] = useState(null);
  const [rowsData, setRowsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [editRowId, setEditRowId] = useState(0);
  let [searchText, setSearchText] = useState("");
  let [selectedRowsIds, setSelectedRowsIds] = useState([]);
  let [sort, setSort] = useState({ colId: null, isAsc: true });
  let [page, setPage] = useState(1);
  let [pageSize, setPageSize] = useState(10);
  let [pageSizes, setPageSizes] = useState([10, 50, 75, 100]);
  let [enableColumnsReorder, setEnableColumnsReorder] = useState(true);
  let [highlightSearch, setHighlightSearch] = useState(true);
  let [showSearch, setShowSearch] = useState(false);
  let [showRowsInformation, setShowRowsInformation] = useState(true);
  let [showColumnVisibilityManager, setShowColumnVisibilityManager] =
    useState(true);
  let [isHeaderSticky, setIsHeaderSticky] = useState(true);
  let [isVirtualScroll, setIsVirtualScroll] = useState(true);
  let [isPaginated, setIsPaginated] = useState(true);
  let [minSearchChars, setMinSearchChars] = useState(2);
  let [minColumnResizeWidth, setMinColumnWidth] = useState(70);
  const [open, setOpen] = useState(false);
  let gridData = useRef();
  const userData = useAuthState();
  let tenantId = userData.user.tenantId;
  let userId = userData.user.userUuid;

  let [isSettingsOpen, setIsSettingsOpen] = useState(false);
  let [selectAllMode, setSelectAllMode] = useState("page");
  // const [state, setState] = React.useState({
  //   checkedC: true,
  // });

  let [columns, setColumns] = useState(
    columVisibilityTransformer(
      GetColumns(
        { setRowsData, metadata, stateMetaData, allMetadata },
        { setOpen },
        open,
        props.accountID
      )
    )
  );

  //const gridData = useRef();

  // const localhost = "http://localhost:8080";
  var adapt = {
    tenantId: tenantId,
    userId: userId,
    rows: [],
  };

  useEffect(() => {
    // loadMetaData(setMetaData, setStateMetataData, null, null, tenantId);
    
    createHierarchy(tenantId, setAllMetaData, setTenantField);
  }, []);

  function addNewRow(rowsData, gridData) {
    //x++;

    //  console.log(gridData.currentrowSelected[0].account.companyWebsite)

    if (
      rowsData.length === 0 ||
      (rowsData[0].accountId !== "" &&
        // rowsData[0].contactId !== "" &&
        rowsData[0].contactFirstName !== "" &&
        rowsData[0].contactLastName !== "" &&
        rowsData[0].contactEmail !== "")
    ) {
      var new_row = {
        id: uuid(), //rowsData.length + 1, // gridData.current.data.rowSelected.length + 1,

        row_id: rowsData.length + 1,
        contactId: "",
        contactSource: "",
        companyName: "",
        companyGroupName: "",
        companyWebsite: "",
        contactRole: "",
        contactStreet: "",
        contactCity: "",
        contactState: "",
        contactCountry: "",
        contactZipCode: "",
        contactFirstName: "",
        contactLastName: "",
        contactTitle: "",
        contactDepartment: "",
        contactSubDepartment: "",
        contactPositionLevel: "",
        contactLinkedin: "",
        contactFacebook: "",
        contactTwitter: "",
        contactMobile: "",
        contactPhone: "",
        contactSecondaryNumber: "",
        contactEmail: "",
        contactPersonalEmail: "",
        contactSecondaryEmail: "",
        contactEducationHistory: "",
        contactEmploymentHistory: "",
        contactIsDefunct: "",
        contactMobilePhoneDoNotCall: "",
        contactDirectPhoneDoNotCall: "",
        contactEmailUnsubscribe: "",
        contactRegion: gridData.companyAccountRegion,

        accountId: props.accountID,
        companyAccountRegion: gridData.companyAccountRegion,
        companyName: gridData.companyName,
        companyGroupName: "",
        companyFranchiseeParent: "",
        companyWebsite: gridData.companyWebsite,
        companyParentWebsite: "",
        companyGroupWebsite: "",
        companyPrimaryIndustryCategory: "",
        companySubIndustryCategory: "",
        companyBrandRevenueNumerical: "",
        companyParentRevenue: "",
        companyGroupRevenue: "",
        companyEmployeeCount: "",
        companyParentEmployees: "",
        companyGroupEmployees: "",
        companyFax: "",
        companyPhone: "",
        companyStreet: "",
        companyCity: "",
        companyState: "",
        companyCountry: "",
        companyContinent: "",
        companyZipCode: "",
        companyDescription: "",
        companyLinkedin: "",
        companyFacebook: "",
        companyTwitter: "",
        companyParent: "",
        companyGtmSegment: "",
        companyGtmRegion: "",
        companyLocationCount: "",
        companyProducts: "",
        companyEmployeeGrowth: "",
        companyType: "",
        companyClassification: "",
        companyTechAttributes: "",
        companyHashTag: "",
        companyRecentFundingAmount: "",
        companyRecentFundingDate: "",
        companyTotalFundingAmount: "",
        companyFundingList: "",
        // crmAccountRegion: "",
        crmFmExecutive: "",
        crmBusinessFormat: "",
        crmISExecutive: "",
        crmFSExecutive: "",
        crmNumberOfStores: "",
        crmAccountGeography: gridData.crmAccountGeography,
        crmContactGeography: gridData.crmAccountGeography,
        crmPersonaCode: "",
        contactSource: "",
      };

      var data = rowsData;
      data.unshift(new_row);

      setRowsData([...data]);

      // console.log(rowsData[0].id === rowsData[1].id);

      if (rowsData.length > 1) {
        if (rowsData[0].id === rowsData[1].id) {
          rowsData.splice(1, 1);
          setRowsData(rowsData);
        }
      }
      setEditRowId(rowsData[0].id);
    } else {
        toast.error(<Alert severity="error">Blank Row already present</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
    }
  }
  useEffect(() => {
    setLoading(true);
    // setTimeout(() => {
    //setRowsData(MOCK_DATA);    //Get API
    //console.log((MOCK_DATA))
    axios
      .get(
        localhost +
          "/b2pm/accountdetailed/account?tenantId=" +
          tenantId +
          "&accountId=" +
          props.accountID,
        { withCredentials: true }
      )
      .then((tabledata) => {
        // gridData.current = tabledata;
        // if(gridData.current.data.rowSelected.length === 0)
        // {
        //    setLoading(false);

        // }
        setRowsData(getAllrows(tabledata));
        setLoading(false);
        gridData.current = getAllRowsData(tabledata.data.rowSelected[0]);
        console.log(tabledata.data.rowSelected[0].account);
        console.log(tabledata.data.rowSelected);
        // setEditRowId(1);
        //  setCount( gridData.current.data.rowSelected.length+1)
      })
      .catch(function (error) {
        toast.error(<Alert severity="error">Unable to fetch data due to server error</Alert>,{
          closeOnClick: true,
          autoClose: true,
          toastId: "my_toast",
          closeButton: true,
          newestOnTop: true
        });
        setLoading(false);
        return Promise.reject(error);
      });

    // setLoading(false);
    // }, 1500);
  }, [props.accountID]);

  //A/C VIEW
  const addToListView = () => {
    console.log(" Contactdata a/c view -> ", selectedRowsIds);
  };

  //ENRICHMENT GRID
  const addToEnrichQueue = () => {
    // console.log("Contactdata enrich grid -> ", selectedRowsIds);
    if (selectedRowsIds.length === 0) {
        toast.error(<Alert severity="error">No row is selected to be added to the Enrichment Queue</Alert>,{
          closeOnClick: true,
          autoClose: true,
          closeButton: true,
          newestOnTop: true
        });
    }

    for (let i = 0; i < selectedRowsIds.length; i++) {
      for (let j = 0; j < rowsData.length; j++) {
        //console.log(rowsData[j]);
        if (selectedRowsIds[i] === undefined) {
          continue;
        }
        if (selectedRowsIds[i] == rowsData[j].id) {
          var json = { id: rowsData[j].contactId };
          adapt.rows.push(json);
          //   mcarlson@vmware.com
          // console.log(adapt);
          break;
        }
      }
    }

    axios
      .post(localhost + "/b2pm/contact/add", adapt, { withCredentials: true })
      .then((tabledata) => {
        if (tabledata.status === 200 && selectedRowsIds.length !== 0) {
          for (let i = 0; i < tabledata.data.rows.length; i++) {
            if (
              tabledata.data.rows[i].id === "" ||
              tabledata.data.rows[i].msg === "ERROR"
            ) {
                toast.error(<Alert severity="error">Unsaved/Empty Record cannot be added to Enrichment Queue</Alert>,{
                  closeOnClick: true,
                  autoClose: true,
                  closeButton: true,
                  newestOnTop: true
                });
        
              continue;
            } else {
              toast.success(<Alert severity="success">Added to Enrichment Queue successfully</Alert>,{
                closeOnClick: true,
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
            }
          }
        }
      });
  };

  const download = () => {
    axios
      .get(localhost + "/b2pm/templateFile/Template_v2_contacts.csv", {
        withCredentials: true,
      })
      .then(function (response) {
        // this.setState({response})

        const FileDownload = require("js-file-download");
        let headerLines = response.request.getResponseHeader(
          "Content-Disposition"
        );
        console.log(
          "content-dispostion" +
            response.request.getResponseHeader("Content-Disposition")
        );
        let startFileNameIndex = headerLines.indexOf('"') + 1;
        let endFileNameIndex = headerLines.lastIndexOf('"');

        FileDownload(response.data, "Template_v2_contacts.csv");
      });
  };

  function unselectfile(event) {
    // ref.current.value = "";
  }

  const [uploadstate, uploadSetState] = useState();
  const [isPicked, setIsPicked] = useState(false);

  function selectfile(event) {
    uploadSetState({ selectedFile: event.target.files[0] });
    setIsPicked(true);
  }

  let counter = rowsData.length + 1;
  function getAllrows(inp_rows) {
    // console.log(inp_rows)

    var rows = inp_rows.data.rowSelected;
    var rows_out = [];

    if (
      rows.length === 0 ||
      rows.length === null ||
      rows.length === undefined ||
      rows[0].contact === undefined
    ) {
      rows_out = [];
      console.log(rows_out);
      return rows_out;
    }

    for (let i = 0; i < rows.length; i++) {
      rows_out.push(getAllRowsData(rows[i], counter));
      counter++;
    }

    // console.log(rows_out);
    return rows_out;
  }

  function getAllRowsData(inp, i) {
    var row = {
      id: uuid(),
      row_id: counter,
      contactSource: inp.contactSource,
      ...inp.account,
      ...inp.contact,
      ...inp.funding,
      ...inp.crm,
    };

    row['crmContactGeography'] = row['crmAccountGeography'];
    row['contactRegion'] = row['companyAccountRegion'];
    return row;
  }

  function upload() {
    if (isPicked === false) {
      // alert("No file chosen to upload!");
      return;
    }

    if (
      uploadstate.selectedFile.type.toLowerCase() === ".csv" ||
      uploadstate.selectedFile.type.toLowerCase() === "text/plain" ||
      uploadstate.selectedFile.type.toLowerCase() ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
      uploadstate.selectedFile.type.toLowerCase() ===
        "application/vnd.ms-excel" ||
      uploadstate.selectedFile.type.toLowerCase() === "text/csv"
    ) {
      setLoading(true);
      // Create an object of formData
      const formData = new FormData();
      const FileDownload = require("js-file-download");

      // Update the formData object
      formData.append(
        "file",
        uploadstate.selectedFile,
        uploadstate.selectedFile.name
      );
      formData.append("userID", userId);
      formData.append("tenantID", tenantId);

      //console.log(uploadstate.selectedFile);

      // Request made to the backend api
      // Send formData object

      axios
        .post(localhost + "/b2pm/contact/uploadfile", formData, {
          withCredentials: true,
        })
        .then(function (response) {
          console.log("response");
          if (response.status == 200) {
            //var data = rowsData;
            //data.unshift(getAllrows(response));
            //etRowsData([...data]);
            // gridData.current = tabledata;
            let datalen = response.data.rowSelected.length;
            let datacount = rowsData.length + datalen;
            var obj = {
              id: datacount, // gridData.current.data.rowSelected.length + 1,
              msg: "",
              row_id: datacount,
              contactId: "",
              contactSource: "",
              contactFirstName: "",
              contactLastName: "",
              contactTitle: "",
              contactDepartment: "",
              contactSubDepartment: "",
              contactPositionLevel: "",
              contactLinkedin: "",
              contactFacebook: "",
              contactTwitter: "",
              contactMobile: "",
              contactPhone: "",
              contactSecondaryNumber: "",
              contactEmail: "",
              contactPersonalEmail: "",
              contactSecondaryEmail: "",
              contactEducationHistory: "",
              contactEmploymentHistory: "",
              contactIsDefunct: "",
              contactMobilePhoneDoNotCall: "",
              contactDirectPhoneDoNotCall: "",
              contactEmailUnsubscribe: "",

              accountId: "",
              companyName: "",
              companyGroupName: "",
              companyFranchiseeParent: "",
              companyWebsite: "",
              companyParentWebsite: "",
              companyGroupWebsite: "",
              companyPrimaryIndustryCategory: "",
              companySubIndustryCategory: "",
              companyBrandRevenueNumerical: "",
              companyParentRevenue: "",
              companyGroupRevenue: "",
              companyEmployeeCount: "",
              companyParentEmployees: "",
              companyGroupEmployees: "",
              companyFax: "",
              companyPhone: "",
              companyStreet: "",
              companyCity: "",
              companyState: "",
              companyCountry: "",
              companyContinent: "",
              companyZipCode: "",
              companyDescription: "",
              companyLinkedin: "",
              companyFacebook: "",
              companyTwitter: "",
              companyParent: "",
              companyGtmSegment: "",
              companyGtmRegion: "",
              companyLocationCount: "",
              companyProducts: "",
              companyEmployeeGrowth: "",
              companyType: "",
              companyClassification: "",
              companyTechAttributes: "",
              companyHashTag: "",
              companyRecentFundingAmount: "",
              companyRecentFundingDate: "",
              companyTotalFundingAmount: "",
              companyFundingList: "",
              crmNumberofStores: "",
              crmAccountGeography: "",
              crmBusinessFormat: "",
              crmISExecutive: "",
              crmFSExecutive: "",
              crmDataSource: "",
              crmFmExecutive: "",
              // crmAccountRegion: "",
              contactSource: "",
            };
            //console.log(datacount)
            var data1 = rowsData;
            data1.splice(0, 1);
            var data2 = getAllrows(response);

            const data3 = [obj, ...data2, ...data1];

            setRowsData([...data3]);

            // gridData.current = tabledata;
            // setRowsData(getAllrows(response));
            console.log(response.data.msg);
            let str = response.data.msg;
            console.log(str);
            let res = str.split("<&>");
            console.log(res[1]);
            setEditRowId(datacount);
            setIsPicked(false);
            if (response.status == 200) {
              unselectfile();
              setLoading(false);
              toast(res[0], {
                closeOnClick: true,
                toastId: "my_toast",
                autoClose: true,
                closeButton: true,
                newestOnTop: true
              });
            }

            var d = new Date();
            d = new Date(d.getTime());
            var date_format_str =
              d.getFullYear().toString() +
              ((d.getMonth() + 1).toString().length == 2
                ? (d.getMonth() + 1).toString()
                : "0" + (d.getMonth() + 1).toString()) +
              (d.getDate().toString().length == 2
                ? d.getDate().toString()
                : "0" + d.getDate().toString()) +
              (d.getHours().toString().length == 2
                ? d.getHours().toString()
                : "0" + d.getHours().toString()) +
              ((parseInt(d.getMinutes() / 5) * 5).toString().length == 2
                ? (parseInt(d.getMinutes() / 5) * 5).toString()
                : "0" + (parseInt(d.getMinutes() / 5) * 5).toString()) +
              "00";
            console.log(date_format_str);

            axios
              .get(localhost + "/b2pm/download/" + res[1] + tenantId, {
                withCredentials: true,
              })
              .then((response) => {
                FileDownload(
                  response.data,
                  "load_results_" + date_format_str + ".csv"
                );
              });
          }
        });
      //endif
    } else {
      // alert("Wrong Input!");
      unselectfile();
    }
  }

  // <ThemeProvider theme={UItheme}>
  return (
    <ThemeProvider theme={UItheme}>
      <div className="demo">
        <div className="tableWrapper">
          <div className="button-section" style={{display: 'table', flexDirection: 'column',paddingTop:"0px"}}>
            <div style={{paddingBottom:"14px"}}>
            <Typography
              style={{ fontWeight: "700", fontFamily:"Nunito Sans", color:"#595959",fontSize:"13px" }}
            >
              CONTACT DETAILS
            </Typography>
            </div>
            <div className="button-array">
              <Button
                variant="contained"
                style={{
                fontSize:"12px",
                backgroundColor:"#21DA8C", 
                borderColor:"#21DA8C", 
                color:"#FFFFFF", 
                borderRadius:"18px",
                marginLeft:'0px',
                boxShadow: '0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 1px 5px rgba(0, 0, 0, 0.12)'
              }}
                title="Add Contact"
                onClick={() => addNewRow(rowsData, gridData.current)}
              >
                ADD CONTACT
              </Button>
              <Button
                title="Add to Enrichment Queue"
                onClick={() => addToEnrichQueue()}
                variant="contained"
                style={{fontSize:"12px",
                backgroundColor:(selectedRowsIds.length>0)?"#21DA8C":"#C4C4C4", 
                borderColor:(selectedRowsIds.length>0)?"#21DA8C":"#C4C4C4",
                cursor: selectedRowsIds.length===0?'default':"Pointer",
                color:"#FFFFFF", 
                borderRadius:"18px"}}
              >
                Queue to Enrich
              </Button>
            </div>
          </div>
         <Paper 
          style={{ borderRadius: '18px', padding: `28px 28px 0px`, boxShadow: `0px 0px 16px rgba(0, 0, 0, 0.1)`, background: `#FFFFFF`, }}> 
          <GridTable
            columns={columns}
            onColumnsChange={onColumnChangeSave(setColumns)}
            rows={rowsData}
            isLoading={isLoading}
            editRowId={editRowId}
            onEditRowIdChange={setEditRowId}
            selectedRowsIds={selectedRowsIds}
            onSelectedRowsChange={setSelectedRowsIds}
            onRowClick={(
              { rowIndex, data, column, isEdit, event },
              tableManager
            ) =>
              !isEdit &&
              tableManager.rowSelectionApi.getIsRowSelectable(data.id) &&
              tableManager.rowSelectionApi.toggleRowSelection(data.id)
            }
            //UI changes
            style={{
              borderRadius: "4px",
              border: "1px solid #d3d3d3",
              height: "70%",
            }}
            onLoad={setTableManager}
            searchText={searchText}
            onSearchTextChange={setSearchText}
            sort={sort}
            onSortChange={setSort}
            page={page}
            onPageChange={setPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
            pageSizes={pageSizes}
            enableColumnsReorder={enableColumnsReorder}
            highlightSearch={highlightSearch}
            showSearch={showSearch}
            showRowsInformation={showRowsInformation}
            showColumnVisibilityManager={showColumnVisibilityManager}
            isHeaderSticky={isHeaderSticky}
            isVirtualScroll={isVirtualScroll}
            isPaginated={isPaginated}
            minSearchChars={minSearchChars}
            minColumnResizeWidth={minColumnResizeWidth}
            selectAllMode={selectAllMode}
            icons={{ columnVisibility: <MoreHorizIcon color="action" /> }}
          />
          </Paper>
        </div>
      </div>
    </ThemeProvider>
  );
};
export default MyAwesomeTable;
